import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";
import MovementSelect from "../movement/MovementSelect";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import VideoTruthObjectList from "./VideoTruthObjectList";
import Select from "react-select";
import { darkColor, lightColor } from "../common/Theme";
import DismissableAlert from "../common/DismissableAlert";

export default function VideoTruthForm({
  video,
  frameIndex,
  frameTimestamp,
  videoDurationMs,
  labelingObjectChanged,
  seekToFrame,
}) {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [didSaveSucceed, setDidSaveSucceed] = useState(false);
  const [movement, setMovement] = useState();
  const [repCount, setRepCount] = useState(0);
  const [movementStart, setMovementStart] = useState(DateTime.fromMillis(0));
  const [movementEnd, setMovementEnd] = useState(DateTime.fromMillis(0));
  const [labeledFrameIndex, setLabeledFrameIndex] = useState(null);
  const [labeledFrameOptions, setLabeledFrameOptions] = useState(null);

  const buildLabeledFrameOptions = useCallback(() => {
    setLabeledFrameOptions(
      video.labeledFrames().map((labeledFrame) => {
        return {
          label: `Frame ${labeledFrame}`,
          value: labeledFrame,
        };
      })
    );
  }, [video, setLabeledFrameOptions]);

  useEffect(() => {
    buildLabeledFrameOptions();
  }, [frameIndex, buildLabeledFrameOptions]);

  useEffect(() => {
    if (video) {
      async function loadGroundTruth() {
        const groundTruth = await axios({
          url: `https://${await guruUtils.apiDomain()}/videos/${video.id}/groundTruth`,
          headers: await guruUtils.getAuthHeaders(),
        })
          .then(function (response) {
            return response.data;
          })
          .catch((reason) => {
            let errorMessage = `Get ground truth failed because ${reason}`;
            console.log(errorMessage);
            throw errorMessage;
          });

        setMovement(groundTruth["movement"]);
        setRepCount(groundTruth["repCount"]);
        setMovementStart(DateTime.fromMillis(groundTruth["startTime"] || 0));
        setMovementEnd(
          DateTime.fromMillis(groundTruth["endTime"] || videoDurationMs)
        );
        setLoading(false);
      }

      loadGroundTruth();
    }
  }, [video, videoDurationMs]);

  const createMovement = () => {
    navigate(`/movements/new?videoId=${video.id}`);
  };

  const movementChanged = async (movement) => {
    console.log(`Movement changed to: ${JSON.stringify(movement)}`);
    if (movement === undefined) {
      createMovement();
    } else {
      setMovement(movement.name);
    }
  };

  const parseMovementTimestamp = (dateTime) => {
    return dateTime
      .setZone("UTC")
      .set({ year: 1970, month: 1, day: 1, hour: 0 });
  };

  const repCountChanged = async (event) => {
    setRepCount(event.target.value);
  };

  const saveGroundTruth = async () => {
    setLoading(true);
    setError(null);
    setDidSaveSucceed(false);

    var intRepCount = null;
    if (repCount && repCount.toString().trim() !== "") {
      intRepCount = Math.max(parseInt(repCount), 0);
    }

    var response;
    var didSucceed = false;
    try {
      response = await axios.put(
        `https://${await guruUtils.apiDomain()}/videos/${video.id}/groundTruth`,
        {
          movement: movement,
          repCount: intRepCount,
          startTime: Math.floor(movementStart.toMillis()),
          endTime: Math.ceil(movementEnd.toMillis()),
        },
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      );
      console.log(
        `Saving objects: ${JSON.stringify(video.objectsForFrame(frameIndex))}`
      );
      const objects = video.objectsForFrame(frameIndex).map((nextObject) => {
        const apiObject = {
          type: nextObject["name"],
          boundingBox: nextObject["bbox"],
        };

        if (apiObject["type"] === "person") {
          apiObject["jointToPoint"] = video.jointPositionsAtFrame(frameIndex);

          // rename "Toe" to "FootIndex"
          apiObject["jointToPoint"]["leftFootIndex"] =
            apiObject["jointToPoint"]["leftToe"];
          apiObject["jointToPoint"]["rightFootIndex"] =
            apiObject["jointToPoint"]["rightToe"];

          const toDelete = [
            "leftToe",
            "rightToe",
            "nose",
            "leftEye",
            "rightEye",
          ];
          toDelete.forEach((joint) => {
            if (apiObject["jointToPoint"][joint]) {
              delete apiObject["jointToPoint"][joint];
            }
          });
        }

        return apiObject;
      });

      if (objects.length > 0) {
        response = await axios.put(
          `https://${await guruUtils.apiDomain()}/videos/${video.id}/frames/${frameIndex}/groundTruth`,
          { objects: objects },
          {
            headers: await guruUtils.getAuthHeaders(),
          }
        );
        console.log(response);
        buildLabeledFrameOptions();
      }
      didSucceed = true;
    } catch (e) {
      setError(e.response.data || e.message);
    } finally {
      setLoading(false);
    }
    setDidSaveSucceed(didSucceed);
  };

  const labeledFrameSelected = (event) => {
    const frameIndex = parseInt(event.value);
    setLabeledFrameIndex(frameIndex);
    seekToFrame(frameIndex);
  };

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <Box>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={3} p={1}>
                  <TimePicker
                    ampm={false}
                    openTo="minutes"
                    views={[]}
                    inputFormat="mm:ss"
                    mask="__:__"
                    label="Activity start"
                    value={movementStart}
                    onChange={(newValue) => {
                      setMovementStart(parseMovementTimestamp(newValue));
                    }}
                    renderInput={(params) => (
                      <Tooltip
                        title="The time in the video at which the activity begins"
                        placement="top"
                      >
                        <TextField {...params} variant={"filled"} />
                      </Tooltip>
                    )}
                  />
                </Grid>
                <Grid item xs={9} p={1}>
                  {!movement || movement === "UNKNOWN" ? (
                    <Tooltip
                      title="Click to teach Guru this activity"
                      placement="top"
                    >
                      <Button
                        variant="contained"
                        color="warning"
                        endIcon={<ErrorIcon />}
                        onClick={createMovement}
                      >
                        Unknown
                      </Button>
                    </Tooltip>
                  ) : (
                    <MovementSelect
                      value={movement}
                      movementChanged={movementChanged}
                      other={true}
                      otherDisplayName={"Create new activity"}
                    />
                  )}
                </Grid>
                <Grid item xs={3} p={1}>
                  <TimePicker
                    ampm={false}
                    openTo="minutes"
                    views={[]}
                    inputFormat="mm:ss"
                    mask="__:__"
                    label="Activity end"
                    value={movementEnd}
                    onChange={(newValue) => {
                      setMovementEnd(parseMovementTimestamp(newValue));
                    }}
                    renderInput={(params) => (
                      <Tooltip
                        title="The time in the video at which the activity ends"
                        placement="top"
                      >
                        <TextField {...params} variant={"filled"} />
                      </Tooltip>
                    )}
                  />
                </Grid>
                <Grid item xs={9} p={1}>
                  <TextField
                    label={"Number of reps"}
                    variant="filled"
                    autoComplete="off"
                    sx={{ maxWidth: "160px" }}
                    type={"number"}
                    value={repCount}
                    onChange={repCountChanged}
                  />
                </Grid>
                <Grid item xs={12} p={1}>
                  <Button onClick={saveGroundTruth} variant="contained">
                    Save
                  </Button>
                  <DismissableAlert open={didSaveSucceed} hideAfterMs={2000}>
                    Saved
                  </DismissableAlert>
                  <DismissableAlert open={!!error} severity="error">
                    Save failed! {error}
                  </DismissableAlert>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <VideoTruthObjectList
                video={video}
                frameIndex={frameIndex}
                labelingObjectChanged={labelingObjectChanged}
              />

              <Select
                placeholder="Labeled frames"
                onChange={labeledFrameSelected}
                value={labeledFrameOptions.find(
                  (nextOption) => nextOption.value === labeledFrameIndex
                )}
                options={labeledFrameOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 0,
                    height: "56px",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    color:
                      state.isSelected || state.isFocused
                        ? "white"
                        : lightColor,
                    backgroundColor: state.isFocused
                      ? lightColor
                      : state.isSelected
                      ? darkColor
                      : "white",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: lightColor,
                  }),
                }}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>
    );
  }
}
