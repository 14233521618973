import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Video from "./Video";
import { DateTime } from "luxon";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "@mui/material/Pagination";
import NewTenantCTA from "./NewTenantCTA";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";

export default function RecentVideosPage() {
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [videos, setVideos] = useState([]);
  const [page, setPage] = React.useState(1);
  const VIDEOS_PER_PAGE = 36;

  useEffect(() => {
    async function init() {
      setAccessToken(await guruUtils.getAuthToken());

      setVideos(
        await axios({
          url: `https://${await guruUtils.apiDomain()}/videos?size=500`,
          headers: await guruUtils.getAuthHeaders(),
        })
          .then(function (response) {
            setLoading(false);
            return response.data.map((nextData) => {
              const movement = nextData.movements[0];
              return new Video({
                id: nextData.id,
                movement: movement["movement"],
                repCount: movement["rep_count"],
                uploadedAt: DateTime.fromISO(nextData.timestamp, {
                  zone: "utc",
                }).toLocal(),
              });
            });
          })
          .catch((reason) => {
            let errorMessage = `Get videos failed because ${reason}`;
            console.log(errorMessage);
            throw errorMessage;
          })
      );
    }

    init();
  }, []);

  const pageChanged = (event, pageIndex) => {
    setPage(pageIndex);
  };

  let content = null;
  if (loading) {
    content = (
      <div className={"centered"}>
        <CircularProgress />
      </div>
    );
  } else if (videos.length === 0) {
    content = <NewTenantCTA />;
  } else {
    content = (
      <Box pl={2} pr={2}>
        <Box display="flex" justifyContent="center" p={2}>
          <Pagination
            count={Math.ceil(videos.length / VIDEOS_PER_PAGE)}
            page={page}
            color="primary"
            onChange={pageChanged}
          />
        </Box>
        <ImageList cols={6}>
          {videos
            .slice((page - 1) * VIDEOS_PER_PAGE, page * VIDEOS_PER_PAGE)
            .map((nextVideo, index) => (
              <Tooltip
                key={`video-${index}`}
                title={`Video ${nextVideo.id}`}
                placement="top"
              >
                <ImageListItem
                  className={"video-thumbnail zoom"}
                  style={{ maxWidth: 250, maxHeight: 250, cursor: "pointer" }}
                  onClick={(e) => {
                    // if ctrl + click (Windows/Linux), cmd + click (Mac), open in new window
                    if (e.ctrlKey || e.metaKey) {
                      window.open(`/videos/${nextVideo.id}`, "_blank");
                    } else {
                      window.location.href = `/videos/${nextVideo.id}`;
                    }
                  }}
                >
                  <img
                    src={`https://api.getguru.ai/thumbnail/${nextVideo.id}.jpg?Authorization=${accessToken}`}
                    loading="lazy"
                    style={{ maxWidth: 250, maxHeight: 250 }}
                    alt={`Preview for video ${nextVideo.id}`}
                  />
                  <ImageListItemBar
                    sx={{ textTransform: "capitalize" }}
                    title={getVideoTitle(nextVideo)}
                    subtitle={nextVideo.uploadedAt.toFormat("DD H:mm")}
                  />
                </ImageListItem>
              </Tooltip>
            ))}
        </ImageList>
      </Box>
    );
  }

  return (
    <GuruConsolePageLayout>
      <Header />
      {content}
    </GuruConsolePageLayout>
  );
}
function getVideoTitle(nextVideo) {
  if (nextVideo.movement === null || nextVideo.movement === undefined) {
    return "Analysis failed";
  } else if (nextVideo.movement === "SPRINT") {
    return `${nextVideo.repCount} strides`;
  } else {
    return `${nextVideo.repCount} ${nextVideo.movementDisplayName()}s`;
  }
}
