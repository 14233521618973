import React, { useEffect, useState, useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Save, AddToDrive, CheckCircle, Delete } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CopyableText from "../common/CopyableText";
import SchemaBuilder from "./SchemaBuilder";
import { Alert, Switch } from "@mui/material";
import EmailOutputForm from "./EmailOutputForm";

export default function AutomationOutputConfigPage({
  automation,
  setAutomation,
}) {
  const [loading, setLoading] = useState(true);
  const [connectingGoogleSheet, setConnectingGoogleSheet] = useState(false);
  const [connectedGoogleSheet, setConnectedGoogleSheet] = useState(false);
  const [googleSheetName, setGoogleSheetName] = useState(null);
  const [hasGoogleCreds, setHasGoogleCreds] = useState(false);
  const [googleClientEmail, setGoogleClientEmail] = useState(null);
  const [emailConnection, setEmailConnection] = useState(null);

  useEffect(() => {
    async function loadConnections() {
      var response;
      try {
        response = await axios({
          url: `https://${await guruUtils.apiDomain()}/automations/${automation.id}/connections/outputs`,
          headers: await guruUtils.getAuthHeaders(),
        });
      } catch (e) {
        let errorMessage = `Get output connections failed because ${e}`;
        console.log(errorMessage);
        throw errorMessage;
      }

      if ("googleSheet" in response.data) {
        setConnectedGoogleSheet(true);
        setGoogleSheetName(response.data["googleSheet"]["name"]);
      }
      if ("email" in response.data) {
        setEmailConnection(response.data["email"]);
      }

      setLoading(false);
    }

    loadConnections();
  }, [automation]);

  useEffect(() => {
    async function checkGoogleCreds() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/user/tenant/keys/google/client_email`,
        headers: await guruUtils.getAuthHeaders(),
      })
        .then(function (response) {
          setGoogleClientEmail(response.data["client_email"]);
          setHasGoogleCreds(response.data["client_email"] !== null);
        })
        .catch((reason) => {
          let errorMessage = `Get Google creds failed because ${reason}`;
          console.log(errorMessage);
          throw errorMessage;
        });
    }
    checkGoogleCreds();
  }, []);

  const connectGoogleSheet = async () => {
    setLoading(true);
    setConnectingGoogleSheet(false);

    axios
      .put(
        `https://${await guruUtils.apiDomain()}/automations/${automation.id}/connections/outputs/googleSheet`,
        {
          name: googleSheetName,
        },
        { headers: await guruUtils.getAuthHeaders() }
      )
      .then(() => {
        setConnectedGoogleSheet(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const disconnectGoogleSheet = async () => {
    setLoading(true);
    setConnectingGoogleSheet(false);

    axios
      .delete(
        `https://${await guruUtils.apiDomain()}/automations/${automation.id}/connections/outputs/googleSheet`,
        { headers: await guruUtils.getAuthHeaders() }
      )
      .then(() => {
        setConnectedGoogleSheet(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveOutputSchema = useCallback(
    async (definition) => {
      try {
        await axios.put(
          `https://${await guruUtils.apiDomain()}/automations/${automation.id}`,
          {
            output_schema: {
              is_enabled: automation.outputSchema.isEnabled,
              definition,
            },
          },
          { headers: await guruUtils.getAuthHeaders() }
        );
      } catch (e) {
        console.error(`Failed to save output schema because ${e}`);
        return false;
      }
      setAutomation((automation) => {
        return {
          ...automation,
          outputSchema: {
            ...automation.outputSchema,
            definition,
          },
        };
      });
      return true;
    },
    [automation]
  );

  const toggleOutputSchema = useCallback(
    async (isEnabled) => {
      if (automation.outputSchema.definition) {
        // TODO: handle error
        await axios.put(
          `https://${await guruUtils.apiDomain()}/automations/${automation.id}`,
          {
            output_schema: {
              is_enabled: isEnabled,
              definition: automation.outputSchema.definition,
            },
          },
          { headers: await guruUtils.getAuthHeaders() }
        );
      }
      setAutomation((automation) => {
        return {
          ...automation,
          outputSchema: {
            ...automation.outputSchema,
            isEnabled: isEnabled,
          },
        };
      });
    },
    [automation]
  );

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <Box p={1}>
          <Card className={"detail-card"} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ borderBottom: 1, borderColor: "grey.400" }}
                >
                  <Typography variant="h5" component="div" pb={1}>
                    Output
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={1}>
                  <Typography className={"field-label"} variant="overline">
                    Schema
                  </Typography>
                  <Switch
                    checked={automation.outputSchema.isEnabled}
                    onChange={(e) => toggleOutputSchema(e.target.checked)}
                  />
                  <Typography variant={"body2"}>
                    Specify a JSON schema that defines the format of the output.
                  </Typography>
                  {automation.outputSchema.isEnabled && (
                    <SchemaBuilder
                      existingSchema={automation.outputSchema.definition}
                      onSchemaUpdated={saveOutputSchema}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  pt={2}
                  sx={{ borderBottom: 1, borderColor: "grey.400" }}
                >
                  <Typography variant="subtitle1" component="div">
                    Destination
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={1}>
                  <Typography variant={"body2"}>
                    Choose a destination where each output will be sent.
                  </Typography>
                  <Grid container>
                    <Grid item xs={12}>
                      {!connectingGoogleSheet && (
                        <Button
                          onClick={() => setConnectingGoogleSheet(true)}
                          variant={"text"}
                          startIcon={<AddToDrive sx={{ color: "green" }} />}
                          sx={{ color: "green" }}
                        >
                          Google Sheets
                          {connectedGoogleSheet && (
                            <CheckCircle
                              sx={{
                                color: "success.main",
                                marginLeft: "0.5rem",
                              }}
                            />
                          )}
                        </Button>
                      )}

                      {connectingGoogleSheet && !hasGoogleCreds && (
                        <Alert severity="warning">
                          Configure your Google Credentials from the{" "}
                          <Link to="/account">Account page</Link>, and then
                          return here to connect your Google Sheet.
                        </Alert>
                      )}

                      {connectingGoogleSheet && hasGoogleCreds && (
                        <>
                          <Alert severity="info">
                            Please ensure the spreadsheet is shared with your
                            service account user{" "}
                            <CopyableText
                              text={googleClientEmail}
                              variant={"body2"}
                            />
                          </Alert>
                          <TextField
                            label="Sheet Name"
                            variant="standard"
                            value={googleSheetName}
                            fullWidth={true}
                            helperText="The name of the Google Sheet to append each output to"
                            InputProps={{
                              endAdornment: (
                                <>
                                  <InputAdornment position="end">
                                    <IconButton onClick={connectGoogleSheet}>
                                      <Save />
                                    </IconButton>
                                  </InputAdornment>
                                  <InputAdornment position="end">
                                    <IconButton onClick={disconnectGoogleSheet}>
                                      <Delete />
                                    </IconButton>
                                  </InputAdornment>
                                </>
                              ),
                            }}
                            onChange={(event) =>
                              setGoogleSheetName(event.target.value)
                            }
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <EmailOutputForm
                    automationId={automation.id}
                    connectionConfig={emailConnection}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </>
    );
  }
}
