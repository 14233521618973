import React from "react";

export default function TabPanel(props) {
  const { children, value, index, style, ...other } = props;

  return (
    <div role="tabpanel"
         hidden={value !== index}
         id={`tabpanel-${index}`}
         style={{height: "94%", ...style}}
         {...other}>
      {value === index && children}
    </div>
  );
}
