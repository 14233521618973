import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";


export default function MovementCreatedPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const navigateToVideo = () => {
    navigate(`/videos/${searchParams.get("videoId")}`);
  };

  return <>
    <GuruConsolePageLayout>
      <div className={"centered"}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={8}>
            <Card>
              <Box p={5}>
                <Typography variant={"h3"}>
                  <strong>{searchParams.get("movement")}</strong> created
                </Typography>

                <Box mt={2}>
                  <Typography variant={"body1"}>
                    <p>
                      Your new movement can now be used when uploading.
                      Please allow up to 48 hours for models to be rebuilt and your
                      new movement to be detected.
                    </p>

                    <p>
                      In the meantime you should <strong>upload and label at least ten different videos</strong>
                      of the movement to ensure model training has sufficient data.
                      The more you upload and label, the better your accuracy will be.
                    </p>
                  </Typography>
                </Box>

                <Box mt={3}>
                  <Button onClick={navigateToVideo} variant="contained">
                    Finish
                  </Button>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </div>
    </GuruConsolePageLayout>
  </>;
};
