import React, { useCallback, useRef, useState } from "react";
import axios from "axios";
import { guruUtils } from "../../common/Utils";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CheckIcon from "@mui/icons-material/Check";
import PendingIcon from "@mui/icons-material/Pending";
import ErrorIcon from "@mui/icons-material/Error";
import * as Sentry from "@sentry/react";
import Popup from "reactjs-popup";
import Box from "@mui/material/Box";
import {
  Link,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const UploadSummaryTable = ({ uploads }) => {
  const theme = useTheme();
  const Status = ({ text, icon: IconComponent }) => {
    return (
      <Stack direction="row" spacing={0.25} alignItems="center">
        <IconComponent />
        <Typography component="span" color="secondary">
          {text}
        </Typography>
      </Stack>
    );
  };

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
            <TableCell>
              <Typography color={theme.palette.primary.contrastText}>
                File name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color={theme.palette.primary.contrastText}>
                Status
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color={theme.palette.primary.contrastText}>
                Details
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uploads.map(({ file: { name }, videoId, error }) => {
            var status = null,
              details = null;
            if (error) {
              status = <Status text="Failed" icon={ErrorIcon} />;
              details = (
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              );
            } else if (videoId) {
              status = <Status text="Done" icon={CheckIcon} />;
              details = (
                <Link
                  href={`/videos/${videoId}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Typography component="span" color="secondary">
                    View
                  </Typography>
                </Link>
              );
            } else {
              status = <Status text="Pending..." icon={PendingIcon} />;
              details = null;
            }
            return (
              <TableRow key={`row-${name}`}>
                <TableCell>
                  <Typography color="secondary">{name}</Typography>
                </TableCell>
                <TableCell>{status}</TableCell>
                <TableCell>{details}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default function VideoUpload({ onVideoUpload }) {
  const [uploads, setUploads] = useState();
  const [uploading, setUploading] = useState(false);
  const [didFinish, setDidFinish] = useState(false);
  const inputRef = useRef();
  const [files, setFiles] = useState([]);

  const uploadVideo = useCallback(async (video) => {
    const sentryTransaction = Sentry.startTransaction({ name: "upload-video" });
    const createVideoSpan = sentryTransaction.startChild({
      op: "create-video",
    });

    let videoData = {
      filename: video.name,
      size: video.size,
    };

    return axios({
      method: "post",
      url: `https://${await guruUtils.apiDomain()}/videos`,
      headers: await guruUtils.getAuthHeaders(),
      data: videoData,
    })
      .then(async function (response) {
        createVideoSpan.finish();

        await guruUtils.uploadVideo(sentryTransaction, response, video);

        return response.data.id;
      })
      .finally(() => {
        sentryTransaction.finish();
      });
  }, []);

  const startBatchUpload = useCallback(
    async (close) => {
      setDidFinish(false);
      setUploading(true);
      const uploadStatus = [];
      files.forEach((file) => {
        uploadStatus.push({ videoId: null, error: null, file });
      });
      setUploads(uploadStatus);
      let videoIds = [];
      for (var i = 0; i < files.length; i++) {
        const video = files[i];
        var videoId = null,
          error = null;
        try {
          videoId = await uploadVideo(video);
          onVideoUpload(videoId);
        } catch (e) {
          error = e.toString();
        }
        videoIds.push(videoId);
        const updated = { file: video, videoId, error };
        const idxToUpdate = i;
        setUploads((oldVal) => {
          const result = [...oldVal];
          result[idxToUpdate] = updated;
          return result;
        });
      }
      setUploading(false);
      setDidFinish(true);
      resetFiles();
      close();
      return videoIds;
    },
    [files, uploadVideo, onVideoUpload]
  );

  function uploadClicked() {
    inputRef.current.click();
  }

  function resetFiles() {
    inputRef.current.value = null;
    setFiles([]);
  }

  return (
    <Stack direction="row" spacing={2}>
      <Popup
        trigger={
          <Button variant="contained" size="small">
            Upload New Video
          </Button>
        }
        closeOnDocumentClick={false}
        contentStyle={{ width: "500px" }}
      >
        {(close) => (
          <>
            {uploading || didFinish ? (
              <>
                <UploadSummaryTable uploads={uploads} />
                <Button
                  variant="contained"
                  sx={{ margin: 2 }}
                  onClick={() => {
                    setUploads(null);
                    setUploading(false);
                    setDidFinish(false);
                  }}
                >
                  Reset
                </Button>
              </>
            ) : (
              <>
                <Stack direction="column" spacing={2}>
                  {files.length === 0 ? (
                    <Button variant="contained" onClick={uploadClicked}>
                      Choose File
                    </Button>
                  ) : (
                    <Box>
                      <Typography variant="body1" color="primary">
                        {files.length} file(s) selected
                      </Typography>
                    </Box>
                  )}
                  <Stack direction="row" spacing={2}>
                    <Button
                      color="error"
                      variant="outlined"
                      disabled={files.length === 0}
                      onClick={resetFiles}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={files === null || files.length === 0}
                      onClick={() => startBatchUpload(close)}
                    >
                      Upload
                    </Button>
                  </Stack>
                </Stack>
              </>
            )}
          </>
        )}
      </Popup>

      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          const MAX_FILES = 16;
          const newFiles = Array.from(e.target.files);

          if (newFiles.length > MAX_FILES) {
            e.preventDefault();
            alert(`Please select no more than ${MAX_FILES} files.`);
            return;
          }
          setFiles(newFiles);
        }}
      />
    </Stack>
  );
}
