import React, { useEffect, useState, useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Alert, Switch } from "@mui/material";
import EditableList from "./EditableList";
import { FormControlLabel } from "@mui/material";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { Radio, RadioGroup } from "@mui/material";
import DismissableAlert from "../common/DismissableAlert";

export default function EmailOutputForm({ automationId, connectionConfig }) {
  const defaultConfig = {
    is_enabled: false,
    reply_type: "reply",
    additional_to_addresses: [],
    additional_cc_addresses: [],
    additional_bcc_addresses: [],
  };
  const [savedConfiguration, setSavedConfiguration] = useState(
    connectionConfig || defaultConfig
  );
  const [currentConfiguration, setCurrentConfiguration] = useState(
    connectionConfig || defaultConfig
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isValidConfig, setIsValidConfig] = useState(true);
  const [didSaveSucceed, setDidSaveSucceed] = useState(false);

  const validateEmail = (input) => {
    // Regular expression for validating a full email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(input);
    if (!isValid) {
      return { error: "Must be either an email address or a domain" };
    }
  };

  const saveConfiguration = useCallback(async () => {
    setDidSaveSucceed(false);
    setLoading(true);
    try {
      await axios.put(
        `https://${await guruUtils.apiDomain()}/automations/${automationId}/connections/outputs/email`,
        currentConfiguration,
        { headers: await guruUtils.getAuthHeaders() }
      );
    } catch (e) {
      setError("Failed to save output connections");
      return;
    }
    setSavedConfiguration(currentConfiguration);
    setLoading(false);
    setDidSaveSucceed(true);
  }, [automationId, currentConfiguration]);

  const toggleEmailOutputEnabled = useCallback(
    async (isEnabled) => {
      setLoading(true);
      const newConfig = {
        ...savedConfiguration,
        is_enabled: isEnabled,
      };
      try {
        await axios.put(
          `https://${await guruUtils.apiDomain()}/automations/${automationId}/connections/outputs/email`,
          newConfig,
          { headers: await guruUtils.getAuthHeaders() }
        );
      } catch (e) {
        setError(`Failed to ${isEnabled ? "enable" : "disable"} email output`);
      }
      setLoading(false);
    },
    [automationId, savedConfiguration]
  );

  const handleReplyModeChange = (event, newReplyType) => {
    setCurrentConfiguration({
      ...currentConfiguration,
      reply_type: newReplyType,
    });
  };

  const handleListChange = (listName, items) => {
    setCurrentConfiguration({
      ...currentConfiguration,
      [listName]: items.map((item) => item.value),
    });
  };

  useEffect(() => {
    const isValid = (email) => {
      const { error } = validateEmail(email) || {};
      return !error;
    };

    setIsValidConfig(
      currentConfiguration.additional_to_addresses.every(isValid) &&
        currentConfiguration.additional_cc_addresses.every(isValid) &&
        currentConfiguration.additional_bcc_addresses.every(isValid)
    );
  }, [currentConfiguration]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  } else if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      {didSaveSucceed && (
        <DismissableAlert
          open={didSaveSucceed}
          severity={"success"}
          hideAfterMs={2000}
        >
          Saved
        </DismissableAlert>
      )}
      <FormControlLabel
        label={
          <>
            Email{" "}
            <Tooltip title="Send the automation's output as an email">
              <InfoOutlined
                fontSize="small"
                style={{ verticalAlign: "middle" }}
              />
            </Tooltip>
          </>
        }
        control={
          <Switch
            checked={currentConfiguration?.is_enabled}
            onChange={(e) => {
              setCurrentConfiguration((config) => {
                return { ...config, is_enabled: e.target.checked };
              });
              toggleEmailOutputEnabled(e.target.checked);
            }}
          />
        }
      />
      {currentConfiguration?.is_enabled && (
        <Box p={1}>
          <Card className={"detail-card"} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={"field-label"} variant="overline">
                    Reply Mode
                  </Typography>
                  <Typography variant={"body2"}>
                    Choose whether to reply to just the sender or everyone in
                    the conversation
                  </Typography>
                  <RadioGroup
                    row
                    aria-label="reply mode"
                    name="reply-mode"
                    value={currentConfiguration.reply_type}
                    defaultValue={"reply"}
                    onChange={handleReplyModeChange}
                  >
                    <FormControlLabel
                      value="reply"
                      control={<Radio />}
                      label="Reply"
                    />
                    <FormControlLabel
                      value="reply_all"
                      control={<Radio />}
                      label="Reply All"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} pt={2}>
                  <Typography className={"field-label"} variant="overline">
                    Additional To: Addresses
                  </Typography>
                  <EditableList
                    validateItem={validateEmail}
                    items={currentConfiguration.additional_to_addresses}
                    onItemsChanged={(items) => {
                      handleListChange("additional_to_addresses", items);
                    }}
                  />
                </Grid>
                <Grid item xs={12} pt={2}>
                  <Typography className={"field-label"} variant="overline">
                    Additional CC: Addresses
                  </Typography>
                  <EditableList
                    validateItem={validateEmail}
                    items={currentConfiguration.additional_cc_addresses}
                    onItemsChanged={(items) =>
                      handleListChange("additional_cc_addresses", items)
                    }
                  />
                </Grid>
                <Grid item xs={12} pt={2}>
                  <Typography className={"field-label"} variant="overline">
                    Additional BCC: Addresses
                  </Typography>
                  <EditableList
                    validateItem={validateEmail}
                    items={currentConfiguration.additional_bcc_addresses}
                    onItemsChanged={(items) => {
                      handleListChange("additional_bcc_addresses", items);
                    }}
                  />
                </Grid>
                <Grid item xs={12} pt={2}>
                  <Button
                    onClick={saveConfiguration}
                    variant={"contained"}
                    color={"primary"}
                    disabled={!isValidConfig}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}
