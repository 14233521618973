import React, { useState } from "react";
import Header from "../common/Header";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

export default function CreateAutomationPage() {
  const [loading, setLoading] = useState(false);
  const [automationName, setAutomationName] = useState("");

  const automationNameChanged = (event) => {
    setAutomationName(event.target.value.trim());
  };

  const createAutomation = async (template) => {
    setLoading(true);

    axios
      .post(
        `https://${await guruUtils.apiDomain()}/automations`,
        {
          name: automationName,
        },
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      )
      .then(function (response) {
        window.location.href = `/automations/${response.data.id}/examples`;
      })
      .catch((reason) => {
        let errorMessage = `Failed to create automation because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
  };

  var content = null;
  if (loading) {
    content = <CircularProgress />;
  } else {
    content = (
      <div className={"centered"}>
        <Card className={"detail-card"} style={{ width: "600px" }}>
          <Box p={5}>
            <form onSubmit={createAutomation}>
              <Box mt={2}>
                <Typography variant={"body1"}>
                  Enter a name for your automation.
                </Typography>
                <TextField
                  label="Name"
                  variant="filled"
                  fullWidth
                  onChange={automationNameChanged}
                />
              </Box>

              <Box mt={3}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    disabled={automationName.length === 0}
                    type="submit"
                    variant="contained"
                  >
                    Create
                  </Button>
                )}
              </Box>
            </form>
          </Box>
        </Card>
      </div>
    );
  }

  return (
    <>
      <GuruConsolePageLayout>
        <Header canUpload={false} />
        {content}
      </GuruConsolePageLayout>
    </>
  );
}
