import { Auth } from "aws-amplify";
import axios from "axios";
import Video from "../video/Video";

class GuruUtils {

  async apiDomain() {
    let tenant = this.getTenantOverride();
    if (!tenant) {
      try {
        tenant = await this.userTenant();
      }
      catch {
        // Not logged in
      }
    }

    if (tenant === "5fstdei9c8r3420l7dqrqa0kkf" || tenant === "1181mb88eooc9qcht9i12ih140") {
      return "api.getguru.fitness";
    }
    else {
      return "api.getguru.ai";
    }
  }

  async checkAdmin(setIsAdmin) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        user.getUserAttributes((error, attributes) => {
          attributes.forEach((attribute) => {
            if (attribute["Name"] === "email") {
              setIsAdmin(
                attribute["Value"].endsWith("@getguru.fitness") ||
                  attribute["Value"].endsWith("@getguru.ai")
              );
            }
          });
        });
      })
      .catch((result) => {
        console.error(`Failed to fetch authenticated user: ${result}`);
      });
  }

  async getAuthHeaders() {
    const token = await this.getAuthToken();
    const headers = {
      Authorization: token,
    };

    const tenantOverride = this.getTenantOverride();
    if (tenantOverride) {
      headers["X-Tenant-Override"] = tenantOverride;
    }

    return headers;
  }

  async getAuthToken() {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  }

  getTenantOverride() {
    return window.sessionStorage.getItem("tenantOverride");
  }

  async getVideo(videoId) {
    // TODO: this endpoint doesn't return "person" objects, but it should!
    return await axios({
      url:
        `https://${await guruUtils.apiDomain()}/videos/` +
        videoId +
        "?include=j2p,analysis,objects",
      headers: await guruUtils.getAuthHeaders(),
    })
      .then(function (response) {
        return new Video({
          id: videoId,
          status: response.data.status,
          uri: response.data.uri,
          overlays: response.data["overlays"],
          j2p: response.data["j2p"],
          analysis: response.data["analysis"],
          repCount: response.data["repCount"],
          fps: response.data["fps"],
          objects: response.data["objects"],
        });
      })
      .catch((reason) => {
        let errorMessage = `Video fetch for ${videoId} failed because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
  }

  uploadVideo(sentryTransaction, createResponse, video) {
    const formData = new FormData();
    Object.keys(createResponse.data.fields).forEach((key) => {
      formData.append(key, createResponse.data.fields[key]);
    });
    formData.append("file", video);

    console.log(`Uploading ${video.size} bytes to ${createResponse.data.url}`);
    const uploadVideoSpan = sentryTransaction.startChild({
      op: "upload-video",
    });
    return axios
      .post(createResponse.data.url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .finally(() => {
        uploadVideoSpan.finish();
      });
  }

  async isLoggedIn() {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch {
      return false;
    }
  }

  setTenantOverride(tenantId) {
    window.sessionStorage.setItem("tenantOverride", tenantId);
  }

  async userHasTenant() {
    return (await this.userTenant()) !== null;
  }

  async userTenant() {
    const user = await Auth.currentAuthenticatedUser();

    if (
      user.signInUserSession.accessToken.payload["cognito:groups"] === undefined
    ) {
      return null;
    }

    const tenantGroup = user.signInUserSession.accessToken.payload[
      "cognito:groups"
    ].find((group) => {
      return group.startsWith("tenant-");
    });

    if (tenantGroup) {
      return tenantGroup.split("-")[1];
    } else {
      return null;
    }
  }
}

export const guruUtils = new GuruUtils();
