import React, { useState } from "react";
import Header from "../common/Header";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";

export default function CreateMovementPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState("");

  const relabelVideo = async (videoId, movementName) => {
    axios
      .put(
        `https://${await guruUtils.apiDomain()}/videos/${videoId}/groundTruth`,
        {
          movement: movementName,
        },
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      )
      .then(function (response) {
        navigate(
          `/movements/created?movement=${displayName}&videoId=${videoId}`
        );
      })
      .catch((reason) => {
        let errorMessage = `Failed to re-label video ${videoId} to ${movementName} because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
  };

  const createMovement = async (event) => {
    event.preventDefault();

    console.log(`Creating movement: ${displayName}`);
    setLoading(true);
    const name = displayName.toLowerCase().replace(/[^a-z_]/g, "_");

    axios
      .put(
        `https://${await guruUtils.apiDomain()}/movements/${name}`,
        {
          name: name,
          display_name: displayName,
        },
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      )
      .then(async function (response) {
        const videoId = searchParams.get("videoId");
        if (videoId === undefined) {
          navigate("/");
        } else {
          await relabelVideo(videoId, name);
        }
      })
      .catch((reason) => {
        let errorMessage = `Failed to create movement ${displayName} because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
  };

  const displayNameChanged = (event) => {
    setDisplayName(event.target.value.trim());
  };

  return (
    <GuruConsolePageLayout>
      <Header canUpload={false} />
      <div className={"centered"}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={8}>
            <Card>
              <Box p={5}>
                <form onSubmit={createMovement}>
                  <Typography variant={"h3"}>Create a new Activity</Typography>

                  <Box mt={2}>
                    <Typography variant={"body1"}>
                      Start by giving this new activity a name:
                    </Typography>
                    <TextField
                      label="Name"
                      variant="filled"
                      fullWidth
                      onChange={displayNameChanged}
                    />
                  </Box>

                  <Box mt={3}>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        disabled={displayName.length === 0}
                        type="submit"
                        variant="contained"
                      >
                        Create
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </div>
    </GuruConsolePageLayout>
  );
}
