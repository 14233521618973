import React, { useState, useEffect } from "react";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";
import { CircularProgress } from "@mui/material";
import GuruPaginatedTable from "../common/GuruPaginatedTable";

export default function HomePage() {
  const [schemas, setSchemas] = useState([]);
  const [templates, setTemplates] = useState(null);
  const [schemasLoading, setSchemasLoading] = useState(true);
  const [templatesloading, setTemplatesLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      try {
        const headers = await guruUtils.getAuthHeaders();

        const [schemasResponse, templatesResponse] = await Promise.all([
          axios({
            url: `https://${await guruUtils.apiDomain()}/schemas`,
            headers: headers,
          }),
          axios({
            url: `https://${await guruUtils.apiDomain()}/schemas/templates`,
            headers: headers,
          }),
        ]);

        const sortedSchemas = schemasResponse.data
          .sort((a, b) => {
            if (a.lastUpdated === null) return 1;
            if (b.lastUpdated === null) return -1;
            return new Date(b.lastUpdated) - new Date(a.lastUpdated);
          })
          .map((schema) => ({
            ...schema,
            humanReadableLastUpdated: schema.lastUpdated
              ? new Date(schema.lastUpdated).toLocaleString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "Not Available",
          }));

        setSchemas(sortedSchemas);

        setTemplates(templatesResponse.data);
      } catch (error) {
        let errorMessage = `Data fetching failed because ${error}`;
        console.log(errorMessage);
        throw errorMessage;
      } finally {
        setSchemasLoading(false);
        setTemplatesLoading(false);
      }
    }

    loadData();
  }, []);

  const schemaColumns = [
    { id: "name", label: "Name" },
    { id: "humanReadableLastUpdated", label: "Last Updated" },
  ];

  const onSchemaRowClick = (schema) => {
    window.location.href = `/editor?schemaId=${schema.id}&videoId=${schema.testVideoId}`;
  };

  const createTemplateClicked = async (template) => {
    const processString = (str) => {
      const lowercaseStr = str.toLowerCase();
      const noSpacesStr = lowercaseStr.replace(/\s+/g, "-");

      const randomString = () => {
        let result = "";
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < 5; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        return result;
      };

      return noSpacesStr + "-" + randomString();
    };

    axios
      .post(
        `https://${await guruUtils.apiDomain()}/schemas`,
        {
          name: processString(template.name),
          templateId: template.id,
        },
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      )
      .then(function (response) {
        window.location.href = `/editor?schemaId=${response.data.id}&videoId=${response.data.testVideoId}`;
      })
      .catch((reason) => {
        let errorMessage = `Failed to create schema because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
  };

  return (
    <>
      <GuruConsolePageLayout showFullMenu={true}>
        <Box py={0} px={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={4}
          >
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Recent Schemas
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            ></Box>
          </Box>
          <Grid container mt={2}>
            {schemasLoading ? (
              <CircularProgress />
            ) : (
              <GuruPaginatedTable
                rowsPerPage={4}
                data={schemas}
                columns={schemaColumns}
                onRowClick={onSchemaRowClick}
                loading={schemasLoading}
              />
            )}
          </Grid>
          <Typography variant="h5" style={{ fontWeight: "bold" }} mt={4}>
            Need inspiration?
          </Typography>
          <br />
          <Typography variant="body1">
            Read our
            <a
              href="https://docs.getguru.ai/introduction"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Quickstart Guide{" "}
            </a>
            and start with a template below 🚀
          </Typography>

          <Grid container spacing={4} mt={2}>
            {templatesloading ? (
              <CircularProgress />
            ) : (
              templates &&
              templates.map((template, templateIndex) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                  lg={3}
                  key={`${template.title}-${templateIndex}`}
                  style={{ display: "flex", justifyContent: "" }}
                >
                  <Card
                    elevation={3}
                    onClick={() => createTemplateClicked(template)}
                    sx={{
                      transition: "0.3s",
                      minWidth: 245,
                      maxWidth: 245,
                      minHeight: 245,
                      maxHeight: 245,
                      "&:hover": { transform: "scale(1.05)" },
                      backgroundColor: "white",
                    }}
                  >
                    <CardMedia
                      sx={{ height: 140 }}
                      image={template.animationUri || template.thumbnailUri}
                      title={template.name}
                    />
                    <CardContent sx={{ textAlign: "center" }}>
                      <Typography gutterBottom variant="body1" component="div">
                        <strong>{template.name}</strong>
                      </Typography>
                      <Typography gutterBottom variant="body2" component="div">
                        {template.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </GuruConsolePageLayout>
    </>
  );
}
