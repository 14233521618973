import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Save } from "@mui/icons-material";
import { useCode } from "../video/ide/IDEUtils";
import EditableTextArea from "../common/EditableTextArea";
import { CodeEditor } from "../video/CodeBlock";

export default function AutomationOutputConfigPage({ automation }) {
  const [loading, setLoading] = useState(true);
  const [addressLookup, setAddressLookup] = useState(false);
  const [editingPreprocessing, setEditingPreprocessing] = useState(false);
  const postProcessingCode = useCode({
    initialText:
      "def process_results(results: str) -> str:\n\treturn results",
  });  

  useEffect(() => {
    async function loadPreprocessing() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/automations/${automation.id}/preprocessing`,
        headers: await guruUtils.getAuthHeaders(),
      }).then(function (response) {
        response.data.forEach((nextFunction) => {
          if (nextFunction["function"] === "address_lookup") {
            setAddressLookup(true);
          }
        });
      }).catch((reason) => {
        let errorMessage = `Get preprocessing failed because ${reason}`;
        console.error(errorMessage);
        throw errorMessage;
      }).finally(() => setLoading(false));
    }

    loadPreprocessing();
  }, [automation]); 

  useEffect(() => {
    async function loadPostprocessing() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/automations/${automation.id}/postprocessing`,
        headers: await guruUtils.getAuthHeaders(),
      }).then(function (response) {
        if (response.data.length > 0) {
          postProcessingCode.resetText(response.data[0].source_code);
        }
      }).catch((reason) => {
        let errorMessage = `Get postprocessing failed because ${reason}`;
        console.error(errorMessage);
        throw errorMessage;
      }).finally(() => setLoading(false));
    }

    loadPostprocessing();
  }, [automation]);   

  const examplesClicked = () => {
    window.location.href = `/automations/${automation.id}/examples`;
  };

  const onAddressLookupToggled = (event) => {
    setAddressLookup(event.target.checked);
    setEditingPreprocessing(true);
  }

  const savePostprocessing = async () => {
    setLoading(true);

    const response = await axios.put(
      `https://${await guruUtils.apiDomain()}/automations/${automation.id}/postprocessing`,
      [
        {
          "type": "python_code",
          "name": "process_results",
          "source_code": postProcessingCode.text,
        }
      ],
      { headers: await guruUtils.getAuthHeaders() }
    ).finally(() => {
      setLoading(false);
    });

    if (response.status !== 200) {
      return `Failed to save post-processing: ${response.status}`;
    }
  };  

  const savePreprocessing = async () => {
    setLoading(true);

    const preprocessing = [];
    if (addressLookup) {
      preprocessing.push({
        "function": "address_lookup",
      })
    }

    const response = await axios.put(
      `https://${await guruUtils.apiDomain()}/automations/${automation.id}/preprocessing`,
      preprocessing,
      { headers: await guruUtils.getAuthHeaders() }
    ).finally(() => {
      setEditingPreprocessing(false);
      setLoading(false);
    });

    if (response.status !== 200) {
      return `Failed to save pre-processing: ${response.status}`;
    }
  };
  
  const taskDescriptionUpdated = async (taskDescription) => {
    const response = await axios.put(
      `https://${await guruUtils.apiDomain()}/automations/${automation.id}`,
      {
        task_description: taskDescription,
      },
      { headers: await guruUtils.getAuthHeaders() }
    );
    if (response.status !== 200) {
      return "Failed to save updated task description";
    }
  };  

  if (loading) {
    return <CircularProgress />;
  } else {
    return <>
      <Box p={1}>
        <Card className={"detail-card"} variant="outlined">
          <CardContent>
            <Grid container>
              <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "grey.400" }}>
                <Typography variant="h5" component="div" pb={1}>
                  Processing
                </Typography>
              </Grid>             
              <Grid item xs={12}>
                <Typography className={"field-label"} variant="overline">
                  Instructions
                </Typography>
                <EditableTextArea
                  text={
                    automation.taskDescription || "Describe your task here..."
                  }
                  onSave={taskDescriptionUpdated}
                />
              </Grid>
              <Grid item xs={12} sx={{ pt: 2, borderBottom: 1, borderColor: "grey.400" }}>
                <Typography variant="subtitle1" className={"field-label"}>
                  Pre-processing

                  {
                    editingPreprocessing && <IconButton size="small" onClick={savePreprocessing}>
                      <Save sx={{ color: "success.main" }} />
                    </IconButton>
                  }                  
                </Typography>
                                                
              </Grid>    
              <Grid item xs={12} pt={1}>
                <FormGroup>
                  <FormControlLabel control={<Switch checked={addressLookup} onChange={onAddressLookupToggled}/>} 
                                    label="Address Lookup"/>
                </FormGroup>
              </Grid> 
              <Grid item xs={12} sx={{ pt: 2, borderBottom: 1, borderColor: "grey.400" }}>
                <Typography variant="subtitle1" className={"field-label"}>
                  Post-processing

                  {
                    postProcessingCode.isDirty && <IconButton size="small" onClick={savePostprocessing}>
                      <Save/>
                    </IconButton>
                  }                                
                </Typography>                                                
              </Grid>    
              <Grid item xs={12} pt={1} style={{height: "180px"}}>
                <CodeEditor
                  language={"python"}
                  onChange={(text) => {
                    postProcessingCode.setText(text);
                    postProcessingCode.setIsDirty(true);
                  }}
                  initialValue={postProcessingCode.initialText}
                  onUnmount={(editor) => {
                    const model = editor.getModel();
                    const value = model && model.getValue();
                    if (value) {
                      postProcessingCode.resetText(value);
                    }
                  }}
                  containerStyle={{marginLeft: 0}}
                />               
              </Grid>                            
            </Grid>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={examplesClicked}>
              Examples
            </Button>
          </CardActions>          
        </Card>
      </Box>        
    </>;
  }
}
