import axios from "axios";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import { useRef, useEffect, useState } from "react";
import { guruUtils } from "../../common/Utils";
import { InfoOutlined, Refresh } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const useModelUploader = ({
  existingModelNames,
  onError,
  onModelCreated = null,
}) => {
  const [uploadProgress, setUploadProgress] = useState(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 1 * 1024 * 1024 * 1024; // 1GB
    const minSizeInBytes = 1; // 1 byte

    if (!file) return;

    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    const fileSize = file.size;
    // TODO: validate model name contains only valid chars and is correct length
    const modelName = file.name;

    if (existingModelNames.includes(modelName)) {
      onError(`A model named '${modelName}' already exists`);
    } else if (fileExtension !== ".onnx") {
      onError("Only .onnx files are allowed");
    } else if (fileSize < minSizeInBytes || fileSize > maxSizeInBytes) {
      onError("File size must be between 1 byte and 1 GB");
    } else {
      const customModel = await createModel(file, modelName);
      if (onModelCreated) {
        onModelCreated(customModel);
      }
    }
  };

  const createModel = async (file, modelName) => {
    console.log("Processing model:", { file, modelName });
    // POST to /custom_models to get a presigned URL
    var response;
    try {
      response = await axios.post(
        `https://${await guruUtils.apiDomain()}/custom_models`,
        { modelName },
        { headers: await guruUtils.getAuthHeaders() }
      );
    } catch (e) {
      onError(`Failed to create custom model: ${e.response.data.message || e}`);
    }

    if (response.status !== 201) {
      const errMsg = response.data.message || response.data;
      onError(`Failed to create custom model: ${errMsg}`);
    }

    // Upload the file to the pre-signed URL
    const { url, fields } = response.data;
    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append("file", file);

    console.log(`Uploading ${file.size} bytes to ${url}`);
    try {
      await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    } catch (e) {
      console.error(e);
      onError(`Failed to upload model to S3: ${e}`);
    } finally {
      setUploadProgress(null);
    }
  };

  return {
    handleFileChange,
    uploadProgress,
  };
};

export default function VideoIDEModels({
  enabledModelNames,
  onModelEnabledOrDisabled,
}) {
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [models, setModels] = useState([]);
  const [selectedModelNames, setSelectedModelNames] = useState([]);
  const theme = useTheme();

  const reloadModels = () => {
    setLoading(true);
    fetchModels();
  };

  const existingModelNames = models.map(({ modelName }) => modelName);

  const { handleFileChange, uploadProgress } = useModelUploader({
    existingModelNames,
    onError: setError,
    onModelCreated: reloadModels,
  });

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    fetchModels();
  }, []);

  const deleteModels = async () => {
    const selectedModels = models.filter(({ modelName }) =>
      selectedModelNames.includes(modelName)
    );
    for (const model of selectedModels) {
      const headers = await guruUtils.getAuthHeaders();
      try {
        await axios.delete(`https://${await guruUtils.apiDomain()}/custom_models`, {
          data: { modelName: model.modelName },
          headers,
        });
      } catch (e) {
        setError(
          `Failed to delete custom model: ${e.response.data.message || e}`
        );
      }
    }
    setSelectedModelNames([]);
    reloadModels();
  };

  const getTableRowForModel = ({ modelName, createdAt, sizeBytes, uri }) => {
    const formatSize = (sizeBytes) => {
      if (sizeBytes < 1024) {
        return `${sizeBytes} bytes`;
      }
      const kb = sizeBytes / 1024;
      if (kb < 1024) {
        return `${kb.toFixed(2)} KB`;
      }
      const mb = kb / 1024;
      if (mb < 1024) {
        return `${mb.toFixed(2)} MB`;
      }
      const gb = mb / 1024;
      return `${gb.toFixed(2)} GB`;
    };

    return (
      <TableRow key={modelName}>
        <TableCell>
          <Checkbox
            size="small"
            sx={{
              "& .MuiSvgIcon-root": {
                color: "white",
              },
            }}
            checked={selectedModelNames.includes(modelName)}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(event) => {
              if (event.target.checked) {
                setSelectedModelNames([...selectedModelNames, modelName]);
              } else {
                setSelectedModelNames(
                  selectedModelNames.filter((name) => name !== modelName)
                );
              }
            }}
          />
        </TableCell>
        <TableCell>
          <Switch
            color="secondary"
            sx={{
              ".MuiSwitch-thumb": { color: "LightGray" },
              ".Mui-checked .MuiSwitch-thumb": { color: "White" },
            }}
            checked={enabledModelNames && enabledModelNames.includes(modelName)}
            onChange={(event) => {
              onModelEnabledOrDisabled({
                modelName,
                modelUrl: uri,
                isEnabled: event.target.checked,
              });
            }}
          />
        </TableCell>

        <TableCell>{modelName}</TableCell>
        <TableCell>{formatSize(sizeBytes)}</TableCell>
        <TableCell>{createdAt}</TableCell>
      </TableRow>
    );
  };

  const fetchModels = async () => {
    var response;
    try {
      response = await axios({
        url: `https://${await guruUtils.apiDomain()}/custom_models`,
        headers: await guruUtils.getAuthHeaders(),
      });
    } catch (e) {
      console.error(e);
      setError(`Failed to get custom models: ${e}`);
      throw e;
    } finally {
      setLoading(false);
    }
    setModels(response.data.models);
  };

  if (loading) {
    return <CircularProgress />;
  } else if (error) {
    return <Typography color={"error"}>{error}</Typography>;
  } else {
    const numModelsSelected = (selectedModelNames || []).length;
    var deleteButtonText;
    if (numModelsSelected === 0) {
      deleteButtonText = "Delete";
    } else if (numModelsSelected === 1) {
      deleteButtonText = "Delete 1 model";
    } else {
      deleteButtonText = `Delete ${numModelsSelected} models`;
    }
    return (
      <>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <IconButton onClick={reloadModels}>
            <Refresh />
          </IconButton>
          <Stack direction={"row"} spacing={1}>
            <Button
              color="error"
              variant="contained"
              disabled={numModelsSelected === 0}
              onClick={deleteModels}
            >
              {deleteButtonText}
            </Button>
            <Button
              variant="contained"
              onClick={openFileDialog}
              disabled={uploadProgress !== null}
            >
              Upload
            </Button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
              accept=".onnx"
            />
          </Stack>
        </Stack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Tooltip
                    title="Whether to enable this model for the current schema"
                    sx={{ marginRight: theme.spacing(0.5) }}
                  >
                    <InfoOutlined
                      fontSize="small"
                      sx={{ p: theme.spacing(0.5) }}
                      style={{ verticalAlign: "middle", color: "white" }}
                    />
                  </Tooltip>
                  Enable?
                </TableCell>
                <TableCell>Model Name</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {models && models.length > 0 ? (
                models.map(getTableRowForModel)
              ) : (
                <TableRow>
                  <TableCell />
                  <TableCell>No models found</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {error && <Alert severity={"error"}>{error}</Alert>}
        {uploadProgress && (
          <div sx={{ marginBottom: theme.spacing(2) }}>
            <Typography sx={{ margin: theme.spacing(2), textAlign: "center" }}>
              Uploading...
            </Typography>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </div>
        )}
      </>
    );
  }
}
