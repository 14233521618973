import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../../common/TabPanel";
import { RocketLaunch } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function VideoIDEFoundationModels() {
  const TABS = {
    VIDEO_EVENTS: "video-events",
    DESCRIBE_IMAGE: "describe-image",
  };

  const [currentTab, setCurrentTab] = useState(TABS.VIDEO_EVENTS);
  const [eventDescription, setEventDescription] = useState("");

  const TabLabel = ({ icon, text }) => (
    <Box display="flex" alignItems="center">
      {icon}
      <Box ml={1} sx={{ color: "black" }}>
        {text}
      </Box>
    </Box>
  );

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleEventDescriptionChange = (event) => {
    setEventDescription(event.target.value);
  };

  const handleSubmit = () => {
    console.log("Event Description:", eventDescription);
    // Add logic to start fine-tuning based on eventDescription
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="Foundation Model Tabs"
        >
          <Tab
            label={
              <TabLabel icon={<RocketLaunch />} text="Detect Video Events" />
            }
            value={TABS.VIDEO_EVENTS}
          />
          <Tab
            label={<TabLabel icon={<RocketLaunch />} text="Describe Image" />}
            value={TABS.DESCRIBE_IMAGE}
          />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={TABS.VIDEO_EVENTS}>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            label="Describe the event you want to detect in your videos"
            variant="outlined"
            fullWidth
            value={eventDescription}
            onChange={handleEventDescriptionChange}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Start Fine-Tuning
          </Button>
        </Box>
      </TabPanel>
      <TabPanel value={currentTab} index={TABS.DESCRIBE_IMAGE}>
        <div>Coming Soon</div>
      </TabPanel>
    </Box>
  );
}
