import React, { useEffect, useState } from "react";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";

export default function AddExampleDialog({automationId, visible, finished, input, output}) {
  const [loading, setLoading] = useState(false);
  const [addingInput, setAddingInput] = useState(input);
  const [addingOutput, setAddingOutput] = useState(output);

  useEffect(() => {   
    setAddingInput(input);
  }, [input]);
  useEffect(() => {   
    setAddingOutput(output);
  }, [output]);    

  const addExample = async (event) => {
    event.preventDefault();

    setLoading(true);
    axios.post(
      `https://${await guruUtils.apiDomain()}/automations/${automationId}/examples`,
      {
        input: addingInput,
        output: addingOutput,
      },
      {
        headers: await guruUtils.getAuthHeaders(),
      }
    )
    .then(function (response) {
      finished({
        "id": response.data.id,
        "input": addingInput,
        "output": addingOutput,        
      });
    })
    .catch((reason) => {
      let errorMessage = `Failed to add example because ${reason}`;
      console.log(errorMessage);
      throw errorMessage;
    }).finally(() => setLoading(false));
  };

  return (
    <Dialog
          onClose={() => finished(null)}
          fullWidth={true}
          maxWidth={"lg"}
          open={visible}
        >
      <DialogTitle>Add Example</DialogTitle>
      <DialogContent>
        <form onSubmit={addExample}>
          <Box p={1}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  label="When Guru receives"
                  variant="filled"
                  fullWidth={true}
                  multiline
                  rows={16}
                  value={addingInput}
                  onChange={(event) =>
                    setAddingInput(event.target.value.trim())
                  }
                />                  
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Guru should return"
                  variant="filled"
                  fullWidth={true}
                  multiline
                  rows={16}
                  value={addingOutput}
                  onChange={(event) =>
                    setAddingOutput(event.target.value.trim())
                  }
                />                  
              </Grid>                
            </Grid>
          </Box>
          <Box p={1}>
            {
              loading ? <CircularProgress/> : <>
                <Button variant="contained" type="submit" style={{marginRight: "2rem"}}>Add</Button>
                <Button variant="outlined" className={"on-dark"} onClick={(event) => {
                  event.preventDefault(); finished(null);
                }}>Cancel</Button>              
              </>
            }
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
