import React from 'react';
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

export default function ReadLinesButton({onRead, children: text, sx = {}, tooltip}) {
  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const fileText = await readFileContent(file);
        const lines = fileText.split('\n')
          .map((next) => next.trim())
          .filter((next) => next.length > 0);
        onRead(lines);
      } catch (error) {
        console.error('Error reading file:', error);
      }
    }
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        resolve(content);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  return <>
    <input accept="*"
           id="read-lines-button"
           type="file"
           style={{ display: "none" }}
           onChange={handleFileChange}/>
    <label htmlFor="read-lines-button">
      <Tooltip title={tooltip}>
        <Button variant="outlined" component="span" sx={sx}>
          {text}
        </Button>
      </Tooltip>
    </label>  
  </>;
};