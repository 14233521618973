export const createButton = ({ canvas, x, y, width, height, maxRealWidth = 20000, maxRealHeight = 20000, ...rest }) => {
  const btn = {
    x: x,
    y: y,
    width: width,
    height: height,
    maxRealWidth: maxRealWidth,
    maxRealHeight: maxRealHeight,
    ...rest,
  };

  const realHeight = () => {
    return Math.min(btn.height * canvas.height, maxRealHeight);
  };

  const realWidth = () => {
    return Math.min(btn.width * canvas.width, maxRealWidth);
  };

  const isMouseOver = (mouseX, mouseY) => {
    const w = canvas.width;
    const h = canvas.height;

    const buttonX = btn.x * w;
    const buttonY = btn.y * h;
    const buttonWidth = realWidth();
    const buttonHeight = realHeight();

    if (
      mouseX >= buttonX &&
      mouseX <= buttonX + buttonWidth &&
      mouseY >= buttonY &&
      mouseY <= buttonY + buttonHeight
    ) {
      return true;
    }
    return false;
  };

  btn.realHeight = realHeight;
  btn.realWidth = realWidth;
  btn.isMouseOver = isMouseOver;

  return btn;
};
