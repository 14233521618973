import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function DismissableAlert({
  open,
  children,
  hideAfterMs,
  ...props
}) {
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    if (open && !!hideAfterMs && hideAfterMs > 0) {
      setTimeout(() => {
        setIsDismissed(true);
      }, hideAfterMs);
    }
  }, [open]);

  return (
    <>
      <Snackbar
        open={!isDismissed && open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant={"filled"}
          onClose={() => setIsDismissed(true)}
          {...props}
        >
          {children}
        </Alert>
      </Snackbar>
    </>
  );
}
