import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Link from "@mui/material/Link";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";
import TextField from "@mui/material/TextField";
import CopyableTextField from "../common/CopyableTextField";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { Auth } from "aws-amplify";
import ChooseTenantControl from "./ChooseTenantControl";
import Button from "@mui/material/Button";
import DismissableAlert from "../common/DismissableAlert";
import GoogleCredInstructions from "./GoogleCredInstructions";

export default function AccountPage() {
  const [tenantInfo, setTenantInfo] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [inviteUserEmailAddress, setInviteUserEmailAddress] = useState("");
  const [userInvitedMessage, setUserInvitedMessage] = useState(null);
  const [openAIKey, setOpenAIKey] = useState(null);
  const [openAIKeyLoading, setOpenAIKeyLoading] = useState(true);
  const [googleAPIKey, setGoogleAPIKey] = useState(null);
  const [googleAPIKeyLoading, setGoogleAPIKeyLoading] = useState(true);  
  const [googleCreds, setGoogleCreds] = useState(null);
  const [googleCredsLoading, setGoogleCredsLoading] = useState(true);  
  const [googleCredInstructionsVisible, setGoogleCredInstructionsVisible] = useState(false);
  const [tenantLoading, setTenantLoading] = useState(true);

  useEffect(() => {
    async function loadTenant() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/user/tenant`,
        headers: await guruUtils.getAuthHeaders(),
      })
        .then(function (response) {
          setTenantLoading(false);
          setTenantInfo(response.data);
        })
        .catch((reason) => {
          let errorMessage = `Get tenant failed because ${reason}`;
          console.log(errorMessage);
          throw errorMessage;
        });
    }
    loadTenant();
  }, []);

  useEffect(() => {
    guruUtils.checkAdmin(setIsAdmin);
  }, []);  

  useEffect(() => {
    async function loadOpenAIKey() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/user/tenant/keys/openai`,
        headers: await guruUtils.getAuthHeaders(),
      }).then(function (response) {
        setOpenAIKeyLoading(false);
        setOpenAIKey(response.data["openai_key"]);
      }).catch((reason) => {
        let errorMessage = `Get OpenAI key failed because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
    }
    loadOpenAIKey();
  }, []);  

  useEffect(() => {
    async function loadGoogleCreds() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/user/tenant/keys/google`,
        headers: await guruUtils.getAuthHeaders(),
      }).then(function (response) {
        setGoogleCredsLoading(false);
        setGoogleCreds(JSON.stringify(response.data, null, 2));
      }).catch((reason) => {
        let errorMessage = `Get Google creds failed because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
    }
    loadGoogleCreds();
  }, []);    

  useEffect(() => {
    async function loadGoogleAPIKey() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/user/tenant/keys/google_api`,
        headers: await guruUtils.getAuthHeaders(),
      }).then(function (response) {
        setGoogleAPIKeyLoading(false);
        setGoogleAPIKey(response.data["key"]);
      }).catch((reason) => {
        let errorMessage = `Get Google API Key failed because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
    }
    loadGoogleAPIKey();
  }, []);    

  const inviteUser = async (event) => {
    event.preventDefault();
    console.log(`Inviting ${inviteUserEmailAddress}`);

    await axios
      .post(
        `https://${await guruUtils.apiDomain()}/invitations`,
        {
          user_email_address: inviteUserEmailAddress,
        },
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      )
      .then(function (response) {
        setUserInvitedMessage(
          `${inviteUserEmailAddress} has been emailed an invitation to your team`
        );
      })
      .catch((reason) => {
        let errorMessage = `Failed to invite ${inviteUserEmailAddress} because ${reason}`;
        console.log(errorMessage);
        setUserInvitedMessage(
          `Unable to invite ${inviteUserEmailAddress}. They may already have an invitation pending.`
        );
      });
  };

  const inviteUserChanged = (event) => {
    setInviteUserEmailAddress(event.target.value.trim());
  };

  const updateOpenAIKey = async () => {
    setOpenAIKeyLoading(true);

    await axios.put(
      `https://${await guruUtils.apiDomain()}/user/tenant/keys/openai`,
      {
        openai_key: openAIKey,
      },
      { headers: await guruUtils.getAuthHeaders() }
    ).catch((reason) => {
      let errorMessage = `Put OpenAI key failed because ${reason}`;
      console.log(errorMessage);
      throw errorMessage;
    }).finally(() => setOpenAIKeyLoading(false)) ;
  };

  const updateGoogleAPIKey = async () => {
    setGoogleAPIKeyLoading(true);

    await axios.put(
      `https://${await guruUtils.apiDomain()}/user/tenant/keys/google_api`,
      {
        key: googleAPIKey,
      },
      { headers: await guruUtils.getAuthHeaders() }
    ).catch((reason) => {
      let errorMessage = `Put Google API Key failed because ${reason}`;
      console.log(errorMessage);
      throw errorMessage;
    }).finally(() => setGoogleAPIKeyLoading(false)) ;
  };  

  const updateGoogleCreds = async () => {
    setGoogleCredsLoading(true);

    await axios.put(
      `https://${await guruUtils.apiDomain()}/user/tenant/keys/google`,
      JSON.parse(googleCreds.trim().length === 0 ? "{}" : googleCreds),
      { headers: await guruUtils.getAuthHeaders() }
    ).catch((reason) => {
      let errorMessage = `Put Google creds failed because ${reason}`;
      console.log(errorMessage);
      throw errorMessage;
    }).finally(() => setGoogleCredsLoading(false)) ;
  };  

  return (
    <>
      <GuruConsolePageLayout p={5}>
        <Box p={2}>
          <Paper elevation={3} className={"info-card"}>
            <Typography variant="h5" gutterBottom>
              API Keys and Authencation Info
            </Typography>
            <Typography>
              Learn how to build a Guru app with our{" "}
              <Link
                href="https://docs.getguru.ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Quickstart
              </Link>{" "}
              .
            </Typography>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              pt={2}
            >
              {
                tenantLoading ? <CircularProgress/> : <>
                  <CopyableTextField label="API Key" value={tenantInfo.apiKey} />
                  <CopyableTextField
                    label="Client Id"
                    value={tenantInfo.credentials.id}
                  />
                  <CopyableTextField
                    label="Client Secret"
                    value={tenantInfo.credentials.secret}
                  />                  
                </>
              }
              
            </Box>
          </Paper>
        </Box>

        <Box p={2}>
          <form onSubmit={inviteUser}>
            <Paper elevation={3} className={"info-card"}>
              <Typography variant="h5" gutterBottom>
                Invite Users
              </Typography>
              <Typography>
                Enter the email address of a user to invite to your team. They
                will have access to the videos and schemas within your
                account.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <TextField
                  label="Email Address"
                  variant="filled"
                  sx={{ width: "400px" }}
                  onChange={inviteUserChanged}
                  value={inviteUserEmailAddress}
                />

                <Button variant="contained" type="submit">
                  Invite
                </Button>
              </Box>
            </Paper>
          </form>
        </Box>

        <Divider/>

        <Box p={2} pb={0}>
          <Typography variant={"h4"}>
            Integrations
          </Typography>
        </Box>

        <Box p={2}>
          <Paper elevation={3} className={"info-card"}>
            <Typography variant="h5" gutterBottom>
              Google API Key
            </Typography>
            <Typography>
              Enter your Google API Key to allow Automations to access public data such as Google Maps.
            </Typography>
            <Link
                href="https://developers.google.com/maps/get-started"
                target="_blank"
                rel="noopener noreferrer"
              >
              Click here for instructions.
            </Link>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {
                googleAPIKeyLoading ? <CircularProgress/> : <>
                  <TextField
                    variant="filled"
                    sx={{ width: "600px" }}
                    onChange={(event) => setGoogleAPIKey(event.target.value.trim())}
                    value={googleAPIKey}
                  />

                  <Button variant="contained" onClick={updateGoogleAPIKey}>
                    Update
                  </Button>                
                </>
              }
            </Box>
          </Paper>
        </Box>          

        <Box p={2}>
          <Paper elevation={3} className={"info-card"}>
            <Typography variant="h5" gutterBottom>
              Google Service Account
            </Typography>
            <Typography>
              Enter your Google Account credentials to allow Automations to access private documents in your account.
            </Typography>
            <Button onClick={() => setGoogleCredInstructionsVisible(!googleCredInstructionsVisible)}>
              Click here for instructions.
            </Button>
            {
              googleCredInstructionsVisible && <GoogleCredInstructions/>
            }

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {
                googleCredsLoading ? <CircularProgress/> : <>
                  <TextField
                    variant="filled"
                    multiline
                    rows={10}
                    sx={{ width: "600px" }}
                    onChange={(event) => setGoogleCreds(event.target.value.trim())}
                    value={googleCreds}
                  />

                  <Button variant="contained" onClick={updateGoogleCreds}>
                    Update
                  </Button>                
                </>
              }
            </Box>
          </Paper>
        </Box>        

        <Box p={2}>
          <Paper elevation={3} className={"info-card"}>
            <Typography variant="h5" gutterBottom>
              OpenAI
            </Typography>
            <Typography>
              Enter your OpenAI key for use with Automations.
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {
                openAIKeyLoading ? <CircularProgress/> : <>
                  <TextField
                    label="OpenAI Key"
                    variant="filled"
                    sx={{ width: "600px" }}
                    onChange={(event) => setOpenAIKey(event.target.value.trim())}
                    value={openAIKey}
                  />

                  <Button variant="contained" onClick={updateOpenAIKey}>
                    Update
                  </Button>                
                </>
              }
            </Box>
          </Paper>
        </Box>                          

        {isAdmin && <>
          <Divider/>

          <Box p={2}>
            <Paper elevation={3} className={"info-card"}>
              <Typography variant={"h5"} pb={2}>
                Admin - Switch Tenant
              </Typography>
              <ChooseTenantControl />
            </Paper>
          </Box>
        </>}

        <Box p={2}>
          <Button
            onClick={() => Auth.signOut()}
            variant="contained"
            sx={{
              width: "fit-content",
              alignSelf: "flex-start",
            }}
          >
            Log Out
          </Button>
        </Box>

        <DismissableAlert open={userInvitedMessage}>
          {userInvitedMessage}
        </DismissableAlert>
      </GuruConsolePageLayout>
    </>
  );
}
