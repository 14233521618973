import Shepherd from "shepherd.js";

export default function newIDETour() {
  const nextButton = {
    action() {
      return this.next();
    },
    text: 'Next'
  };
  const backButton = {
    action() {
      return this.back();
    },
    classes: 'shepherd-button-secondary',
    text: 'Back'
  };
  const previousNextButtons = [backButton, nextButton];

  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      cancelIcon: {
        enabled: true
      },
      scrollTo: { behavior: 'smooth', block: 'center' }
    },
    useModalOverlay: true,
    steps: [
      {
        id: 'intro',
        title: 'Welcome to the Guru IDE',
        text: "The IDE is where you will create an AI Schema that will tell Guru how to process your videos.",
        buttons: [nextButton],
      },
      {
        id: 'video',
        title: 'Video',
        text: "This video will be used to test your work. As you iterate on the schema, you will be able to run it against this video to see how it behaves.",
        attachTo: {
          element: '#ide-video',
          on: 'right'
        },
        buttons: previousNextButtons,
      },
      {
        id: 'file-tab',
        title: 'File',
        text: "The File menu is where you can create, save, open, and delete AI schemas.",
        attachTo: {
          element: '#file-tab',
          on: 'bottom'
        },
        buttons: previousNextButtons,
      },
      {
        id: 'code-tab',
        title: 'Code',
        text: "This tab shows the code that defines your schema. It will be pre-populated if you start from a template.",
        attachTo: {
          element: '#code-tab',
          on: 'bottom'
        },
        when: {
          "before-show": function() {
            const codeTab = document.getElementById('code-tab');
            codeTab.dispatchEvent(new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            }));
          }
        },
        buttons: previousNextButtons,
      },
      {
        id: 'schema-code',
        title: 'Schema Code',
        text: "Your code will contain a class called GuruSchema that defines three functions: processFrame, renderFrame, and outputs.<br/><br/>The first defines the AI operations to run on each frame, such as object detection and pose estimation.<br/><br/>renderFrame allows you to visualize the operations, as an overlay on the video.<br/><br/>The outputs function defines the value of your schema that will be returned to your application.",
        attachTo: {
          element: "div.monaco-editor",
          on: 'left'
        },
        buttons: previousNextButtons,
      },
      {
        id: 'schema-output',
        title: 'Schema Output',
        text: "This shows the output from your schema on the test video.",
        attachTo: {
          element: "#schema-output",
          on: 'top'
        },
        buttons: previousNextButtons,
      },
      {
        id: 'deploy-tab',
        title: 'Deploy',
        text: "The Deploy tab gives instructions on how to deploy your schema to your app.",
        attachTo: {
          element: '#deploy-tab',
          on: 'bottom'
        },
        when: {
          "before-show": function() {
            const deployTab = document.getElementById('deploy-tab');
            if (deployTab) {
              deployTab.dispatchEvent(new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
              }));
            }
          }
        },
        buttons: previousNextButtons,
      },
      {
        id: 'end',
        title: 'More Help?',
        text: "The File menu has a link to comprehensive documentation and allows you to take this tour again at anytime. We look forward to seeing what you build!",
        buttons: [
          backButton,
          {
            action() {
              return this.next();
            },
            text: 'Finish'
          }
        ],
      },
    ]
  });

  tour.on("complete", () => window.localStorage.setItem("ideTourTaken", true.toString()));
  const tourAlreadyTaken = window.localStorage.getItem("ideTourTaken") === true.toString();

  return [tour, tourAlreadyTaken];
}