import React, { useCallback, useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import CategoryIcon from "@mui/icons-material/Category";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import { darkColor } from "../common/Theme";
import Tooltip from "@mui/material/Tooltip";
import DismissableAlert from "../common/DismissableAlert";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Video from "./Video";

export default function VideoTruthObjectList({
  video,
  frameIndex,
  labelingObjectChanged,
}) {
  const [labelingObjects, setLabelingObjects] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [creatingObject, setCreatingObject] = useState(false);
  const [newObjectName, setNewObjectName] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (video) {
      const boundingBoxes = video.bboxesForFrame(frameIndex);
      var labeledObjects =
        boundingBoxes.map((nextBbox) => {
          return {
            id: nextBbox["id"],
            name: nextBbox["name"],
          };
        }) || [];

      setLabelingObjects(labeledObjects);
    }
  }, [video, frameIndex, setLabelingObjects]);

  const addNewObject = (event) => {
    event?.preventDefault();

    addAndSelectObject({
      id: Date.now(),
      name: newObjectName,
    });

    closeCreateObjectDialog();
  };

  const addAndSelectObject = (labelingObject) => {
    setLabelingObjects((existingObjects) => {
      const alreadyHasPerson = existingObjects.some(
        (nextObject) => nextObject["name"] === Video.PERSON_TYPE
      );
      if (alreadyHasPerson && labelingObject["name"] === Video.PERSON_TYPE) {
        setError("You can only label one person");
        return existingObjects;
      }
      setSelectedIndex(existingObjects.length);
      return [...existingObjects, labelingObject];
    });
    labelingObjectChanged(labelingObject);
  };

  const closeCreateObjectDialog = () => {
    setCreatingObject(false);
  };

  const addNewObjectWithName = (name) => {
    addAndSelectObject({ id: Date.now(), name });
  };

  const newObjectNameChanged = (event) => {
    setNewObjectName(event.target.value.trim().toLowerCase());
  };

  const objectDeleted = useCallback(
    (index) => {
      const labelingObject = labelingObjects[index];
      setLabelingObjects(
        labelingObjects.filter(
          (nextLabelingObject) =>
            nextLabelingObject["id"] !== labelingObject["id"]
        )
      );
      video.removeBBoxForFrame(frameIndex, labelingObject["id"]);
    },
    [labelingObjects]
  );

  const objectDuplicated = useCallback(
    (index) => {
      addNewObjectWithName(labelingObjects[index]["name"]);
    },
    [labelingObjects]
  );

  const objectSelected = useCallback(
    (index) => {
      setSelectedIndex(index);
      labelingObjectChanged(labelingObjects[index]);
    },
    [labelingObjects, labelingObjectChanged]
  );

  const startAddingNewObject = () => {
    setCreatingObject(true);
  };

  const bindNewObjectNameField = useCallback((newObjectNameField) => {
    if (newObjectNameField) {
      setTimeout(() => {
        newObjectNameField.focus();
      }, 100);
    }
  }, []);

  return (
    <>
      <DismissableAlert
        severity="info"
        open={!creatingObject && newObjectName !== null}
        hideAfterMs={3000}
      >
        Drag to draw the bounding box for this object
      </DismissableAlert>
      <DismissableAlert severity="error" open={error !== null}>
        {error}
      </DismissableAlert>

      <List component="nav">
        <ListItem
          sx={{
            border: "solid white",
            borderWidth: "1px 1px 0px 1px",
            backgroundColor: darkColor,
          }}
        >
          <ListItemIcon>
            <CategoryIcon className={"on-dark"} />
          </ListItemIcon>
          <ListItemText className={"on-dark"} primary="People & Objects" />
        </ListItem>

        {labelingObjects.map((labelingObject, index) => (
          <ListItem
            disablePadding
            key={`labeling-object-${index}`}
            secondaryAction={
              <Box>
                <Tooltip title="Create a copy of this object">
                  <IconButton
                    edge="end"
                    onClick={() => objectDuplicated(index)}
                  >
                    <ContentCopyIcon className={"on-dark"} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete this object">
                  <IconButton edge="end" onClick={() => objectDeleted(index)}>
                    <DeleteIcon className={"on-dark"} />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            sx={{ border: "solid white", borderWidth: "0px 1px 1px 1px" }}
          >
            <Tooltip
              title="Click to draw a bounding box for this object"
              placement={"left"}
            >
              <ListItemButton
                selected={selectedIndex === index}
                onClick={() => objectSelected(index)}
              >
                <ListItemIcon>
                  {selectedIndex === index && (
                    <ChevronRightIcon className={"on-dark"} />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={"on-dark"}
                  primary={labelingObject.name}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}

        <ListItem
          disablePadding
          sx={{
            border: "solid white",
            borderWidth: "0px 1px 1px 1px",
            backgroundColor: darkColor,
          }}
        >
          <ListItemButton onClick={startAddingNewObject}>
            <ListItemIcon>
              <AddIcon className={"on-dark"} />
            </ListItemIcon>
            <ListItemText className={"on-dark"} primary={"Add"} />
          </ListItemButton>
        </ListItem>
      </List>

      <Dialog onClose={closeCreateObjectDialog} open={creatingObject}>
        <form onSubmit={addNewObject}>
          <Box p={3}>
            <TextField
              label="Type of object"
              variant="filled"
              inputRef={bindNewObjectNameField}
              onChange={newObjectNameChanged}
            />
          </Box>
        </form>
      </Dialog>
    </>
  );
}
