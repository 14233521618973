import LinearProgress from "@mui/material/LinearProgress";
import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";

export const InferenceProgressBar = ({ progress, cancelIsDisabled, onCancel, ...props }) => {
  const percentage = Math.round(progress * 100);
  const [startedAt, _] = useState(Date.now());

  var secondsRemaining = null;
  if (startedAt && progress > 0) {
    const elapsedSec = (Date.now() - startedAt) / 1000;
    const progressRate = elapsedSec / progress;
    secondsRemaining = (1 - progress) * progressRate;
  }

  const formatEta = (etaSeconds) => {
    if (etaSeconds === null) {
      return "--:--";
    }
    const minutes = Math.floor(etaSeconds / 60);
    const seconds = Math.floor(etaSeconds % 60);
    const hours = Math.floor(minutes / 60);
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
    return `ETA: ${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const progressBar = (
    <LinearProgress
      variant="determinate"
      value={percentage}
      {...props}
    />
  );
  const percentageEl = (
    <Typography variant="body2" color="text.secondary">
      {percentage}%
    </Typography>
  );
  const etaEl = (
    <Typography variant="body2" color="text.secondary">
      {formatEta(secondsRemaining)}
    </Typography>
  );

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Box sx={{flexGrow: 1, pt: 1}}>{progressBar}</Box>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: 'center',
          height: "100%",
          width: "100%",
        }}
      >
        {percentageEl}
        {etaEl}
        <Button disabled={cancelIsDisabled} onClick={onCancel}>Cancel</Button>
      </Container>
    </Container>
  );
};
