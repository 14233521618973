import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import {
  drawCircle,
  drawLine,
  drawRect,
  drawText,
} from "../../workers/inference/guru/draw";
import { useClickAndDragHandler } from "../../common/ClickHandler";
import { drawBarPath } from "./overlays/BaseOverlays";
import VideoPlayerInfoBar from "./VideoPlayerInfoBar";

const useDrawLoop = ({ isStarted, canvasDomEl, uiStateRef, tickFn }) => {
  const animationRequestRef = useRef(null);
  const tickFnRef = useRef();

  useEffect(() => {
    tickFnRef.current = tickFn;
  }, [tickFn]);

  useEffect(() => {
    if (!isStarted) {
      return;
    }
    var prevTime = performance.now();

    const loop = async () => {
      if (canvasDomEl) {
        const ctx = canvasDomEl.getContext("2d");
        const tick = tickFnRef.current;
        const now = performance.now();
        const elapsedMs = now - prevTime;
        const fps = Math.round(1000 / elapsedMs);
        if (fps < 30) {
          console.warn(`Rendering is slow, FPS=${fps}`);
        }
        prevTime = now;
        if (tick) {
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          tick(uiStateRef.current, ctx);
        }
      }
      animationRequestRef.current = window.requestAnimationFrame(loop);
    };
    animationRequestRef.current = window.requestAnimationFrame(loop);
    return () => {
      cancelAnimationFrame(animationRequestRef.current);
    };
  }, [tickFnRef, canvasDomEl, isStarted, uiStateRef]);
};

export default function useVideoOverlay({
  canvasElement,
  video,
  timestamp,
  frameIndex,
  isLabeling,
  labelingObject,
  skeletonEdited,
  isPlaying,
  videoDurationMs,
  hasVideoLoaded,
  uiStateRef,
  seekByTimestamp,
}) {
  const [selectedKeypoint, setSelectedKeypoint] = useState(null);
  const [mouseDragAt, setMouseDragAt] = useState(null);
  const [mouseClickAt, setMouseClickAt] = useState(null);
  const [areTextLabelsVisible, setAreTextLabelsVisible] = useState(true);

  const canEdit = isLabeling && !!labelingObject;
  const [jointsToShow] = useState({
    leftSide: true,
    rightSide: true,
  });
  const jointPairs = useMemo(() => {
    const sides = [
      ...(jointsToShow.leftSide ? ["left"] : []),
      ...(jointsToShow.rightSide ? ["right"] : []),
    ];
    return sides.reduce((acc, direction) => {
      const pairsThisSide = Object.fromEntries(
        [
          ["Toe", "Ankle"],
          ["Heel", "Ankle"],
          ["Toe", "Heel"],
          ["Ankle", "Knee"],
          ["Knee", "Hip"],
          ["Hip", "Shoulder"],
          ["Shoulder", "Elbow"],
          ["Elbow", "Wrist"],
        ].map(([a, b]) => [direction + a, direction + b])
      );
      return { ...acc, ...pairsThisSide };
    }, {});
  }, [jointsToShow]);

  const drawSkeleton = useCallback(
    (ctx, frameIndex, timestampMs, jointPairs, shouldInterpolate = true) => {
      if (!video.j2p) {
        return;
      }

      const jointToCoord = {};
      // draw connectors
      Object.entries(jointPairs).forEach(([jointA, jointB]) => {
        var joint1, joint2;
        if (isPlaying) {
          joint1 = video.jointPositionAt(
            jointA,
            timestampMs,
            shouldInterpolate
          );
          joint2 = video.jointPositionAt(
            jointB,
            timestampMs,
            shouldInterpolate
          );
        } else {
          joint1 = video.jointPositionAtFrame(
            jointA,
            frameIndex,
            shouldInterpolate
          );
          joint2 = video.jointPositionAtFrame(
            jointB,
            frameIndex,
            shouldInterpolate
          );
        }

        if (joint1) {
          jointToCoord[jointA] = joint1;
        }
        if (joint2) {
          jointToCoord[jointB] = joint2;
        }
        if (joint1 && joint2) {
          drawLine(
            ctx,
            {
              position: {
                x: joint1.x * ctx.canvas.width,
                y: joint1.y * ctx.canvas.height,
              },
            },
            {
              position: {
                x: joint2.x * ctx.canvas.width,
                y: joint2.y * ctx.canvas.height,
              },
            },
            {
              color: jointA.startsWith("left") ? "blue" : "red",
              alpha: 0.5,
              width: 2,
            }
          );
        }
      });

      const yCoords = Object.values(jointToCoord).map(({ x, y }) => y);
      const personHeight =
        (Math.max(...yCoords) - Math.min(...yCoords)) * ctx.canvas.height;
      const kptRadius = Math.max(1, personHeight / 50);

      // draw keypoints
      Object.entries(jointToCoord).forEach(([jointName, { x, y }]) => {
        drawCircle(
          ctx,
          ctx.canvas.width * x,
          ctx.canvas.height * y,
          jointName === selectedKeypoint ? kptRadius * 1.5 : kptRadius,
          {
            color: jointName.startsWith("left") ? "blue" : "red",
            alpha: 1.0,
            isFilled: false,
          }
        );

        if (
          !isPlaying &&
          areTextLabelsVisible &&
          (!mouseDragAt || selectedKeypoint === jointName)
        ) {
          drawText(
            ctx,
            jointName,
            ctx.canvas.width * x,
            ctx.canvas.height * y,
            100,
            {
              fontSize: 14,
              alpha: 0.35,
              background: "black",
              textAlpha: 1.0,
            }
          );
        }
      });

      for (const frameBbox of video.bboxesForFrame(frameIndex, isPlaying)) {
        const bbox = frameBbox["bbox"];
        drawRect(
          ctx,
          {
            x: bbox.left * ctx.canvas.width,
            y: bbox.top * ctx.canvas.height,
          },
          {
            x: (bbox.left + bbox.width) * ctx.canvas.width,
            y: (bbox.top + bbox.height) * ctx.canvas.height,
          },
          {
            alpha: 1.0,
            borderColor:
              frameBbox.id === labelingObject?.id ? "#00ffff" : "#00ff00",
            borderWidth: 1,
          }
        );

        if (!isPlaying) {
          drawText(
            ctx,
            `${frameBbox["name"]} - ${Math.round(frameBbox["score"] * 100)}%`,
            bbox.left * ctx.canvas.width,
            bbox.top * ctx.canvas.height - 24,
            ctx.canvas.width,
            {
              fontSize: 12,
              alpha: 0.35,
              background: "black",
              textAlpha: 1.0,
            }
          );
        }
      }
    },
    [
      video,
      isPlaying,
      areTextLabelsVisible,
      selectedKeypoint,
      mouseDragAt,
      labelingObject,
    ]
  );

  const tickFn = useCallback(
    (uiState, ctx) => {
      if (!canvasElement) {
        return;
      }

      const wasClickPendingAtStart = uiState.isClicked;
      const timestampMs = uiState.t;
      if (video.j2p != null && Object.keys(video.j2p).length > 0) {
        if (isLabeling) {
          drawSkeleton(ctx, uiState.frameIndex, timestampMs, jointPairs, true);
        }
        if ("platesCenter" in video.j2p) {
          drawBarPath(ctx, video, timestampMs);
        }
      }

      const { x, y } = uiState.mousePosition;
      if (canEdit) {
        drawCrossHairs(ctx, x, y);
      } else {
        VideoPlayerInfoBar(
          {
            currentTimestamp: uiState.t,
            durationSec:
              videoDurationMs !== null ? videoDurationMs / 1000 : null,
          },
          mouseDragAt,
          mouseClickAt,
          seekByTimestamp,
          ctx,
          video.reps(),
          uiState
        );
      }

      // the click could have arrived in the middle of this loop, so we wait to
      // discard it as unintercepted until it's been unhandled for a full cycle
      if (wasClickPendingAtStart) {
        uiState.isClicked = false;
      }
    },
    [
      isPlaying,
      isLabeling,
      labelingObject,
      canEdit,
      canvasElement,
      mouseDragAt,
      areTextLabelsVisible,
      mouseClickAt,
    ]
  );

  useDrawLoop({
    isStarted: hasVideoLoaded,
    tickFn: tickFn,
    canvasDomEl: canvasElement,
    uiStateRef: uiStateRef,
  });

  const distance = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => {
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  };

  const onMouseDown = useCallback(
    (canvasElement, { x: mouseX, y: mouseY }) => {
      setMouseClickAt({ x: mouseX, y: mouseY });

      if (!canEdit) {
        return;
      }
      const TOLERANCE_PX = 10;
      const clickedKeypoints = Object.entries(
        video.jointPositionsAtFrame(frameIndex, true)
      )
        .map(([label, position]) => {
          if (position === null) {
            return Infinity;
          }
          return [
            label,
            distance(
              { x: mouseX, y: mouseY },
              {
                x: position.x * canvasElement.width,
                y: position.y * canvasElement.height,
              }
            ),
          ];
        })
        .filter(([_label, distance]) => distance <= TOLERANCE_PX);

      if (clickedKeypoints.length === 0) {
        setSelectedKeypoint(null);
      } else {
        var smallestDistance = Infinity;
        var selectedKeypoint = null;
        for (var i = 0; i < clickedKeypoints.length; i++) {
          const [label, distance] = clickedKeypoints[i];
          if (distance < smallestDistance) {
            smallestDistance = distance;
            selectedKeypoint = label;
          }
        }
        setSelectedKeypoint(selectedKeypoint);
      }
    },
    [video, canEdit, timestamp, setSelectedKeypoint, setMouseClickAt]
  );

  const onMouseUp = useCallback(() => {
    setMouseClickAt(null);
  }, [setMouseClickAt]);

  const onDrag = useCallback(
    (canvasElement, { x: x1, y: y1 }, { x: x2, y: y2 }) => {
      setMouseDragAt({ x: x2, y: y2 });

      if (!canEdit) {
        return;
      }
      y1 = y1 / canvasElement.height;
      y2 = y2 / canvasElement.height;
      x1 = x1 / canvasElement.width;
      x2 = x2 / canvasElement.width;
      if (selectedKeypoint !== null) {
        video.updateJointPosition(
          selectedKeypoint,
          timestamp,
          frameIndex,
          x2,
          y2
        );
        skeletonEdited();
      } else {
        video.groundTruthBBoxForFrame(
          frameIndex,
          labelingObject["id"],
          labelingObject["name"],
          {
            top: Math.min(y1, y2),
            left: Math.min(x1, x2),
            width: Math.abs(x2 - x1),
            height: Math.abs(y2 - y1),
          }
        );
      }
    },
    [
      selectedKeypoint,
      frameIndex,
      canEdit,
      timestamp,
      video,
      skeletonEdited,
      labelingObject,
    ]
  );

  const onDragFinished = () => {
    setMouseDragAt(null);
  };

  const onMouseMove = useCallback(
    (_el, { x, y }) => {
      if (uiStateRef.current) {
        uiStateRef.current.mousePosition = { x, y };
      }
    },
    [uiStateRef]
  );

  useClickAndDragHandler({
    canvasElement,
    onMouseMove,
    onMouseDown,
    onMouseUp,
    onDrag,
    onDragFinished,
  });

  const drawCrossHairs = (ctx, x, y) => {
    const lineOpts = {
      color: "red",
      alpha: 0.5,
      width: 1,
      isDashed: true,
    };
    // draw cross-hairs
    drawLine(
      ctx,
      { position: { x, y: 0 } },
      { position: { x, y: ctx.canvas.height } },
      lineOpts
    );
    drawLine(
      ctx,
      { position: { x: 0, y } },
      { position: { x: ctx.canvas.width, y } },
      lineOpts
    );
    // display coords in lower right corner
    drawText(
      ctx,
      `(${Math.round(x)},${Math.round(y)})`,
      ctx.canvas.width - 90,
      ctx.canvas.height - 30,
      90,
      {
        background: "black",
        alpha: 0.3,
        textAlpha: 1.0,
        fontSize: 14,
      }
    );
  };

  const handleKeydown = useCallback(
    (e) => {
      const KEYS = {
        Backspace: () => {
          setSelectedKeypoint((selection) => {
            if (selection) {
              video.removeJointForTimestamp(selection, timestamp);
            }
            return null;
          });
        },
        KeyL: () => {
          setAreTextLabelsVisible((isVisible) => !isVisible);
        },
      };

      if (e.code in KEYS) {
        const fn = KEYS[e.code];
        fn(e);
      }
    },
    [video, timestamp, setSelectedKeypoint]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);
}
