import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VideoFailedMessage from "./VideoFailedMessage";
import RepSignalGraph from "./RepSignalGraph";

export default function RepsTable({video, frameIndex}) {
  const analysisTypes = video.importantAnalysisTypes();

  function copyAsCsv() {
    const csv = video.reps().map((rep, index) => (
      analysisTypes.map((analysisType) =>
        video.analysisValueFor(index, analysisType)
      ).join(",")
    )).join("\n");

    navigator.clipboard.writeText(csv);
  }

  if (video.successful()) {
    return (
      <Box>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            <Typography variant={"h5"} className={"on-dark"} style={{textTransform: "capitalize"}}>{video.movement}</Typography>
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={copyAsCsv} className={"on-dark"} variant="outlined">Copy as CSV</Button>
          </Grid>
        </Grid>

        <Box pt={1}>
          <RepSignalGraph video={video} frameIndex={frameIndex}/>
        </Box>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Rep</TableCell>
                {analysisTypes.map((analysisType) => (
                  <TableCell align="right" key={analysisType}>{analysisType}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {video.reps().map((rep, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>

                  {analysisTypes.map((analysisType) =>
                    <TableCell key={`${index}-${analysisType}`} align="right">
                      {video.analysisValueFor(index, analysisType)}
                    </TableCell>)
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
  else {
    return <VideoFailedMessage video={video}/>;
  }
}
