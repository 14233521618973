import { useRouteError } from "react-router-dom";
import React from "react";
import GuruConsolePageLayout from "./GuruConsolePageLayout";
import Box from "@mui/material/Box";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return <>
    <GuruConsolePageLayout>
      <Box p={2}>
        <h1>An unexpected error has occurred</h1>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </Box>
    </GuruConsolePageLayout>
  </>;
}
