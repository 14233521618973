import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {guruUtils} from "./common/Utils";


if (window.location.hostname !== "localhost") {
  console.log(`Starting Sentry`);
  Sentry.init({
    dsn: "https://8f5be85f461545d3afe38dd330bd2fcb@o1160698.ingest.sentry.io/4503940390060032",
    integrations: [new BrowserTracing({
      tracingOrigins: ["api.getguru.ai"],
    })],
    // Tune this down once we have a decent number of customers
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);
