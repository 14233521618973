import { Authenticator, Image, useTheme, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import React, { useEffect, useState } from "react";
import { amplifyConfig } from "./AmplifyConfig";
import Alert from "@mui/material/Alert";
import { Amplify, Auth, Hub } from "aws-amplify";
import Box from "@mui/material/Box";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { theme } from "./common/Theme";
import { createBrowserRouter, Navigate } from "react-router-dom";
import ErrorPage from "./common/ErrorPage";
import VideoIDE from "./video/ide/VideoIDE";
import VideoPage from "./video/VideoPage";
import RecentVideosPage from "./video/RecentVideosPage";
import { RouterProvider } from "react-router";
import CreateTenantPage from "./admin/CreateTenantPage";
import { guruUtils } from "./common/Utils";
import CircularProgress from "@mui/material/CircularProgress";
import AccountPage from "./admin/AccountPage";
import CreateAutomationPage from "./automation/CreateAutomationPage";
import ListAutomationsPage from "./automation/ListAutomationsPage";
import CreateMovementPage from "./movement/CreateMovementPage";
import MovementCreatedPage from "./movement/MovementCreatedPage";
import CreateSchemaPage from "./schema/CreateSchemaPage";
import ListSchemasPage from "./schema/ListSchemasPage";
import ListTemplatesPage from "./schema/ListTemplatesPage";
import HomePage from "./home/HomePage";
import AcceptInvitation from "./admin/AcceptInvitation";
import AutomationPage from "./automation/AutomationPage";
import AutomationExamplesPage from "./automation/AutomationExamplesPage";
import AutomationInvocationPage from "./automation/AutomationInvocationPage";

const queryParams = new URLSearchParams(window.location.search);
Amplify.configure(amplifyConfig);
Auth.configure(amplifyConfig);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/home" replace/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "account",
    element: <AccountPage />,
    errorElement: <ErrorPage />,
  },  
  {
    path: "automations",
    element: <ListAutomationsPage />,
    errorElement: <ErrorPage />,
  },    
  {
    path: "automations/new",
    element: <CreateAutomationPage />,
    errorElement: <ErrorPage />,
  },  
  {
    path: "automations/:id",
    element: <AutomationPage />,
    errorElement: <ErrorPage />,
  },    
  {
    path: "automations/:id/examples",
    element: <AutomationExamplesPage />,
    errorElement: <ErrorPage />,
  },  
  {
    path: "automations/:automationId/invocations/:invocationId",
    element: <AutomationInvocationPage />,
    errorElement: <ErrorPage />,
  },    
  {
    path: "invitations/accept",
    element: <AcceptInvitation />,
    errorElement: <ErrorPage />,
  },    
  {
    path: "videos",
    element: <RecentVideosPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "error",
    element: <ErrorPage />,
  },
  {
    path: "login-callback",
    element: <Navigate to="/" replace />,
  },
  {
    path: "logout-callback",
    element: <Navigate to="/" replace />,
  },
  {
    path: "movements/new",
    element: <CreateMovementPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "movements/created",
    element: <MovementCreatedPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "home",
    element: <HomePage/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "schemas",
    element: <ListSchemasPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "schemas/new",
    element: <CreateSchemaPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "templates",
    element: <ListTemplatesPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "videos/:id",
    element: <VideoPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "videos/:id/ide",
    element: <VideoIDE />,
    errorElement: <ErrorPage />,
  },
  {
    path: "editor",
    element: <VideoIDE />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  const [loading, setLoading] = useState(true);
  const [hasTenant, setHasTenant] = useState(false);

  const authComponents = {
    Header() {
      const { tokens } = useTheme();   

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="Guru" src={"/logo.png"} />

          {
            queryParams.get("error") && <>
              <Alert severity="error" sx={{mt: 2}}>
                {
                  queryParams.get("error_description").includes("has not been invited to Guru") ?
                    "You have not been invited to join Guru yet. Reach out to us at support@getguru.ai to request access." :
                    queryParams.get("error_description")
                }
              </Alert>
            </>
          }
        </View>
      );
    },
  };

  const checkTenant = async () => {
    if (await guruUtils.isLoggedIn()) {
      setHasTenant(await guruUtils.userHasTenant());
    }

    setLoading(false);
  };

  useEffect(() => {
    checkTenant();
  }, [setLoading]);

  const listener = (data) => {
    if (data.payload.event === "signIn") {
      checkTenant();
    }
  };
  Hub.listen("auth", listener);

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Authenticator
          loginMechanisms={["email"]}
          hideSignUp={false}
          socialProviders={["google"]}
          components={authComponents}
        >
          <Box
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {hasTenant ? (
              <RouterProvider router={router} />
            ) : (
              <CreateTenantPage tenantCreated={() => Auth.signOut()} />
            )}
          </Box>
        </Authenticator>
      )}
    </ThemeProvider>
  );
}

export default App;
