import React, { useEffect, useCallback, useRef, useState } from "react";
import { Alert, Button, CircularProgress } from "@mui/material";
import Ajv from "ajv";

const SchemaBuilder = ({ existingSchema, onSchemaUpdated }) => {
  const domEl = useRef(null);
  const JSONEditor = window.JSONEditor;
  const [validationErrors, setValidationErrors] = useState(null);
  const isNotSet =
    existingSchema === null || typeof existingSchema === "undefined";
  const [isDirty, setIsDirty] = useState(isNotSet);
  const [isSaving, setIsSaving] = useState(false);

  let ajv = new Ajv();
  let jsonEditor = useRef(null);

  const validateAsJsonSchema = (json) => {
    // validate the JSON is itself a valid JSON schema
    if (ajv.validateSchema(json)) {
      setValidationErrors(null);
    } else {
      setValidationErrors(ajv.errorsText());
    }
  };

  useEffect(() => {
    if (domEl.current && !jsonEditor.current) {
      const options = {
        mode: "code",
        modes: ["code", "tree", "preview"],
        showErrorTable: true,
        onValidate: validateAsJsonSchema,
        onChange: () => setIsDirty(true),
      };
      const placeHolderSchema = {
        type: "object",
        properties: {
          field1: { type: "number" },
          field2: { type: "string" },
          field3: {
            type: "object",
            properties: {
              subfield1: { type: "string" },
              subfield2: { type: "number" },
            },
          },
          field4: { type: "array", items: { type: "string" } },
          field5: { type: "boolean" },
          field6: { enum: ["value1", "value2"] },
        },
        required: ["field1", "field2"],
      };
      jsonEditor.current = new JSONEditor(
        domEl.current,
        options,
        existingSchema || placeHolderSchema
      );
    }

    return () => {
      if (jsonEditor.current) {
        jsonEditor.current.destroy();
        jsonEditor.current = null;
      }
    };
  }, []);

  const saveSchema = useCallback(async () => {
    if (jsonEditor.current) {
      const schema = jsonEditor.current.get();
      setIsSaving(true);
      try {
        await onSchemaUpdated(schema);
      } finally {
        setIsSaving(false);
      }
      setIsDirty(false);
    }
  }, [onSchemaUpdated]);

  return (
    <>
      <div
        ref={domEl}
        style={{
          height: "400px",
          overflow: "visible",
        }}
      />
      {validationErrors && (
        <Alert title={"Validation Failed"} severity="error">
          {validationErrors}
        </Alert>
      )}
      {isSaving ? (
        <CircularProgress title="Saving..." />
      ) : (
        <Button disabled={!isDirty || !!validationErrors} onClick={saveSchema}>
          Save
        </Button>
      )}
    </>
  );
};

export default SchemaBuilder;
