import React from "react";
import Typography from '@mui/material/Typography';

export default function GoogleCredInstructions() {
  return <ol>
    <li>
      Open the <a target="_blank" rel="noreferrer" href="https://console.developers.google.com/">Google Developer Console</a>.
    </li>
    <li>
      From the dropdown at the top of the page, either create a new project or select an existing one.
    </li>    
    <li>
      Enable the <Typography variant="overline">Google Drive API</Typography> and <Typography variant="overline">Google Sheets API</Typography>.
    </li>
    <li>
      Navigate to <Typography variant="overline">Credentials</Typography>.
    </li>    
    <li>
      Click to <Typography variant="overline">Create Credentials</Typography>.
    </li>
    <li>
      Select <Typography variant="overline">Service account</Typography>.
    </li>
    <li>
      For <Typography variant="overline">Service account name</Typography> enter <Typography variant="overline">Guru</Typography>.
    </li>             
    <li>
      Click <Typography variant="overline">Create and continue</Typography> and then <Typography variant="overline">Done</Typography>.
    </li>
    <li>
      Click on your newly created service account, and then select <Typography variant="overline">Keys</Typography>.
    </li>
    <li>
      Click <Typography variant="overline">Add Key</Typography> and then <Typography variant="overline">Create new key</Typography>.
    </li>    
    <li>
      Select <Typography variant="overline">JSON</Typography> and click <Typography variant="overline">Create</Typography>.
    </li>        
    <li>
      The credentials will be downloaded to your computer. Open them in any text editor, copy the contents, and paste it into the field below.
    </li>            
  </ol>;
}