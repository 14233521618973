import React, { useCallback } from "react";
import { Button, TextField, Box, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

export default function EditableList({
  items,
  onItemsChanged,
  validateItem,
  minItems,
  itemName,
}) {
  const validate = useCallback(
    (items) => {
      const validateFn = validateItem || (() => null);
      return items.map((item) => {
        const { error = null } = validateFn(item) || {};
        return { error, value: item };
      });
    },
    [validateItem]
  );

  const validatedItems = validate(items);

  const handleItemChange = (index, value) => {
    const newItems = [...items];
    newItems[index] = value;
    onItemsChanged(validate(newItems));
  };

  const addItem = () => {
    const newItems = [...items, ""];
    onItemsChanged(validate(newItems));
  };

  const createRow = (item, index) => {
    const textField = (
      <TextField
        error={validatedItems[index]?.error}
        key={index}
        variant="outlined"
        value={validatedItems[index]?.value}
        onChange={(e) => handleItemChange(index, e.target.value)}
        margin="normal"
        fullWidth
      />
    );

    return (
      <Stack direction={"row"} key={index}>
        {textField}

        <IconButton
          size="small"
          onClick={() => {
            const newItems = [...items];
            newItems.splice(index, 1);
            onItemsChanged(validate(newItems));
          }}
          disabled={index < (minItems || 0)}
        >
          <DeleteIcon
            sx={{
              color: index < (minItems || 0) ? "disabled.main" : "error.main",
            }}
          />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Box>
      {items.map(createRow)}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          marginTop: 1,
          justifyContent: "center",
        }}
      >
        <Button variant="contained" onClick={addItem}>
          Add {itemName || "Item"}
        </Button>
      </Box>
    </Box>
  );
}
