import { useState, useCallback } from "react";
import { drawRect } from "../workers/inference/guru/draw";
import { useClickAndDragHandler } from "../common/ClickHandler";
import { lightColor } from "../common/Theme";

export default function LabelInvocation({canvasElement}) {
  const [mouseDrag, setMouseDrag] = useState(null);
  const [mouseClickAt, setMouseClickAt] = useState(null);

  const resetCanvas = (canvasElement) => {
    const context = canvasElement.getContext("2d")
    context.clearRect(0, 0, canvasElement.width, canvasElement.height);
    return context;
  }

  const onMouseDown = useCallback(
    (canvasElement, { x: mouseX, y: mouseY }) => {
      setMouseClickAt({ x: mouseX, y: mouseY });
    },
    [setMouseClickAt]
  );

  const onMouseUp = useCallback(() => {
    setMouseClickAt(null);
  }, [setMouseClickAt]);

  const onDrag = useCallback(
    (canvasElement, from, to) => {
      const context = resetCanvas(canvasElement);
      drawRect(context, from, to, {borderWidth: 2, color: lightColor});

      setMouseDrag({ from: from, to: to });
    },
    [setMouseDrag]
  );

  const onDragFinished = () => {
    console.log(`Drag finished: ${JSON.stringify(mouseDrag)}`);
    navigator.clipboard.writeText(JSON.stringify({
      top: mouseDrag.from.y / canvasElement.height,
      left: mouseDrag.from.x / canvasElement.width,
      height: Math.abs(mouseDrag.to.y - mouseDrag.from.y) / canvasElement.height,
      width: Math.abs(mouseDrag.to.x - mouseDrag.from.x) / canvasElement.width,
    }, null, 2));

    resetCanvas(canvasElement);

    setMouseDrag(null);
  };

  const onMouseMove = useCallback(
    (_el, { x, y }) => {},
    []
  );

  useClickAndDragHandler({
    canvasElement,
    onMouseMove,
    onMouseDown,
    onMouseUp,
    onDrag,
    onDragFinished,
  });
}
