import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";

export default function NewTenantCTA() {
  return <Card variant="outlined" sx={{width: "400px", backgroundColor: "white", margin: "auto"}}>
    <CardContent>
      <Typography variant={"h5"} sx={{color: "grey !important"}}>
        New to Guru? Get started by creating an AI Schema.
      </Typography>
    </CardContent>
    <CardActions>
      <Button size={"small"} onClick={() => window.location.href = "/schemas/new"}>Create Schema</Button>
    </CardActions>
  </Card>
}
