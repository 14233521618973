import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import * as Sentry from "@sentry/react";
import Modal from "@mui/material/Modal";
import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";

function CreateSchemaModal(props, ref) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [schemaName, setSchemaName] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const fileInputRef = useRef();

  useImperativeHandle(ref, () => ({
    openModal: () => setOpen(true),
  }));

  useEffect(() => {
    async function loadTemplates() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/schemas/templates`,
        headers: await guruUtils.getAuthHeaders(),
      })
        .then(function (response) {
          setTemplates(response.data);

          if (response.data && response.data.length > 0) {
            setSelectedTemplate(response.data[0]);
          }
        })
        .catch((reason) => {
          let errorMessage = `Get templates failed because ${reason}`;
          console.error(errorMessage);
          throw errorMessage;
        })
        .finally(() => setLoading(false));
    }

    loadTemplates();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = async (name, template) => {
    setLoading(true);

    axios
      .post(
        `https://${await guruUtils.apiDomain()}/schemas`,
        {
          name: name,
          templateId: template.id,
        },
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      )
      .then(function (response) {
        window.location.href = `/editor?schemaId=${response.data.id}&videoId=${response.data.testVideoId}`;
      })
      .catch((reason) => {
        let errorMessage = `Failed to create schema because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      })
      .finally(() => handleClose());
  };

  const uploadClicked = () => {
    fileInputRef.current.click();
  };

  const uploadVideo = async (video) => {
    const sentryTransaction = Sentry.startTransaction({ name: "upload-video" });
    const createVideoSpan = sentryTransaction.startChild({
      op: "create-video",
    });
    setUploading(true);

    return axios({
      method: "post",
      url: `https://${await guruUtils.apiDomain()}/videos`,
      headers: await guruUtils.getAuthHeaders(),
      data: {
        filename: video.name,
        size: video.size,
      },
    })
      .then(async function (response) {
        createVideoSpan.finish();

        await guruUtils.uploadVideo(sentryTransaction, response, video);

        window.location.href = `/editor?videoId=${response.data.id}`;
      })
      .finally(() => {
        sentryTransaction.finish();
      });
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h3" color="white">
            Create an Guru Schema
          </Typography>
          <Typography variant="h6" color="white">
            Schemas tell Guru how to process videos with AI.
          </Typography>
          <br />
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Typography variant="body1" color="white" marginBottom={2}>
                Name your schema
              </Typography>
              <TextField
                label="Schema Name"
                variant="outlined"
                value={schemaName}
                onChange={(e) => setSchemaName(e.target.value)}
                fullWidth
                color="primary"
              />
              <Typography variant="body1" color="white" marginBottom={2}>
                Choose a base template
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                templates && (
                  <Select
                    label="Template"
                    value={selectedTemplate}
                    onChange={(e) => setSelectedTemplate(e.target.value)}
                    fullWidth
                  >
                    {templates.map((template) => (
                      <MenuItem key={template.name} value={template}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </Select>
                )
              )}
              <Typography variant="body2" color="white">
                See Template Gallery
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleContinue(schemaName, selectedTemplate)}
                fullWidth
                style={{ marginTop: "20px" }}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default React.forwardRef(CreateSchemaModal);
