import React, {
  useCallback,
  useState,
  useRef,
  forwardRef,
  useEffect,
} from "react";
import { VideoPlayerOffscreenCanvas } from "./VideoPlayerOffscreenCanvas";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export const VideoPlayerCanvas = forwardRef(
  ({ onReady, src, ...props }, canvasRef) => {
    const [videoResolution, setVideoResolution] = useState(null);
    const [displayResolution, setDisplayResolution] = useState(null);
    const boxEl = useRef();
    const canvasEl = useRef();

    useEffect(() => {
      setVideoResolution(null);
      setDisplayResolution(null);
    }, [src]);

    const setCanvasRef = useCallback(
      (domEl) => {
        if (typeof canvasRef === "function") {
          canvasRef(domEl);
        } else {
          canvasRef.current = domEl;
        }
        canvasEl.current = domEl;
      },
      [canvasRef]
    );

    const resize = useCallback(() => {
      if (!boxEl.current || !videoResolution) {
        return;
      }
      const displayResolution = calculateDisplayResolution(
        boxEl.current,
        videoResolution
      );
      setDisplayResolution(displayResolution);
    }, [videoResolution, boxEl]);

    useEffect(() => {
      document.addEventListener("resize", resize);
      return () => {
        document.removeEventListener("resize", resize);
      };
    }, [resize]);

    useEffect(() => {
      if (videoResolution === null || videoResolution === undefined) {
        return;
      }
      resize();
    }, [videoResolution, resize]);

    const calculateDisplayResolution = (containerEl, videoResolution) => {
      const { width, height } = videoResolution;
      const maxHeight = 640;
      const maxWidth = containerEl.offsetWidth;
      const scale = Math.min(maxHeight / height, maxWidth / width, 1);
      const targetWidth = width * scale;
      const xMargin = (containerEl.offsetWidth - targetWidth) / 2;
      return {
        width: targetWidth,
        height: height * scale,
        xMargin,
      };
    };

    const _onReady = useCallback(
      ({ numFrames, durationMs, videoResolution: { width, height } }) => {
        const displayResolution = calculateDisplayResolution(boxEl.current, {
          width,
          height,
        });
        setDisplayResolution(displayResolution);
        setVideoResolution({ width, height });
        if (onReady) {
          onReady({ numFrames, durationMs, videoResolution });
        }
      },
      [onReady, boxEl, videoResolution]
    );

    const sizeProps = displayResolution
      ? { width: displayResolution.width, height: displayResolution.height }
      : {};

    return (
      <Box
        ref={boxEl}
        style={{
          position: "relative",
          width: "100%",
          zIndex: 1,
          minHeight: "640px",
        }}
        {...sizeProps}
      >
        {displayResolution === null ? (
          <CircularProgress />
        ) : (
          <>
            <canvas
              ref={setCanvasRef}
              style={{
                position: "absolute",
                paddingLeft: displayResolution ? displayResolution.xMargin : 0,
                paddingRight: displayResolution ? displayResolution.xMargin : 0,
              }}
              width={displayResolution ? displayResolution.width : 0}
              height={displayResolution ? displayResolution.height : 0}
            />
          </>
        )}
        <VideoPlayerOffscreenCanvas
          {...props}
          src={src}
          style={{
            position: "absolute",
            paddingLeft: displayResolution ? displayResolution.xMargin : 0,
            paddingRight: displayResolution ? displayResolution.xMargin : 0,
          }}
          width={displayResolution ? displayResolution.width : 0}
          height={displayResolution ? displayResolution.height : 0}
          onReady={_onReady}
        />
      </Box>
    );
  }
);
