import { useState } from "react";
import axios from "axios";
import { guruUtils } from "../common/Utils";

const useDocumentUpload = (props) => {
  const [files, setFiles] = useState([]);

  const handleFileUpload = async (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      // First, get a presigned URL from the server
      var response;
      try {
        response = await axios.put(
          `https://${await guruUtils.apiDomain()}/automations/files`,
          { file_name: file.name, file_size: file.size },
          { headers: await guruUtils.getAuthHeaders() }
        );
      } catch (error) {
        const message = error.response?.data?.message || error.toString();
        setFiles([
          ...files,
          { file_name: file.name, status: "error", error: message },
        ]);
        continue;
      }

      setFiles([...files, { file_name: file.name, status: "uploading" }]);
      // The JSON response has the keys: 'fields', 'file_id', and 'url'
      const { fields, url, file_id, extension } = response.data;
      // Upload the file to S3 with a POST request to the url
      const formData = new FormData();
      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value);
      });
      try {
        await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {
        setFiles((files) =>
          files.map((existingFile) => {
            if (existingFile.file_name === file.name) {
              return { ...existingFile, status: "error", error: error.message };
            }
            return existingFile;
          })
        );
      }

      setFiles((files) =>
        files.map((existingFile) => {
          if (existingFile.file_name === file.name) {
            return { ...existingFile, file_id, extension, status: "uploaded" };
          }
          return existingFile;
        })
      );
    }
  };

  const handleFileDelete = (event, index) => {
    event && event.preventDefault();
    setFiles(files.filter((_, i) => i !== index));
  };

  return { files, handleFileUpload, handleFileDelete };
};

export default useDocumentUpload;
