import {
  drawCircle,
  drawLine,
  drawPath,
  drawText,
  MUI_SECONDARY,
} from "../../../workers/inference/guru/draw";

export const LIGHT_GREEN = "#58d68d";

export const drawSkeleton = (ctx, jTP, t, jointPairs) => {
  const [w, h] = [ctx.canvas.width, ctx.canvas.height];
  const positionOf = (joint) => {
    const p = jTP.positionAt(joint, t);
    if (p) {
      return { x: p.x * w, y: p.y * h };
    }
    return null;
  };
  Object.entries(jointPairs).forEach(([a, b]) => {
    const joint1 = positionOf(a);
    const joint2 = positionOf(b);
    if (joint1 && joint2) {
      drawConnector(ctx, joint1, joint2);
    }
  });
};

export const drawConnector = (ctx, p1, p2) => {
  drawLine(
    ctx,
    { position: { x: p1.x, y: p1.y } },
    { position: { x: p2.x, y: p2.y } },
    {
      color: "white",
      alpha: 0.75,
    }
  );
  [p1, p2].forEach((p) => {
    drawCircle(ctx, p.x, p.y, 4, {
      color: MUI_SECONDARY,
      alpha: 1.0,
    });
  });
};

export const drawCurrentRepOverlay = (ctx, reps, tMs, x, y) => {
  let currentRep = reps.find((rep) => {
    return rep.startTimestampMs <= tMs && tMs <= rep.endTimestampMs;
  });
  const repIndex = reps.indexOf(currentRep);

  if (repIndex !== -1) {
    drawText(
      ctx,
      `Rep ${repIndex + 1}`,
      x,
      y,
      ctx.canvas.width,
      { fontSize: 18 }
    );
  }
};

const INTERPOLATION_STEP_SZ_MS = 25;
const timeRange = (t1, t2, step = INTERPOLATION_STEP_SZ_MS) => {
  const ts = [t1];
  while (t1 <= t2) {
    t1 += step;
    ts.push(t1);
  }
  return ts;
};

export const drawBarPath = (ctx, video, tMs) => {
  tracePoint(ctx, video, tMs, "platesCenter");
};

export const tracePoint = (ctx, video, tMs, pointName) => {
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  const drawPointsInWindow = (timestamps, options) => {
    const points = timestamps
      .map((t) => video.jointPositionAt(pointName, t))
      .filter((x) => x);
    if (points) {
      const toAbsolute = (p) => {
        return { x: p.x * width, y: p.y * height };
      };
      drawPath(ctx, points.map(toAbsolute), options);
    }
  };

  if (video.reps()) {
    const findIndexOfLast = (arr, predicate) => {
      const i = [...arr].reverse().findIndex(predicate);
      if (i >= 0) {
        return arr.length - 1 - i;
      }
      return -1;
    };
    const prevRepIndex = findIndexOfLast(video.reps(), (r) => tMs > r.endTimestampMs);
    // draw the previous rep in semi-transparent gray
    if (prevRepIndex >= 0) {
      const prevRep = video.reps()[prevRepIndex];
      drawPointsInWindow(
        timeRange(prevRep.startTimestampMs, prevRep.endTimestampMs),
        { color: "gray", alpha: 0.6 }
      );
    }
    // draw the current rep in red
    const curRepIndex = video.reps().findIndex(
      (r) => tMs >= r.startTimestampMs && tMs <= r.endTimestampMs
    );
    if (curRepIndex >= 0) {
      const curRep = video.reps()[curRepIndex];
      drawPointsInWindow(timeRange(curRep.startTimestampMs, tMs), {
        color: "red",
        alpha: 0.75,
      });
    }
  } else {
    const WINDOW_SZ_MS = 3000;
    drawPointsInWindow(timeRange(tMs - WINDOW_SZ_MS, tMs));
  }
};
