import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";
import Paper from "@mui/material/Paper";
import { Auth } from "aws-amplify";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function AcceptInvitation() {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const accept = async (event) => {
    event.preventDefault();
    console.log(`Accepting invitation`);
    setLoading(true);

    await axios.get(
      `https://${await guruUtils.apiDomain()}/invitations/accept`,
      {
        headers: await guruUtils.getAuthHeaders(),
      }
    ).then(async function (response) {
      await Auth.signOut();
      navigate("/");
    }).catch((reason => {
      let errorMessage = `Failed to accept invitation because ${reason}`;
      console.log(errorMessage);
    })).finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <>
        <div className={"centered"}>
          <CircularProgress />
        </div>
      </>
    );
  } else {
    return (
      <>
        <GuruConsolePageLayout p={5}>
          <Box p={2}>
            <Paper elevation={3} className={"info-card"}>
              <Typography variant="h5" gutterBottom>
                Migrate Team
              </Typography>
              <Typography>
                You are about to migrate your account to another team.
                Your existing schemas and videos will <strong>not</strong> be migrated.
              </Typography>

              <Typography pt={2}>
                After migration is complete, you will need to log in again.
              </Typography>              

              <Typography pt={2} pb={1}>
                Are you sure you want to continue?
              </Typography>

              <Button variant="contained" type="submit" onClick={accept}>Yes</Button>
            </Paper>
          </Box>
        </GuruConsolePageLayout>
      </>
    );
  }
}
