import React, { useCallback, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import { guruUtils } from "../../common/Utils";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Button from "@mui/material/Button";
import Video from "../Video";
import { CodeEditor } from "../CodeBlock";
import ReactJson from "react-json-view";
import { darkColor } from "../../common/Theme";
import {
  PlayCircleFilled,
  FileOpen,
  Save,
  AddCircle,
  Delete,
  Settings,
  Code,
  RocketLaunch,
  InsertDriveFile,
  Help,
  Tour,
  Bolt,
  Memory,
} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogTitle from "@mui/material/DialogTitle";
import MovementSelect from "../../movement/MovementSelect";
import DialogContent from "@mui/material/DialogContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../common/TabPanel";
import { Typography } from "@mui/material";
import SplitPane from "../../common/SplitPane";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Alert from "@mui/material/Alert";
import { InferenceProgressBar } from "../InferenceProgressBar";
import VideoIDEDeploy from "./VideoIDEDeploy";
import { useSearchParams } from "react-router-dom";
import { useInferenceWorker } from "./ModuleWorker";
import Divider from "@mui/material/Divider";
import newIDETour from "./IDETour";
import StackTrace from "./StackTrace";
import "shepherd.js/dist/css/shepherd.css";
import "./Tour.css";
import GuruConsolePageLayout from "../../common/GuruConsolePageLayout";
import FrameRateInput from "./FrameRateInput";
import { useCode } from "./IDEUtils";
import VideoIDEModels from "./VideoIDEModels";
import VideoUploadSchema from "./VideoUploadSchema";
import VideoIDEFoundationModels from "./VideoIDEFoundationModels";

export default function VideoIDE() {
  const [searchParams] = useSearchParams();
  const urlSchemaId = searchParams.get("schemaId");
  const urlVideoId = searchParams.get("videoId");
  const [schemaId, setSchemaId] = useState(urlSchemaId);
  const [videoId, setVideoId] = useState(urlVideoId);
  const [video, setVideo] = useState(null);
  const code = useCode({
    initialText:
      "export default class GuruSchema {\n" +
      "  async processFrame(frame) {\n" +
      "    return this.outputs();\n" +
      "  }\n" +
      "\n" +
      "  renderFrame(frameCanvas) {\n" +
      "  }\n" +
      "\n" +
      "  async outputs() {\n" +
      "    return {};\n" +
      "  }\n" +
      "}",
  });
  const [output, setOutput] = useState({});
  const [targetInferenceFps, setTargetInferenceFps] = useState(8);
  const [videoInferenceProgress, setVideoInferenceProgress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [running, setRunning] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [schema, setSchema] = useState("");
  const [creatingSchema, setCreatingSchema] = useState(false);
  const [isCancellingInference, setIsCancellingInference] = useState(false);
  const [newSchemaName, setNewSchemaName] = useState(null);
  const [openingSchema, setOpeningSchema] = useState(false);
  const [configuringSchema, setConfiguringSchema] = useState(false);
  const [schemas, setSchemas] = useState([]);
  const [schemaActivities, setSchemaActivities] = useState([]);
  const [enabledModelNames, setEnabledModelNames] = useState([]);

  const overlayCanvas = useRef(null);
  const captureCanvas = useRef(null);

  const TAB_VALUES = {
    MENU: "menu-tab",
    CODE: "code-tab",
    MODELS: "models-tab",
    FOUNDATION_MODELS: "foundation-models-tab",
    DEPLOY: "deploy-tab",
  };
  const [selectedTabValue, setSelectedTabValue] = useState(TAB_VALUES.CODE);

  const [fileMenuAnchor, setFileMenuAnchor] = useState(null);
  const fileMenuOpen = Boolean(fileMenuAnchor);
  const [error, setError] = useState(null);
  const [runtimeError, setRuntimeError] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(true);
  const [tour, tourAlreadyTakenOnLoad] = newIDETour();
  const [tourAlreadyTaken, setTourAlreadyTaken] = useState(
    tourAlreadyTakenOnLoad
  );
  const renderLoopRef = useRef(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoPlayerRef = useCallback((videoElement) => {
    if (videoElement) {
      setVideoPlayer(videoElement);
    }
  }, []);
  const saveButton = useRef(null);

  // TODO: remove useCallback hook
  const handleInferenceMessage = useCallback(({ data: { command, args } }) => {
    switch (command) {
      case "inference-progress-updated":
        const { progress, exception, result, wasCancelled } = args;
        if (exception) {
          console.error(`Inference exception ${exception}: ${exception.stack}`);
          setRuntimeError(exception);
          setVideoInferenceProgress(null);
          setRunning(false);
        } else if (wasCancelled) {
          setVideoInferenceProgress(null);
          setRunning(false);
          setIsCancellingInference(false);
          setRuntimeError(null);
        } else if (progress === 1.0) {
          setVideoInferenceProgress(null);
          setRunning(false);
          setOutput(result);
        } else {
          setVideoInferenceProgress(progress);
        }
        break;
      case "inited":
        const { error } = args || {};
        setLoading(false);
        if (error) {
          setError(error);
        }
        break;
      case "validate-schema-finished":
        setPendingSaveOperation((prev) => ({
          ...prev,
          isValid: args.isValid,
          validationError: args.validationError || null,
        }));
        break;
      default:
        console.error(`Unknown command: ${command}`);
    }
  }, []);

  const [isInferenceWorkerReady, postMessageToInferenceWorker] =
    useInferenceWorker(handleInferenceMessage);

  const getAreCustomModelsEnabled = () => {
    if (schemaId === null) {
      return false;
    }

    const currentUrl = new URL(window.location.href);
    const enableCustomModelsParam =
      currentUrl.searchParams.get("enableCustomModels");
    const isLocalTesting = currentUrl.href.includes("localhost:3000");
    // If 'enableCustomModels' query parameter is present, use its value (0 or 1)
    // If it's not present, default to local testing logic
    const featureFlag =
      enableCustomModelsParam !== null
        ? enableCustomModelsParam === "1" || enableCustomModelsParam === "true"
        : isLocalTesting;
    return featureFlag;
  };

  useEffect(() => {
    if (!videoPlayer) {
      return;
    }

    const renderLoop = async () => {
      if (videoPlaying) {
        videoFrameUpdated();
      }
      renderLoopRef.current = window.requestAnimationFrame(renderLoop);
    };
    renderLoopRef.current = window.requestAnimationFrame(renderLoop);

    return () => {
      cancelAnimationFrame(renderLoopRef.current);
    };
  }, [videoPlayer, videoPlaying]);

  useEffect(() => {
    setVideo(null);
    setLoading(true);

    if (videoId) {
      console.log(`Loading video ${videoId}`);

      const pollVideoInterval = setInterval(async () => {
        const video = await axios({
          url: `https://${await guruUtils.apiDomain()}/videos/${videoId}`,
          headers: await guruUtils.getAuthHeaders(),
        })
          .then(function (response) {
            return new Video({
              id: videoId,
              status: response.data.status,
              uri: response.data.uri,
              overlays: response.data["overlays"],
              repCount: response.data["repCount"],
              fps: response.data["fps"],
              height: response.data["height"],
              width: response.data["width"],
            });
          })
          .catch((reason) => {
            let errorMessage = `Video fetch for ${videoId} failed because ${reason}`;
            console.log(errorMessage);
            throw errorMessage;
          });
        if (video.uri && video.uri.includes(".mp4")) {
          setVideo(video);
          setLoading(false);
          clearInterval(pollVideoInterval);
        }
      }, 2000);

      return () => clearInterval(pollVideoInterval);
    } else {
      setLoading(false);
    }
  }, [videoId]);

  useEffect(() => {
    if (!tourAlreadyTaken) {
      tour.start();
      setTourAlreadyTaken(true);
    }
  }, [tour, tourAlreadyTaken]);

  useEffect(() => {
    async function loadSchemas() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/schemas`,
        headers: await guruUtils.getAuthHeaders(),
      })
        .then((response) => {
          setSchemas(response.data);
          if (schemaId) {
            const schema = response.data.find(
              (nextSchema) => nextSchema.id === schemaId
            );
            setSchema(schema);
            setSchemaActivities(schema.activities);
            resetFromSchema(schema);
          }
        })
        .catch((reason) => {
          let errorMessage = `Failed to list schemas because ${reason}`;
          console.log(errorMessage);
          throw errorMessage;
        });
    }

    loadSchemas();
  }, [schemaId]);

  useEffect(() => {
    if (!(schemaId && isInferenceWorkerReady)) {
      return;
    }
    listEnabledModels().then((models) => {
      models.forEach(({ modelName, uri }) => {
        postMessageToInferenceWorker({
          command: "update-custom-models",
          args: {
            modelName,
            modelUrl: uri,
            isEnabled: true,
          },
        });
      });
    });
  }, [schemaId, isInferenceWorkerReady]);

  useEffect(() => {
    const globalKeyUp = (event) => {
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();

        saveButton.current.click();
      }
    };

    document.addEventListener("keydown", globalKeyUp);

    return () => {
      document.removeEventListener("keydown", globalKeyUp);
    };
  }, []);

  const listEnabledModels = async () => {
    try {
      const response = await axios.get(
        `https://${await guruUtils.apiDomain()}/schemas/${schemaId}/custom_models`,
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      );
      const models = await response.data["models"];
      setEnabledModelNames(models.map(({ modelName }) => modelName));
      return models;
    } catch (e) {
      console.error(`Failed to load enabled models because ${e}`);
      throw e;
    }
  };

  const bindNewSchemaNameField = useCallback((newSchemaNameField) => {
    if (newSchemaNameField) {
      setTimeout(() => {
        newSchemaNameField.focus();
      }, 100);
    }
  }, []);

  const configureSchemaClicked = () => {
    setFileMenuAnchor(null);
    setConfiguringSchema(true);
  };

  const createSchema = async (event) => {
    event.preventDefault();

    axios
      .post(
        `https://${await guruUtils.apiDomain()}/schemas`,
        {
          name: newSchemaName,
          code: code.text,
          testVideoId: videoId,
        },
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      )
      .then(function (response) {
        setSchemaId(response.data.id);
        setCreatingSchema(false);
        setUnsavedChanges(false);
      })
      .catch((reason) => {
        let errorMessage = `Failed to create schema because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
  };

  const createSchemaClicked = () => {
    setFileMenuAnchor(null);
    setCreatingSchema(true);
  };

  const deleteSchemaClicked = async () => {
    setFileMenuAnchor(null);

    if (window.confirm("Are you sure you want to delete this Schema?")) {
      axios
        .delete(`https://${await guruUtils.apiDomain()}/schemas/${schemaId}`, {
          headers: await guruUtils.getAuthHeaders(),
        })
        .then(function (response) {
          setSchemaId(null);
          setSchema(null);
        })
        .catch((reason) => {
          let errorMessage = `Failed to delete schema because ${reason}`;
          console.log(errorMessage);
          throw errorMessage;
        });
    }
  };

  const documentationClicked = () => {
    setFileMenuAnchor(null);
    window.open("https://docs.getguru.fitness/#guru-js", "_blank").focus();
  };

  const onHoverProcessWord = (word) => {
    if (word === "frame") {
      return [
        "```\n" +
          "async findObjects(objectTypes, {keypoints = true} = {})\n\n" +
          "```",
      ];
    } else if (word === "frameCanvas") {
      return [
        "```\n" +
          "drawBoundingBox(object, color, width = 2)\n\n" +
          "drawSkeleton(object, lineColor, keypointColor, lineWidth = 2, keypointRadius = 5)\n\n" +
          "```",
      ];
    }
  };

  const onProcessCodeCompletion = (line) => {
    const suggestions = [];

    if (line.includes("frame.")) {
      suggestions.push({
        label: "findObjects",
        documentation: "Find objects of a specific type within the video",
        insertText: 'findObjects("objectType", {keypoints: true});',
      });
    } else if (line.includes("frameCanvas.")) {
      suggestions.push({
        label: "drawBoundingBox",
        documentation:
          "Draw a box with the given color around this object's location onto the frame",
        insertText: "drawBoundingBox(object, new Color(255, 0, 0));",
      });
      suggestions.push({
        label: "drawSkeleton",
        documentation: "Draw the skeleton for the given object onto the frame",
        insertText:
          "drawSkeleton(object, new Color(0, 255, 0), new Color(0, 0, 255));",
      });
    }

    return suggestions;
  };

  const openSchemaClicked = async () => {
    setFileMenuAnchor(null);
    setOpeningSchema(true);
  };

  const pauseVideo = () => {
    setVideoPlaying(false);
  };

  const playVideo = () => {
    setVideoPlaying(true);
  };

  const resetFromSchema = (schema) => {
    if (schema.code) {
      code.resetText(schema.code);
      code.setIsDirty(true);
    }

    setUnsavedChanges(false);
  };

  const runClicked = useCallback(
    async (codeToRun) => {
      setRunning(true);

      const frameTimestampMs = Math.round(videoPlayer.currentTime * 1000);
      postMessageToInferenceWorker({
        command: "infer-video",
        args: {
          script: codeToRun,
          inferenceFps: targetInferenceFps,
          videoUri: video.uri,
          frameTimestampMs,
        },
      });
      setVideoInferenceProgress(0);
      setRuntimeError(null);
      code.setText(codeToRun);
      code.setIsDirty((isDirty) => {
        if (isDirty) {
          // Discard inference results that were calculated with stale code
          setOutput({});
        }
        return false;
      });
    },
    [targetInferenceFps, video, postMessageToInferenceWorker]
  );

  const triggerCodeValidation = useCallback(
    async (codeToSave) => {
      postMessageToInferenceWorker({
        command: "validate-schema",
        args: {
          script: codeToSave,
        },
      });
    },
    [postMessageToInferenceWorker]
  );

  const [pendingSaveOperation, setPendingSaveOperation] = useState(null);

  useEffect(() => {
    const onPendingSaveChange = async () => {
      if (pendingSaveOperation === null) {
        return;
      }

      const { name, code, activities, testVideoId, isValid, validationError } =
        pendingSaveOperation;
      if (isValid === null) {
        triggerCodeValidation(code);
      } else if (validationError) {
        setError(`Schema is invalid. ${validationError}`);
        setPendingSaveOperation(null);
      } else if (isValid) {
        setError(null);
        try {
          await axios.put(
            `https://${await guruUtils.apiDomain()}/schemas/${schemaId}`,
            {
              name,
              code,
              activities,
              testVideoId,
            },
            {
              headers: await guruUtils.getAuthHeaders(),
            }
          );
          setConfiguringSchema(false);
          setUnsavedChanges(false);
          setPendingSaveOperation(null);
        } catch (e) {
          let errorMessage = `Failed to save schema because ${e}`;
          setPendingSaveOperation(null);
          setError(errorMessage);
          console.error(errorMessage);
          throw errorMessage;
        }
      }
    };
    onPendingSaveChange();
  }, [pendingSaveOperation, triggerCodeValidation]);

  const saveSchemaClicked = async () => {
    setPendingSaveOperation((prev) => {
      return (
        prev || {
          name: schema.name,
          code: code.text,
          activities: schemaActivities,
          testVideoId: videoId,
          isValid: null,
          validationError: null,
        }
      );
    });
    setFileMenuAnchor(null);
  };

  const schemaActivitiesChosen = (movements) => {
    setSchemaActivities(movements.map((nextMovement) => nextMovement.name));
  };

  const schemaChosen = (event) => {
    const schemaId = event.target.value;
    setSchemaId(schemaId);
    const schema = schemas.find((nextSchema) => nextSchema.id === schemaId);
    setSchema(schema);
    setOpeningSchema(false);

    resetFromSchema(schema);
  };

  const tourClicked = () => {
    setFileMenuAnchor(null);
    tour.start();
  };

  const videoFrameUpdated = useCallback(async () => {
    const frameTimestampMs = Math.round(videoPlayer.currentTime * 1000);
    postMessageToInferenceWorker({
      command: "render-frame",
      args: {
        frameTimestampMs,
      },
    });
  }, [postMessageToInferenceWorker]);

  const videoLoaded = useCallback(async () => {
    if (videoPlayer) {
      captureCanvas.current.width = video.width;
      captureCanvas.current.height = video.height;

      if (video.width > video.height) {
        // landscape, so account for whitespace at top and bottom.
        overlayCanvas.current.width = videoPlayer.clientWidth;
        overlayCanvas.current.height =
          video.height * (videoPlayer.clientWidth / video.width);
      } else {
        // portrait, so account for whitespace on left and right.
        overlayCanvas.current.height = videoPlayer.clientHeight;
        overlayCanvas.current.width =
          video.width * (videoPlayer.clientHeight / video.height);
      }

      const offscreenCanvas =
        overlayCanvas.current.transferControlToOffscreen();
      postMessageToInferenceWorker(
        {
          command: "set-canvas",
          args: {
            canvas: offscreenCanvas,
          },
        },
        [offscreenCanvas]
      );
    }
  }, [postMessageToInferenceWorker, videoPlayer, video]);

  const cancelInference = useCallback(() => {
    postMessageToInferenceWorker({
      command: "cancel-inference",
    });
    setIsCancellingInference(true);
  }, [postMessageToInferenceWorker]);

  const getRunButtonOrProgressBar = () => {
    if (!isInferenceWorkerReady) {
      return <CircularProgress />;
    } else if (running) {
      return (
        <>
          <Stack>
            <InferenceProgressBar
              progress={videoInferenceProgress}
              cancelIsDisabled={isCancellingInference}
              onCancel={cancelInference}
            />
          </Stack>
        </>
      );
    }
    var style = {};
    if (!code.isDirty) {
      // gray-out the button but keep it enabled
      style["filter"] = "grayscale(100%)";
    }
    return (
      <Button
        id={"run-inference"}
        startIcon={<PlayCircleFilled />}
        onClick={() => runClicked(code.text)}
        variant="contained"
      >
        Run
      </Button>
    );
  };

  const getOutputPane = () => {
    if (runtimeError) {
      return <StackTrace exception={runtimeError} />;
    }
    return (
      <ReactJson
        src={output}
        name={null}
        theme={"google"}
        collapsed={4}
        sortKeys={false}
        displayDataTypes={false}
        enableClipboard={false}
      />
    );
  };

  const TabLabel = ({ icon, text }) => (
    <Box display="flex" alignItems="center">
      {icon}
      <Box ml={1}>{text}</Box>
    </Box>
  );

  const handleTabChange = (event, newTabValue) => {
    setSelectedTabValue(newTabValue);
  };

  const onModelEnabledOrDisabled = async ({
    modelName,
    modelUrl,
    isEnabled,
  }) => {
    if (isEnabled) {
      await axios.post(
        `https://${await guruUtils.apiDomain()}/schemas/${schemaId}/custom_models`,
        { modelName },
        { headers: await guruUtils.getAuthHeaders() }
      );
    } else {
      await axios.delete(
        `https://${await guruUtils.apiDomain()}/schemas/${schemaId}/custom_models`,
        {
          data: { modelName },
          headers: await guruUtils.getAuthHeaders(),
        }
      );
    }
    listEnabledModels();
    postMessageToInferenceWorker({
      command: "update-custom-models",
      args: {
        modelName,
        modelUrl,
        isEnabled,
      },
    });
  };

  const onNewVideoUpload = (videoId) => {
    setVideoId(videoId);
    postMessageToInferenceWorker({
      command: "clear-canvas",
    });
  };

  const handleNameChange = (event) => {
    setUnsavedChanges(true);
    setSchema((prevSchema) => ({ ...prevSchema, name: event.target.value }));
  };

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <GuruConsolePageLayout>
          {error && (
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          )}
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Box
                  sx={{
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {schema && schema.name ? (
                    <TextField
                      value={schema.name}
                      onChange={handleNameChange}
                      style={{
                        color: "white",
                        marginLeft: "5px",
                        fontSize: "12px",
                        fontStyle:
                          !schema || !schema.name ? "italic" : "normal",
                      }}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                          fontWeight: "bold",
                          wordWrap: "break-word",
                        },
                      }}
                    />
                  ) : (
                    <TextField
                      value="Untitled"
                      disabled
                      style={{
                        color: "white",
                        marginLeft: "10px",
                        fontStyle: "italic",
                      }}
                      inputProps={{
                        style: {
                          fontSize: "14px",
                          wordWrap: "break-word",
                        },
                      }}
                    />
                  )}
                  <Button
                    ref={saveButton}
                    size="small"
                    onClick={saveSchemaClicked}
                    style={{ color: unsavedChanges ? darkColor : "gray" }}
                    startIcon={
                      <Save
                        style={{
                          color: unsavedChanges ? darkColor : "gray",
                        }}
                      />
                    }
                  >
                    Save
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Tabs
                      value={selectedTabValue}
                      onChange={handleTabChange}
                      size="small"
                    >
                      <Tab
                        style={{ color: darkColor, size: "small" }}
                        label={
                          <TabLabel icon={<InsertDriveFile />} text="Menu" />
                        }
                        value={TAB_VALUES.MENU}
                      />
                      <Tab
                        style={{ color: darkColor }}
                        label={<TabLabel icon={<Code />} text="Code" />}
                        value={TAB_VALUES.CODE}
                      />
                      {getAreCustomModelsEnabled() && (
                        <Tab
                          style={{ color: darkColor }}
                          label={
                            <TabLabel icon={<Bolt />} text="Fast Models" />
                          }
                          value={TAB_VALUES.MODELS}
                        />
                      )}
                      <Tab
                        style={{ color: darkColor }}
                        label={
                          <TabLabel
                            icon={<Memory />}
                            text="Foundation Models"
                          />
                        }
                        value={TAB_VALUES.FOUNDATION_MODELS}
                      />
                      {schemaId && (
                        <Tab
                          style={{ color: darkColor }}
                          label={
                            <TabLabel icon={<RocketLaunch />} text="Deploy" />
                          }
                          value={TAB_VALUES.DEPLOY}
                        />
                      )}
                    </Tabs>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      paddingRight: 2,
                    }}
                  >
                    <FrameRateInput
                      initialValue={targetInferenceFps}
                      onChange={setTargetInferenceFps}
                    />
                    {getRunButtonOrProgressBar()}
                  </Box>
                </Box>

                <Menu
                  anchorEl={fileMenuAnchor}
                  open={fileMenuOpen}
                  onClose={() => setFileMenuAnchor(null)}
                >
                  {!schemaId && (
                    <MenuItem onClick={createSchemaClicked}>
                      <ListItemIcon>
                        <AddCircle className={"on-dark"} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Save...</ListItemText>
                    </MenuItem>
                  )}

                  <MenuItem onClick={openSchemaClicked}>
                    <ListItemIcon>
                      <FileOpen className={"on-dark"} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Open...</ListItemText>
                  </MenuItem>

                  {schemaId && (
                    <MenuItem onClick={saveSchemaClicked}>
                      <ListItemIcon>
                        <Save className={"on-dark"} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Save</ListItemText>
                    </MenuItem>
                  )}
                  {schemaId && (
                    <MenuItem onClick={deleteSchemaClicked}>
                      <ListItemIcon>
                        <Delete className={"on-dark"} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  )}
                  {schemaId && (
                    <MenuItem onClick={configureSchemaClicked}>
                      <ListItemIcon>
                        <Settings className={"on-dark"} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Configure...</ListItemText>
                    </MenuItem>
                  )}

                  <Divider />

                  <MenuItem onClick={documentationClicked}>
                    <ListItemIcon>
                      <Help className={"on-dark"} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Documentation</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={tourClicked}>
                    <ListItemIcon>
                      <Tour className={"on-dark"} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Tour</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            </Grid>
            <Grid item xs={6} style={{ height: "100%" }}>
              <TabPanel
                value={selectedTabValue}
                index={TAB_VALUES.CODE}
                style={{ position: "relative" }}
              >
                <SplitPane style={{ backgroundColor: "#1e1e1e" }}>
                  <CodeEditor
                    initialValue={code.initialText}
                    language="javascript"
                    onChange={(text) => {
                      code.setText(text);
                      code.setIsDirty(true);
                      setUnsavedChanges(true);
                    }}
                    onUnmount={(editor) => {
                      const model = editor.getModel();
                      const value = model && model.getValue();
                      if (value) {
                        code.resetText(value);
                      }
                    }}
                    onHoverWord={onHoverProcessWord}
                    onCodeCompletion={onProcessCodeCompletion}
                  />

                  <Box
                    p={0}
                    id={"schema-output"}
                    style={{ backgroundColor: "#1e1e1e", overflowY: "auto" }}
                    data-output-json={JSON.stringify(output)}
                  >
                    {getOutputPane()}
                  </Box>
                </SplitPane>
              </TabPanel>

              {getAreCustomModelsEnabled() && (
                <TabPanel value={selectedTabValue} index={TAB_VALUES.MODELS}>
                  <VideoIDEModels
                    enabledModelNames={enabledModelNames}
                    onModelEnabledOrDisabled={onModelEnabledOrDisabled}
                  />
                </TabPanel>
              )}

              <TabPanel
                value={selectedTabValue}
                index={TAB_VALUES.FOUNDATION_MODELS}
              >
                <VideoIDEFoundationModels />
              </TabPanel>

              <TabPanel value={selectedTabValue} index={TAB_VALUES.DEPLOY}>
                <VideoIDEDeploy schemaId={schemaId} />
              </TabPanel>
            </Grid>

            <Grid item xs={6} style={{ height: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  backgroundColor: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 1,
                  gap: 2,
                }}
              >
                <Typography variant={"h6"} color={"white"}>
                  Preview
                </Typography>
                <VideoUploadSchema onVideoUpload={onNewVideoUpload} />
              </Box>
              <Box
                style={{
                  position: "relative",
                  display: "grid",
                  placeItems: "center",
                  backgroundColor: "black",
                }}
              >
                {video ? (
                  // Show the video player
                  <>
                    <video
                      ref={videoPlayerRef}
                      id={"ide-video"}
                      crossOrigin="anonymous"
                      src={video.uri}
                      style={{
                        height: "92vh",
                        maxWidth: "100%",
                        backgroundColor: "black",
                      }}
                      onLoadedMetadata={videoLoaded}
                      onPlay={playVideo}
                      onPause={pauseVideo}
                      muted
                      controls
                    >
                      Your browser does not support embedded videos
                    </video>
                    <CircularProgress
                      style={{
                        display: running ? "block" : "none",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                      }}
                    />
                    <canvas ref={captureCanvas} style={{ display: "none" }} />
                    <canvas
                      ref={overlayCanvas}
                      style={{
                        pointerEvents: "none",
                        position: "absolute",
                        zIndex: 10,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div>
                      <Typography variant={"h6"} color={"white"}>
                        No Video Selected
                      </Typography>
                    </div>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>

          <Dialog
            onClose={() => setCreatingSchema(false)}
            fullWidth={true}
            maxWidth={"xs"}
            open={creatingSchema}
          >
            <DialogTitle>New AI Schema</DialogTitle>
            <DialogContent>
              <form onSubmit={createSchema}>
                <TextField
                  label="Name"
                  variant="filled"
                  fullWidth={true}
                  inputRef={bindNewSchemaNameField}
                  onChange={(event) =>
                    setNewSchemaName(event.target.value.trim().toLowerCase())
                  }
                />
              </form>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={() => setOpeningSchema(false)}
            fullWidth={true}
            maxWidth={"xs"}
            open={openingSchema}
          >
            <DialogTitle>Open AI Schema</DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <Select value={schemaId} onChange={schemaChosen}>
                  {schemas.map((schema) => {
                    return (
                      <MenuItem key={schema["id"]} value={schema["id"]}>
                        {schema["name"]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={() => setConfiguringSchema(false)}
            fullWidth={true}
            maxWidth={"md"}
            scroll={"body"}
            open={configuringSchema}
          >
            <DialogTitle>Configure AI Schema</DialogTitle>
            <DialogContent style={{ backgroundColor: "white" }}>
              <Box pt={1}>
                <MovementSelect
                  value={schemaActivities}
                  label={"Choose the activities this schema will apply to..."}
                  movementChanged={schemaActivitiesChosen}
                  multiple={true}
                  other={false}
                />
              </Box>
              <Box pt={1}>
                <FrameRateInput
                  initialValue={targetInferenceFps}
                  onChange={setTargetInferenceFps}
                />
              </Box>
              <Box pt={2}>
                <Button variant={"contained"} onClick={saveSchemaClicked}>
                  Save
                </Button>
                <Button
                  variant={"text"}
                  className={"on-dark"}
                  onClick={() => setConfiguringSchema(false)}
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </GuruConsolePageLayout>
      </>
    );
  }
}
