export default class Video {
  static PERSON_ID = 0;
  static PERSON_TYPE = "person";

  constructor({
    id,
    status,
    uri,
    overlays,
    j2p,
    analysis,
    movement,
    repCount,
    fps,
    uploadedAt,
    objects,
    height,
    width,
  }) {
    this.id = id;
    this.status = status;
    this.uri = uri;
    this.overlays = overlays;
    this.j2p = j2p;
    this.analysis = analysis;
    this.movement = movement;
    this.repCount = repCount;
    this.uploadedAt = uploadedAt;
    this.groundTruthFrameIndices = new Set();
    this.bboxIds = new Set();
    this.fps = fps;
    this.height = height;
    this.width = width;

    this.timestampsByJoint = {};
    this.jointNames = [];
    this.jointToRemovedTimestamp = {};
    this.frameToBBoxes = {};
    if (objects) {
      this._initBBoxes(objects);
    }

    if (this.movement === undefined && this.analysis) {
      this.movement = this.analysis["liftType"];
    }

    if (j2p !== undefined) {
      this.jointNames = Object.entries(this.j2p)
        .filter(
          ([joint, points]) =>
            typeof points !== "function" &&
            (joint.startsWith("left") || joint.startsWith("right"))
        )
        .map(([joint, points]) => joint);

      this.jointNames.forEach(
        (jointName) => (this.jointToRemovedTimestamp[jointName] = [])
      );

      this._computeJointTimestamps();
      this._computeFrameToJoints();
      this.estimatedFps = this._estimateFps();
    }
  }

  failureReason() {
    if (this.analysis && this.analysis["reason"]) {
      return this.analysis["reason"];
    }
    if (this.j2p && this.j2p["reason"]) {
      return this.j2p["reason"];
    }

    return "UNKNOWN";
  }

  analysisFor(repIndex, analysisType) {
    return this.reps()[repIndex]["analyses"].find((nextAnalysis) => {
      return nextAnalysis["analysisType"] === analysisType;
    });
  }

  successful() {
    if (!this.j2p) {
      return false;
    }
    if (this.j2p["reason"]) {
      return false;
    }

    if (!this.analysis) {
      return false;
    }
    if (this.analysis["reason"]) {
      return false;
    }

    return true;
  }

  analysisTypes() {
    const types = new Set();

    this.reps().forEach((rep) => {
      if (rep["analyses"]) {
        rep["analyses"].forEach((analysis) =>
          types.add(analysis["analysisType"])
        );
      }
    });

    return [...types].sort();
  }

  analysisValueFor(repIndex, analysisType) {
    const analysis = this.analysisFor(repIndex, analysisType);
    if (analysis === undefined) {
      return null;
    } else {
      return analysis["analysisScalar"]
        ? analysis["analysisScalar"].toFixed(2)
        : null;
    }
  }

  bboxForFrame(frameIndex, bboxId, interpolate = false) {
    if (frameIndex in this.frameToBBoxes) {
      const bbox = this.frameToBBoxes[frameIndex].find(
        (nextBbox) => nextBbox["id"] === bboxId
      );

      if (bbox) {
        return bbox;
      }
    }

    if (interpolate) {
      var previousBbox = null;
      var previousIndex = frameIndex - 1;
      for (; previousIndex >= 0 && !previousBbox; --previousIndex) {
        previousBbox = this.bboxForFrame(previousIndex, bboxId, false);
      }
      var nextBbox = null;
      var nextIndex = frameIndex + 1;
      const maxFrameIndex = this._maxFrameIndexFor(this.frameToBBoxes);
      for (; nextIndex <= maxFrameIndex && !nextBbox; ++nextIndex) {
        nextBbox = this.bboxForFrame(nextIndex, bboxId, false);
      }

      if (previousBbox && nextBbox) {
        return this._interpolateBboxes(
          frameIndex,
          previousIndex,
          previousBbox,
          nextIndex,
          nextBbox
        );
      }
    }

    return undefined;
  }

  bboxesForFrame(frameIndex, interpolate = false) {
    return Array.from(this.bboxIds)
      .map((bboxId) => {
        return this.bboxForFrame(frameIndex, bboxId, interpolate);
      })
      .filter((bbox) => !!bbox);
  }

  _estimateFps() {
    const argmax = (arr) =>
      arr.reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0);
    const numPointsForJoint = Object.keys(this.timestampsByJoint).map(
      (joint) => this.j2p[joint].length
    );
    const mostCommonJoint = this.jointNames[argmax(numPointsForJoint)];

    if (mostCommonJoint) {
      const totalFps = this.j2p[mostCommonJoint]
        .map((nextPoint) => {
          return (
            nextPoint["frame_idx"] / Math.max(nextPoint["timestamp"], 0.0001)
          );
        })
        .reduce((a, b) => a + b, 0);
      return totalFps / this.j2p[mostCommonJoint].length;
    } else {
      return 0;
    }
  }

  detectedFps() {
    return this.fps;
  }

  estimateTimestampForFrameIndex(frameIndex) {
    return frameIndex / this.estimatedFps;
  }

  estimateFrameIndexForTimestamp(timestamp) {
    return (timestamp / 1000) * this.estimatedFps;
  }

  hasAnyBBoxesForFrame(frameIndex) {
    return (
      frameIndex?.toString() in this.frameToBBoxes &&
      this.frameToBBoxes[frameIndex.toString()].length > 0
    );
  }

  hasBBoxForFrame(frameIndex, bboxId) {
    return this.bboxForFrame(frameIndex, bboxId) !== undefined;
  }

  importantAnalysisTypes() {
    return this.analysisTypes().filter(
      (analysisType) => !["IS_LEFT_LEG"].includes(analysisType)
    );
  }

  isFrameLabeled(frameIndex) {
    return this.groundTruthFrameIndices.has(frameIndex);
  }

  jointForRepCounting() {
    if (this.movement) {
      switch (this.movement.toLowerCase()) {
        case "squat":
        case "bodyweight_squat":
        case "chin_up":
        case "lunge":
        case "deadlift":
        case "sit_up":
        case "burpee":
          return "rightShoulder";
        case "clean_and_jerk":
        case "snatch":
        case "bench":
        case "shoulder_flexion":
          return "rightWrist";
        default:
          return undefined;
      }
    }
    else {
      return undefined;
    }
  }

  jointPositionAt(joint, timestamp, shouldInterpolate = true) {
    if (!(joint in this.j2p && this.j2p[joint].length > 0)) {
      return null;
    }

    const idx = this._jointIndexAtOrBefore(joint, timestamp);
    if (idx === -1) {
      return null;
    }
    const points = this.j2p[joint];
    const t1 = this.timestampsByJoint[joint][idx];
    const p1 = points[idx]["position"];
    if (shouldInterpolate && idx + 1 < points.length) {
      // interpolate between this point and the next one
      const t2 = this.timestampsByJoint[joint][idx + 1];
      // when j2p has duplicates it's possible that t2 === t1
      const weight = t2 === t1 ? 1 : (timestamp - t1) / (t2 - t1);
      const p2 = points[idx + 1]["position"];
      return {
        x: (1 - weight) * p1.x + weight * p2.x,
        y: (1 - weight) * p1.y + weight * p2.y,
      };
    } else {
      return p1;
    }
  }

  jointPositionAtFrame(joint, frameIndex, interpolate = true) {
    var position = null;
    if (frameIndex.toString() in this.frameToJoints) {
      position = this.frameToJoints[frameIndex.toString()][joint];
    }

    if (!position && interpolate) {
      var previousPosition = null;
      var previousIndex = frameIndex + 1;
      for (; previousIndex >= 0 && !previousPosition; --previousIndex) {
        previousPosition = this.jointPositionAtFrame(
          joint,
          previousIndex,
          false
        );
      }
      var nextPosition = null;
      var nextIndex = frameIndex + 1;
      const maxFrameIndex = this._maxFrameIndexFor(this.frameToJoints);
      for (; nextIndex <= maxFrameIndex && !nextPosition; ++nextIndex) {
        nextPosition = this.jointPositionAtFrame(joint, nextIndex, false);
      }

      if (previousPosition && nextPosition) {
        return this._interpolatePositions(
          frameIndex,
          previousIndex,
          previousPosition,
          nextIndex,
          nextPosition
        );
      }
    }

    return position;
  }

  jointPositionsAt(timestamp, shouldInterpolate = false) {
    const positions = {};

    for (let joint in this.j2p) {
      if (this.jointNames.includes(joint)) {
        let jointPosition = this.jointPositionAt(
          joint,
          timestamp,
          shouldInterpolate
        );
        if (jointPosition !== null) {
          positions[joint] = jointPosition;
        }
      }
    }

    return positions;
  }

  jointPositionsAtFrame(frameIndex, shouldInterpolate = false) {
    const positions = {};

    for (let joint in this.j2p) {
      if (this.jointNames.includes(joint)) {
        let jointPosition = this.jointPositionAtFrame(
          joint,
          frameIndex,
          shouldInterpolate
        );
        if (jointPosition !== null) {
          positions[joint] = jointPosition;
        }
      }
    }

    return positions;
  }

  labeledFrames() {
    return Array.from(this.groundTruthFrameIndices);
  }

  movementDisplayName() {
    return this.movement.toLowerCase().replace(/[_]/g, " ");
  }

  numFrames() {
    return this._maxFrameIndexFor(this.frameToJoints);
  }

  objectsForFrame(frameIndex) {
    if (frameIndex in this.frameToBBoxes) {
      return this.frameToBBoxes[frameIndex];
    } else {
      return [
        {
          id: Video.PERSON_ID,
          name: Video.PERSON_TYPE,
        },
      ];
    }
  }

  overlayUri(type) {
    if (this.overlays[type] !== undefined) {
      return this.overlays[type]["uri"];
    } else {
      return this.uri;
    }
  }

  processed() {
    return (
      this._isTerminalStatus(this.status) &&
      ((this.j2p && this.j2p["status"] === "Failed") ||
        (this.overlays["all"] !== undefined &&
          this._isTerminalStatus(this.overlays["all"]["status"])) ||
        (this.analysis && this._isTerminalStatus(this.analysis["status"])))
    );
  }

  removeBBoxesForFrame(frameIndex) {
    delete this.frameToBBoxes[frameIndex];
  }

  removeBBoxForFrame(frameIndex, id) {
    if (frameIndex in this.frameToBBoxes) {
      console.log(
        `Before for ${id}: ${JSON.stringify(this.frameToBBoxes[frameIndex])}`
      );
      this.frameToBBoxes[frameIndex] = this.frameToBBoxes[frameIndex].filter(
        (bbox) => bbox["id"] !== id
      );
      if (this.frameToBBoxes[frameIndex].length === 0) {
        delete this.frameToBBoxes[frameIndex];
        this.groundTruthFrameIndices.delete(frameIndex);
      }
    }
  }

  removeJointForTimestamp(joint, timestamp) {
    const idx = this._jointIndexAtOrBefore(joint, timestamp);
    if (idx === -1) {
      return;
    }

    this.j2p[joint].splice(idx, 1);
    this.jointToRemovedTimestamp[joint].push(timestamp);
    this._computeJointTimestamps();
  }

  anyRepCount() {
    return this.repCount || this.reps().length;
  }

  reps() {
    return this.analysis ? this.analysis["reps"] : [];
  }

  groundTruthBBoxForFrame(frameIndex, bboxId, name, bbox) {
    this.groundTruthFrameIndices.add(frameIndex);
    this.setBBoxForFrame(frameIndex, bboxId, name, bbox, 1.0);
  }

  setBBoxForFrame(frameIndex, bboxId, name, bbox, score) {
    const existingBbox = this.bboxForFrame(frameIndex, bboxId);

    if (existingBbox) {
      existingBbox["bbox"]["top"] = bbox["top"];
      existingBbox["bbox"]["left"] = bbox["left"];
      existingBbox["bbox"]["width"] = bbox["width"];
      existingBbox["bbox"]["height"] = bbox["height"];
    } else {
      if (!(frameIndex in this.frameToBBoxes)) {
        this.frameToBBoxes[frameIndex] = [];
      }

      this.frameToBBoxes[frameIndex].push({
        id: bboxId,
        name: name,
        bbox: bbox,
        score: score,
      });

      this.bboxIds.add(bboxId);
    }
  }

  updateJointPosition(joint, timestamp, frameIndex, x, y) {
    if (this.j2p[joint] === undefined) {
      this.j2p[joint] = [];
    }

    const idx = this._jointIndexAtOrBefore(joint, timestamp);
    if (idx === -1) {
      this.j2p[joint].push({
        part: joint,
        timestamp: timestamp / 1000.0,
        frame_idx: frameIndex,
        position: {
          x: x,
          y: y,
        },
        score: 1.0,
      });
      this.j2p[joint].sort((a, b) => {
        return a["timestamp"] - b["timestamp"];
      });
    } else {
      const currentPosition = this.j2p[joint][idx];
      currentPosition["position"]["x"] = x;
      currentPosition["position"]["y"] = y;
    }

    this._computeJointTimestamps();

    if (!(frameIndex.toString() in this.frameToJoints)) {
      this.frameToJoints[frameIndex.toString()] = {};
    }
    this.frameToJoints[frameIndex.toString()][joint] = {
      x: x,
      y: y,
    };
  }

  _jointIndexAtOrBefore(joint, timestamp) {
    if (!(joint in this.timestampsByJoint)) {
      return -1;
    }
    if (
      joint in this.jointToRemovedTimestamp &&
      this.jointToRemovedTimestamp[joint].indexOf(timestamp) >= 0
    ) {
      return -1;
    }

    // Note: we assume vals is pre-sorted
    const vals = this.timestampsByJoint[joint];
    let hi = vals.length - 1;
    let lo = 0;
    if (timestamp < vals[lo]) {
      return -1;
    } else if (timestamp >= vals[hi]) {
      return hi;
    }

    // Invariants:
    // * lo <= t
    // * (hi - lo) decreases on every iteration
    while (lo < hi) {
      const mid = lo + Math.ceil((hi - lo) / 2);
      if (vals[mid] > timestamp) {
        hi = mid - 1;
      } else if (vals[mid] < timestamp) {
        lo = mid;
      } else {
        return mid;
      }
    }
    return lo;
  }

  _computeFrameToJoints() {
    this.frameToJoints = {};
    this.jointNames.forEach((joint) => {
      if (Array.isArray(this.j2p[joint])) {
        this.j2p[joint].forEach((point) => {
          if (!(point.frame_idx in this.frameToJoints)) {
            this.frameToJoints[point.frame_idx] = {};
          }

          this.frameToJoints[point.frame_idx][joint] = point.position;
        });
      }
    });
  }

  _computeJointTimestamps() {
    this.timestampsByJoint = {};
    this.jointNames.forEach((joint) => {
      this.timestampsByJoint[joint] = [];
      if (Array.isArray(this.j2p[joint])) {
        this.timestampsByJoint[joint] = this.j2p[joint].map(
          (p) => p.timestamp * 1000
        );
      }
    });
  }

  _initBBoxes(objects) {
    objects.forEach((nextObject, index) => {
      const objectName = nextObject["type"] || Video.PERSON_TYPE;
      const objectId =
        objectName === Video.PERSON_TYPE ? Video.PERSON_ID : index + 1;

      nextObject["boundingBoxes"].forEach((boundingBox) => {
        this.setBBoxForFrame(
          boundingBox["frameIndex"],
          objectId,
          objectName,
          {
            top: boundingBox["top"],
            left: boundingBox["left"],
            width: boundingBox["right"] - boundingBox["left"],
            height: boundingBox["bottom"] - boundingBox["top"],
          },
          boundingBox["score"]
        );
      });
    });
  }

  _interpolateBboxes(
    frameIndex,
    previousIndex,
    previousBbox,
    nextIndex,
    nextBbox
  ) {
    const interpolationFactor =
      (frameIndex - previousIndex) / (nextIndex - previousIndex);

    return {
      id: previousBbox["id"],
      name: previousBbox["name"],
      score: (previousBbox["score"] + nextBbox["score"]) / 2,
      bbox: {
        top:
          previousBbox["bbox"]["top"] +
          (nextBbox["bbox"]["top"] - previousBbox["bbox"]["top"]) *
            interpolationFactor,
        left:
          previousBbox["bbox"]["left"] +
          (nextBbox["bbox"]["left"] - previousBbox["bbox"]["left"]) *
            interpolationFactor,
        width:
          previousBbox["bbox"]["width"] +
          (nextBbox["bbox"]["width"] - previousBbox["bbox"]["width"]) *
            interpolationFactor,
        height:
          previousBbox["bbox"]["height"] +
          (nextBbox["bbox"]["height"] - previousBbox["bbox"]["height"]) *
            interpolationFactor,
      },
    };
  }

  _interpolatePositions(
    frameIndex,
    previousIndex,
    previousPosition,
    nextIndex,
    nextPosition
  ) {
    const interpolationFactor =
      (frameIndex - previousIndex) / (nextIndex - previousIndex);

    return {
      x:
        previousPosition["x"] +
        (nextPosition["x"] - previousPosition["x"]) * interpolationFactor,
      y:
        previousPosition["y"] +
        (nextPosition["y"] - previousPosition["y"]) * interpolationFactor,
    };
  }

  _isTerminalStatus(status) {
    return status && status !== "Pending";
  }

  _maxFrameIndexFor(frameMap) {
    return Object.keys(frameMap)
      .map((i) => parseInt(i))
      .sort(function (a, b) {
        return b - a;
      })[0];
  }
}
