import React, { useEffect, useState, useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EditableList from "./EditableList";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";

export default function CrawlerTriggerForm({ automation, setAutomation }) {
  const [config, setConfig] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [depth, setDepth] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isValidConfig, setIsValidConfig] = useState(false);

  const fetchConfiguration = useCallback(async () => {
    setLoading(true);
    var response;
    try {
      response = await axios.get(
        `https://${await guruUtils.apiDomain()}/automations/${automation.id}/connections/inputs`,
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      );
    } catch (e) {
      setError("Failed to fetch input connections");
      return;
    }
    const latestConfig = response.data["crawler"] || {
      frequency: 24,
      depth: 1,
      rootUrls: [""],
    };
    setConfig(latestConfig);
    setFrequency(latestConfig.frequency);
    setDepth(latestConfig.depth);
    setLoading(false);
  }, [automation]);
  useEffect(() => {
    fetchConfiguration();
  }, [fetchConfiguration]);

  const saveConfig = useCallback(
    async (rootUrls) => {
      setLoading(true);

      try {
        await axios.put(
          `https://${await guruUtils.apiDomain()}/automations/${automation.id}/connections/inputs/crawler`,
          {
            frequency: frequency,
            depth: depth,
            rootUrls: rootUrls,
          },
          { headers: await guruUtils.getAuthHeaders() }
        );

        setConfig({
          frequency: frequency,
          depth: depth,
          rootUrls: rootUrls,
        });
      } catch (e) {
        console.error(`Failed to save crawler config because ${e}`);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [automation, depth, frequency]
  );

  const validateUrl = (input) => {
    const urlRegex =
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/;
    if (urlRegex.test(input)) {
      return null;
    } else {
      return { error: "Must be a URL" };
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box pt={2}>
        <Grid container>
          <Grid item xs={3} pt={1} pr={2}>
            <TextField
              label="Frequency (hours)"
              type="number"
              fullWidth
              value={frequency}
              onChange={(event) => setFrequency(event.target.value)}
            />
            <Typography variant={"body2"}>How often to crawl.</Typography>
          </Grid>
          <Grid item xs={3} pt={1}>
            <TextField
              label="Depth"
              type="number"
              fullWidth
              value={depth}
              onChange={(event) => setDepth(event.target.value)}
            />
            <Typography variant={"body2"}>
              How many layers of links should be traversed from each root.
            </Typography>
          </Grid>
          <Grid item xs={12} pt={1}>
            <Typography
              className={"field-label"}
              variant="overline"
              component="div"
            >
              Root URLs
            </Typography>
            <EditableList
              items={config.rootUrls}
              validateItem={validateUrl}
              onItemsChanged={(newRootUrls) => {
                const isValid = newRootUrls.every((item) => !item.error);
                setIsValidConfig(isValid);
                const values = newRootUrls.map(({ value }) => value);
                setConfig({ ...config, rootUrls: values });
              }}
              minItems={1}
              itemName={"URL"}
            />
            <Button
              onClick={saveConfig}
              variant="contained"
              disabled={!isValidConfig}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
    </>
  );
}
