import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import VideoUpload from "../video/VideoUpload";

export const headerHeight = 60;

export default function Header({ videoId, canUpload = true }) {
  let navigate = useNavigate();
  const [currentVideoId, setCurrentVideoId] = useState(videoId);

  function videoIdKeyPress(event) {
    if (event.key === "Enter") {
      navigate(`/videos/${currentVideoId}`);
    }
  }

  function videoIdChanged(event) {
    setCurrentVideoId(event.target.value);
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ flexWrap: "nowrap" }}
      >
        {canUpload && (
          <Grid item style={{ width: "50%", margin: "0 auto" }}>
            <TextField
              fullWidth
              label="Enter a Video ID..."
              variant="filled"
              onChange={videoIdChanged}
              onKeyPress={videoIdKeyPress}
              size="small"
              defaultValue={videoId}
            />
          </Grid>
        )}

        <Grid item style={{ flex: "0 0 auto" }}>
          <Box p={3} style={{ visibility: canUpload ? "inherit" : "hidden" }}>
            <VideoUpload />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
