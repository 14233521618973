import React, { useState, useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CrawlerTriggerForm from "./CrawlerTriggerForm";
import EmailTriggerForm from "./EmailTriggerForm";
import Grid from "@mui/material/Grid";
import ManualTriggerForm from "./ManualTriggerForm";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

export default function AutomationInputConfigPage({
  automation,
  setAutomation,
}) {
  const [loading, setLoading] = useState(false);
  const [triggerType, setTriggerType] = useState(
    automation.trigger_type || "manual"
  );

  const updateTriggerType = useCallback(
    async (type) => {
      setLoading(true);
      setTriggerType(type);

      axios
        .put(
          `https://${await guruUtils.apiDomain()}/automations/${automation.id}`,
          {
            trigger_type: type,
          },
          { headers: await guruUtils.getAuthHeaders() }
        )
        .then(() => {
          setAutomation({
            ...automation,
            trigger_type: type,
          });
        })
        .catch((e) => {
          console.error(`Failed to save trigger type because ${e}`);
          return false;
        })
        .finally(() => setLoading(false));
    },
    [automation, setAutomation]
  );

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <Box p={1}>
          <Card className={"detail-card"} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ borderBottom: 1, borderColor: "grey.400" }}
                >
                  <Typography variant="h5" component="div" pb={1}>
                    Input
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={1}>
                  <Typography
                    className={"field-label"}
                    variant="overline"
                    component="div"
                  >
                    Trigger
                  </Typography>
                  <ToggleButtonGroup
                    value={triggerType}
                    size="small"
                    exclusive
                    onChange={(_, value) => updateTriggerType(value)}
                  >
                    <ToggleButton value="manual">Manual</ToggleButton>
                    <ToggleButton value="crawler">Crawler</ToggleButton>
                    <ToggleButton value="email">Email</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} pt={1}>
                  {triggerType === "manual" && (
                    <ManualTriggerForm
                      automation={automation}
                      setAutomation={setAutomation}
                    />
                  )}
                  {triggerType === "crawler" && (
                    <CrawlerTriggerForm
                      automation={automation}
                      setAutomation={setAutomation}
                    />
                  )}
                  {triggerType === "email" && (
                    <EmailTriggerForm automation={automation} />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </>
    );
  }
}
