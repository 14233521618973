import React, { useEffect, useRef, useState } from "react";
import Header from "../common/Header";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import Start from "@mui/icons-material/Start";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import * as Sentry from "@sentry/react";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";

export default function CreateSchemaPage() {
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [templates, setTemplates] = useState(null);
  const fileInputRef = useRef();

  useEffect(() => {
    async function loadTemplates() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/schemas/templates`,
        headers: await guruUtils.getAuthHeaders(),
      })
        .then(function (response) {
          setLoading(false);
          setTemplates(response.data);
        })
        .catch((reason) => {
          let errorMessage = `Get templates failed because ${reason}`;
          console.log(errorMessage);
          throw errorMessage;
        });
    }

    loadTemplates();
  }, []);

  const templateClicked = async (template) => {
    setLoading(true);

    axios
      .post(
        `https://${await guruUtils.apiDomain()}/schemas`,
        {
          templateId: template.id,
        },
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      )
      .then(function (response) {
        window.location.href = `/videos/${response.data.videoId}/ide?schemaId=${response.data.id}`;
      })
      .catch((reason) => {
        let errorMessage = `Failed to create schema because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
  };

  const uploadClicked = () => {
    fileInputRef.current.click();
  };

  const uploadVideo = async (video) => {
    const sentryTransaction = Sentry.startTransaction({ name: "upload-video" });
    const createVideoSpan = sentryTransaction.startChild({
      op: "create-video",
    });
    setUploading(true);

    return axios({
      method: "post",
      url: `https://${await guruUtils.apiDomain()}/videos`,
      headers: await guruUtils.getAuthHeaders(),
      data: {
        filename: video.name,
        size: video.size,
      },
    })
      .then(async function (response) {
        createVideoSpan.finish();

        await guruUtils.uploadVideo(sentryTransaction, response, video);

        window.location.href = `/videos/${response.data.id}/ide`;
      })
      .finally(() => {
        sentryTransaction.finish();
      });
  };

  var content = null;
  if (loading) {
    content = <CircularProgress />;
  } else {
    content = (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} pb={3}>
          <Typography variant="h6">
            An AI Schema tells Guru how to process your videos. To create a
            schema you can either:
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1">Start from a template...</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            ...or&nbsp;
            {uploading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" onClick={uploadClicked}>
                Upload
              </Button>
            )}
            &nbsp;a video and start fresh.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ImageList>
            {templates.map((nextTemplate) => (
              <ImageListItem key={nextTemplate.name} sx={{ maxWidth: "300px" }}>
                <img
                  src={nextTemplate.thumbnailUri}
                  height={"300px"}
                  alt={nextTemplate.name}
                />
                <Tooltip title={nextTemplate.description}>
                  <ImageListItemBar
                    title={nextTemplate.name}
                    subtitle={nextTemplate.description}
                    actionIcon={
                      <IconButton
                        sx={{ backgroundColor: "white" }}
                        onClick={() => templateClicked(nextTemplate)}
                      >
                        <Start />
                      </IconButton>
                    }
                  />
                </Tooltip>
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>

        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => {
            uploadVideo(e.target.files[0]);
          }}
        />
      </Grid>
    );
  }

  return (
    <GuruConsolePageLayout>
      <Header canUpload={false} />
      {content}
    </GuruConsolePageLayout>
  );
}
