import React, { useState, useEffect } from "react";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh"; // Import the refresh icon
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useNavigate } from "react-router-dom";

const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        style={{ backgroundColor: "white" }}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        style={{ backgroundColor: "white" }}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        style={{ backgroundColor: "white" }}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        style={{ backgroundColor: "white" }}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
};

const InvocationHistoryTable = ({ automationId, validationFunction }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios({
        url: `https://${await guruUtils.apiDomain()}/automations/${automationId}/invocations?page=${page}&size=${rowsPerPage}`,
        headers: await guruUtils.getAuthHeaders(),
      });
      const jsonData = response.data;
      const validatedData = await Promise.all(
        jsonData.entries.map(async ({ output, ...rest }) => {
          if (!validationFunction) {
            return { ...rest, output, isValid: null };
          }
          const isValid = await validationFunction(output);
          return { ...rest, output, isValid };
        })
      );
      setData(validatedData);
      setTotalRows(jsonData.total);
    } catch (error) {
      console.error("Error fetching data", error);
      setError(error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchData();
  }, [automationId, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const prettyPrintDate = (date) => {
    return new Date(date).toLocaleString();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing the page size
  };

  const handleRowClick = (invocationId) => {
    navigate(`/automations/${automationId}/invocations/${invocationId}`);
  };

  const TruncatedText = ({ text, maxLength = 50 }) => {
    return (
      <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
        {text && text.length > maxLength
          ? text.substring(0, maxLength) + "..."
          : text}
      </Typography>
    );
  };

  const refresh = () => {
    setError(null);
    fetchData();
  };

  return (
    <>
      <Box sx={{ position: "relative", width: "100%" }}>
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            m: 1,
            zIndex: 1000, // Ensure the button is above table elements
          }}
          onClick={refresh}
        >
          <Tooltip title="Refresh">
            <RefreshIcon sx={{ color: "white" }} />
          </Tooltip>
        </IconButton>
        <TableContainer>
          <Table
            aria-label="simple table"
            sx={{ ".MuiTableCell-root": { color: "black" } }}
          >
            <TableHead>
              <TableRow sx={{ ".MuiTableCell-root": { color: "white" } }}>
                <TableCell>Created at</TableCell>
                <TableCell>Input</TableCell>
                {validationFunction && <TableCell>Valid?</TableCell>}
                <TableCell>Output</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow key={-1}>
                  <TableCell colSpan={validationFunction ? 4 : 3}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow key={-1}>
                  <TableCell colSpan={validationFunction ? 4 : 3}>
                    Error fetching data.{" "}
                    <Link href="#" onClick={refresh}>
                      Retry
                    </Link>
                  </TableCell>
                </TableRow>
              ) : (
                (data || []).map((row, index) => (
                  <Tooltip title="Expand details" key={index}>
                    <TableRow
                      key={index}
                      onClick={() => handleRowClick(row.id)}
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      <TableCell>{prettyPrintDate(row.created_at)}</TableCell>
                      <TableCell>
                        <TruncatedText text={row.input} />
                      </TableCell>
                      {validationFunction && (
                        <TableCell>{row.isValid ? "✅" : "❌"}</TableCell>
                      )}
                      <TableCell>
                        <TruncatedText text={row.output} />
                      </TableCell>
                    </TableRow>
                  </Tooltip>
                ))
              )}
              <TableRow>
                <TablePagination
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default InvocationHistoryTable;
