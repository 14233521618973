import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { Edit, Save } from "@mui/icons-material";

export default function EditableTextArea({ text: initialText, onSave }) {
  // When the user clicks the pencil icon, the text becomes editable

  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const buttonClicked = async () => {
    if (editing) {
      setIsSaving(true);
      const error = await onSave(text);
      if (error) {
        setError(error);
      }
      setIsSaving(false);
    }
    setEditing((editing) => !editing);
  };

  const rehypePlugins = [
    rehypeSanitize,
    // Remove the anchor links that are automatically added to the Markdown headings
    {
      rewrite: (node, index, parent) => {
        if (
          node.tagName === "a" &&
          parent &&
          /^h(1|2|3|4|5|6)/.test(parent.tagName)
        ) {
          parent.children = [parent.children[1]];
        }
      },
    },
  ];

  return (
    <>
      {error && <Typography color="error">{error}</Typography>}
      {isSaving ? (
        <CircularProgress />
      ) : (
        <IconButton size="small" onClick={buttonClicked}>
          {editing ? <Save sx={{ color: "success.main" }} /> : <Edit />}
        </IconButton>
      )}
      <div data-color-mode="light">
        {editing ? (
          <MDEditor
            value={text}
            onChange={setText}
            commandsFilter={(command) => {
              console.log("command name", command.name);
              if (command.name === "image") {
                return false;
              }
              return command;
            }}
            previewOptions={{
              rehypePlugins: [rehypePlugins],
            }}
          />
        ) : (
          <MDEditor.Markdown
            source={text}
            style={{ whiteSpace: "pre-wrap" }}
            rehypePlugins={[rehypePlugins]}
          />
        )}
      </div>
    </>
  );
}