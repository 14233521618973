import { Button } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { VideoPlayerCanvas } from "./VideoPlayerCanvas";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RemoveIcon from "@mui/icons-material/Remove";
import useVideoOverlay from "./VideoOverlay";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import {
  FastRewind,
  FastForward,
  ArrowLeft,
  ArrowRight,
} from "@mui/icons-material";

export default function VideoPlayer({
  video,
  isLabeling,
  labelingObject,
  frameUpdated,
  initialFrameIndex,
  onPlayingChange,
  onReady = () => {},
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [editingGroundTruth, setEditingGroundTruth] = useState(false);
  const [operationSucceeded, setOperationSucceeded] = useState(false);
  const [seekTarget, setSeekTarget] = useState(null);
  const [canvasElement, setCanvasElement] = useState(null);
  const [videoDurationMs, setVideoDurationMs] = useState(null);
  const [currentFrameIndex, setCurrentFrameIndex] = useState(0);

  const uiStateRef = useRef({
    mousePosition: { x: -1, y: -1 },
    isClicked: false,
  });
  const [hasVideoLoaded, setHasVideoLoaded] = useState(false);

  const seekToFrame = (frameIndex) => {
    setSeekTarget({ frameIndex });
  };

  const updateFrameIndex = useCallback(
    (frameIndex) => {
      uiStateRef.current.frameIndex = frameIndex;
      setCurrentFrameIndex(frameIndex);
      frameUpdated(frameIndex, uiStateRef.current.t);
    },
    [frameUpdated]
  );

  useEffect(() => {
    if ((initialFrameIndex || initialFrameIndex === 0) && hasVideoLoaded) {
      seekToFrame(initialFrameIndex);
      updateFrameIndex(initialFrameIndex);
    }
  }, [hasVideoLoaded, initialFrameIndex, updateFrameIndex]);

  const onFrameRendered = useCallback(
    ({ frameIndex, timestampMs }) => {
      uiStateRef.current.t = timestampMs;
      updateFrameIndex(frameIndex);
    },
    [uiStateRef, updateFrameIndex]
  );

  const seekByOffset = useCallback(
    (offset) => {
      const currentFrame = uiStateRef.current.frameIndex;
      seekToFrame(currentFrame + offset);
      updateFrameIndex(currentFrame + offset);
    },
    [uiStateRef, updateFrameIndex]
  );

  const seekByTimestamp = (tMs) => {
    // We don't get 1 frame per millisecond, so ensure there is
    // some meaningful difference here before seeking.
    if (Math.abs(uiStateRef.current.t - tMs) > 17) {
      setSeekTarget({ timestampMs: tMs });
    }
  };

  useVideoOverlay({
    canvasElement,
    video,
    timestamp: uiStateRef.current.t,
    frameIndex: uiStateRef.current.frameIndex,
    skeletonEdited: () => {
      setEditingGroundTruth(true);
    },
    isPlaying,
    isLabeling,
    labelingObject,
    videoDurationMs,
    hasVideoLoaded,
    uiStateRef,
    seekByTimestamp,
  });

  const canvasReady = useCallback(
    ({ durationMs }) => {
      setVideoDurationMs(durationMs);
      setHasVideoLoaded(true);
      onReady(durationMs);
    },
    [onReady]
  );

  const togglePlaying = () => {
    updateFrameIndex(uiStateRef.current.frameIndex);
    setIsPlaying(!isPlaying);
    onPlayingChange(!isPlaying);
    setEditingGroundTruth(false);
  };

  const successPopupClosed = () => {
    setOperationSucceeded(false);
  };

  if (!video.processed()) {
    return <CircularProgress />;
  } else {
    return (
      <Grid container>
        <Grid item xs={12} padding={1}>
          <VideoPlayerCanvas
            ref={setCanvasElement}
            isPlaying={isPlaying}
            seekTarget={seekTarget}
            playbackRate={1.0}
            src={isLabeling ? video.uri : video.overlayUri("all")}
            onReady={canvasReady}
            onFrameRendered={onFrameRendered}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mr={1}>
              <ButtonGroup variant="outlined">
                <Tooltip title="Move back 5 frames" placement="top">
                  <Button
                    id={"minus-5-frame-button"}
                    disabled={isPlaying}
                    variant="outlined"
                    onClick={() => seekByOffset(-5)}
                  >
                    <FastRewind fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title="Move back one frame" placement="top">
                  <Button
                    id={"prev-frame-button"}
                    disabled={isPlaying}
                    variant="outlined"
                    onClick={() => seekByOffset(-1)}
                  >
                    <ArrowLeft fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Position of the current frame within the video"
                  placement="top"
                >
                  <Button
                    id={"frame-index"}
                    disabled={isPlaying}
                    variant="outlined"
                  >
                    {isPlaying ? "" : `Frame: ${currentFrameIndex}`}
                  </Button>
                </Tooltip>
                <Tooltip title="Move forward one frame" placement="top">
                  <Button
                    id={"next-frame-button"}
                    disabled={isPlaying}
                    variant="outlined"
                    onClick={() => seekByOffset(+1)}
                  >
                    <ArrowRight fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title="Move forward 5 frames" placement="top">
                  <Button
                    id={"plus-5-frame-button"}
                    disabled={isPlaying}
                    variant="outlined"
                    onClick={() => seekByOffset(+5)}
                  >
                    <FastForward fontSize="small" />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Box>

            <Box mr={1}>
              <Button
                id={"play-pause-button"}
                variant={editingGroundTruth ? "outlined" : "contained"}
                onClick={togglePlaying}
              >
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </Button>
            </Box>

            <Snackbar
              open={operationSucceeded}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={5000}
              onClose={successPopupClosed}
            >
              <Alert
                onClose={successPopupClosed}
                variant={"filled"}
                severity="success"
                sx={{ width: "100%" }}
              >
                Saved
              </Alert>
            </Snackbar>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
