import React, {useCallback, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";

export default function FrameRateInput({ initialValue, onChange }) {
  const MAX_FPS = 30;
  const [value, setValue] = useState(initialValue);

  const _onChange = useCallback(
    ({ target: { value } }) => {
      var valueAsNum = Number.parseFloat(value);
      var _isValid =
        !Number.isNaN(valueAsNum) && valueAsNum > 0 && valueAsNum <= MAX_FPS;
      if (_isValid) {
        setValue(valueAsNum);
        onChange(valueAsNum);
      }
    },
    [onChange]
  );

  const toolTipText =
    "# of frames to sample per second for processing. Lower frame rates will result in faster processing, but less accurate results.";

  return (
    <Tooltip title={toolTipText} placement="top-start">
      <TextField
        id="inference-fps"
        label={"Frame/s"}
        type="Number"
        sx={{ input: { color: "black", width: "4ch", height: "1rem" } }}
        onChange={_onChange}
        size="small"
        value={value}
      />
    </Tooltip>
  );
};