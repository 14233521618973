import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Editor as MonacoEditor } from "@monaco-editor/react";

const CollapsibleBlock = ({ title, defaultToExpanded = false, children }) => {
  const [isOpen, setIsOpen] = useState(Boolean(defaultToExpanded));

  return (
    <Paper
      sx={{ padding: 1 }}
      style={{
        flex: isOpen ? 1 : null,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
      elevation={1}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
        </IconButton>
      </Box>
      {isOpen && <Box style={{ flex: 1, height: "100%" }}>{children}</Box>}
    </Paper>
  );
};

const CollapsibleBlocks = ({ children }) => {
  return (
    <Container
      disableGutters
      style={{
        height: "100%",
        maxHeight: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
      sx={{ padding: 1 }}
    >
      {children}
    </Container>
  );
};

const CodeEditor = ({ initialValue, language, onChange, onUnmount, onHoverWord = null, onCodeCompletion = null, containerStyle = {}, ...props }) => {
  const editorRef = useRef(null);
  const [hoverProvider, setHoverProvider] = useState(null);
  const [codeCompletionProvider, setCodeCompletionProvider] = useState(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;

    if (onHoverWord) {
      setHoverProvider(monaco.languages.registerHoverProvider('javascript', {
        provideHover: function(model, position) {
          const word = model.getWordAtPosition(position);

          if (word) {
            const hoverSections = onHoverWord(word.word);
            if (hoverSections) {
              return {
                contents: hoverSections.map((nextSection) => {
                  return {
                    value: nextSection,
                  };
                }),
              };
            }
          }
        }
      }));
    }

    if (onCodeCompletion) {
      setCodeCompletionProvider(monaco.languages.registerCompletionItemProvider("javascript", {
        provideCompletionItems: function (model, position) {
          const line = model.getLineContent(position.lineNumber);

          return {
            suggestions: onCodeCompletion(line).map((nextSuggestion) => {
              return {...nextSuggestion, kind: monaco.languages.CompletionItemKind.Function};
            }),
          };
        },
      }));
    }
  }

  useEffect(() => {
    return () => {
      if (editorRef.current && onUnmount) {
        onUnmount(editorRef.current);
      }

      if (hoverProvider) {
        hoverProvider.dispose();
      }
      if (codeCompletionProvider) {
        codeCompletionProvider.dispose();
      }
    }
  }, [hoverProvider, codeCompletionProvider]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <Container style={{height: "100%", ...containerStyle}}>
      <MonacoEditor
        defaultLanguage={language}
        onChange={onChange}
        onMount={handleEditorDidMount}
        defaultValue={initialValue}
        theme="vs-dark"
        // https://microsoft.github.io/monaco-editor/docs.html#enums/editor.EditorOption.html
        options={{ fontSize: 14, minimap: { enabled: false }, fixedOverflowWidgets: true }}
        {...props}
      />
    </Container>
  );
};

export { CollapsibleBlocks, CollapsibleBlock, CodeEditor };
