import React, { useState, useRef } from "react";

const HorizontalDivider = ({ onMouseDown }) => {
  return (
    <div className="divider" onMouseDown={onMouseDown}>
      <div className="grab-line" />
      <div className="grab-line" />
    </div>
  );
};

const SplitPane = ({ children, style = {} }) => {
  const containerRef = useRef(null);
  const [splitRatio, setSplitRatio] = useState([2, 1]);

  const handleMouseMove = (event) => {
    const { top, height } = containerRef.current.getBoundingClientRect();
    const otherHeight = containerRef.current.getClientRects()[0];
    const yOffset = event.clientY - top;
    const topRatio = Math.round((yOffset / height) * 1000);
    const bottomRatio = 1000 - topRatio;
    setSplitRatio([topRatio, bottomRatio]);
  };

  const handleMouseUp = () => {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };
  const handleMouseDown = () => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <>
      <div
        ref={containerRef}
        style={{
          ...style,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div style={{ flex: splitRatio[0], border: "1px solid white", overflow: "auto" }}>
          {children[0]}
        </div>
        <HorizontalDivider onMouseDown={handleMouseDown} />
        <div style={{ flex: splitRatio[1], border: "1px solid white", height: "100%", overflow: "auto" }}>
          {children[1]}
        </div>
      </div>
    </>
  );
};

export default SplitPane;
