import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";
import { DateTime } from "luxon";
import InvocationHistoryTable from "./InvocationHistoryTable";
import { Alert, Chip, Stack } from "@mui/material";
import Ajv from "ajv";
import ReactJson from "react-json-view";
import useDocumentUpload from "./DocumentUploadHook";
import AutomationInputConfigPage from "./AutomationInputConfigPage";
import AutomationOutputConfigPage from "./AutomationOutputConfigPage";
import AutomationProcessingConfigPage from "./AutomationProcessingConfigPage";
import ReadLinesButton from "../common/ReadLinesButton";
import CopyableText from "../common/CopyableText";

export default function AutomationPage() {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [automation, setAutomation] = useState(null);

  const [testing, setTesting] = useState(false);
  const [testInput, setTestInput] = useState("");
  const { files, handleFileUpload, handleFileDelete } = useDocumentUpload();
  const [testOutput, setTestOutput] = useState(null);
  const [testError, setTestError] = useState(null);
  const [invokeInputType, setInvokeInputType] = useState("text");
  const [invocationHistoryTimestamp, setInvocationHistoryTimestamp] = useState(Date.now());

  useEffect(() => {
    async function loadAutomation() {
      var response;
      try {
        response = await axios({
          url: `https://${await guruUtils.apiDomain()}/automations/${id}`,
          headers: await guruUtils.getAuthHeaders(),
        });
      } catch (e) {
        let errorMessage = `Get automation failed because ${e}`;
        console.log(errorMessage);
        throw errorMessage;
      }
      setLoading(false);
      const automation = {
        ...response.data,
        outputSchema: {
          definition: response.data.output_schema.definition,
          isEnabled: response.data.output_schema.is_enabled,
        },
        taskDescription: response.data.task_description,
        lastUpdated: DateTime.fromISO(response.data.last_updated, {
          zone: "utc",
        }).toLocal(),
      };
      // While we migrate to an array for these.
      if (automation.input_doc_type) {
        automation.input_doc_types = [automation.input_doc_type];
      }
      setAutomation(automation);
    }

    loadAutomation();
  }, [id]);

  const bulkRun = (inputs) => {
    setTesting(true);

    Promise.allSettled(inputs.map((input) => startInvocation(input, []))).then((responses) => {
      let invocationIds = responses.map((response) => response.value.data.id);
      console.log(`Started invocations: ${JSON.stringify(invocationIds)}`);

      const pollInterval = setInterval(async () => {
        for (let i = invocationIds.length - 1; i >= 0; i--) {
          const pollResponse = await pollInvocation(invocationIds[i]);

          if (pollResponse.data.status === "Complete") {
              invocationIds.splice(i, 1);
          }
        }

        if (invocationIds.length === 0) {
          console.log(`Bulk run finished`);
          clearInterval(pollInterval);
          setTesting(false);      
          setInvocationHistoryTimestamp(Date.now());
        }
      }, 1000);
    });
  };

  const deleteClicked = async () => {
    if (window.confirm("Are you sure you want to delete this Automation?")) {
      axios
        .delete(`https://${await guruUtils.apiDomain()}/automations/${id}`, {
          headers: await guruUtils.getAuthHeaders(),
        })
        .then(function (response) {
          window.location.href = "/automations";
        })
        .catch((reason) => {
          let errorMessage = `Failed to delete automation because ${reason}`;
          console.log(errorMessage);
          throw errorMessage;
        });
    }
  };

  const invoke = async () => {
    setTesting(true);
    setTestOutput(null);

    const fileInputs = (files || [])
      .filter((file) => file.status === "uploaded")
      .map((file) => {
        return {
          file_id: file.file_id,
          extension: file.extension,
        };
      });

    startInvocation(testInput, fileInputs).then(function (response) {
        const invocationId = response.data.id;
        console.log(`Created invocation: ${invocationId}`);

        const pollInterval = setInterval(async () => {
          const pollResponse = await pollInvocation(invocationId);

          if (pollResponse.data.status === "Complete") {
            clearInterval(pollInterval);

            setTestOutput(pollResponse.data.output);
            setTesting(false);
            setInvocationHistoryTimestamp(Date.now());
          }
        }, 500);
      })
      .catch((reason) => {
        let errorMessage = `Failed to invoke the automation: ${reason}`;
        console.log(errorMessage);
        setTestError(errorMessage);
        setTesting(false);
      })
  };

  const pollInvocation = async (invocationId) => {
    return axios.get(
      `https://${await guruUtils.apiDomain()}/automations/${id}/invocations/${invocationId}`,
      { headers: await guruUtils.getAuthHeaders() }
    ).catch((reason) => {
      let errorMessage = `Failed to get invocation because ${reason}`;
      console.log(errorMessage);
    });    
  }

  const startInvocation = async (text, files) => {
    return axios.post(
      `https://${await guruUtils.apiDomain()}/automations/${id}/invocations`,
      {
        input: text,
        files: files,
      },
      { headers: await guruUtils.getAuthHeaders() }
    );    
  }

  const validateOutput = useCallback(
    async (output) => {
      if (!automation.outputSchema.isEnabled) {
        return undefined;
      }
      try {
        const ajv = new Ajv();
        const validate = ajv.compile(automation.outputSchema.definition);
        const object = JSON.parse(output);
        const isValid = await validate(object);
        return isValid;
      } catch (e) {
        console.error(`Failed to validate output because ${e}`);
        return false;
      }
    },
    [automation]
  );

  var content = null;
  if (loading) {
    content = <CircularProgress />;
  } else {
    const areFileUploadsInProgress = files.some(
      (file) => file.status === "uploading"
    );
    const isReadyToInvoke = testInput.trim().length > 0 || (!areFileUploadsInProgress && files.length > 0);
    var isOutputValidJson = true;
    var parsedResult;
    try {
      parsedResult = JSON.parse(testOutput);
    } catch (e) {
      isOutputValidJson = false;
    }
    const displayOutputAsJson =
      testOutput &&
      automation.outputSchema.isEnabled &&
      isOutputValidJson &&
      typeof parsedResult === "object";
    content = (
      <>
        <Box p={1}>
          <Card className={"detail-card"} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ borderBottom: 1, borderColor: "grey.400" }}
                >
                  <Typography variant="h5" component="div" pb={1}>
                    Automation Overview
                    <Box><CopyableText text={automation.id} variant={"caption"}/></Box>
                  </Typography>
                </Grid>
                <Grid item xs={4} pt={1}>
                  <Typography className={"field-label"} variant="overline">
                    Name
                  </Typography>
                  <Typography variant="body2">{automation.name}</Typography>
                </Grid>
                <Grid item xs={4} pt={1}>
                  <Typography className={"field-label"} variant="overline">
                    Status
                  </Typography>
                  <Typography variant="body2">{automation.status}</Typography>
                </Grid>
                <Grid item xs={4} pt={1}>
                  <Typography className={"field-label"} variant="overline">
                    Last Modified
                  </Typography>
                  <Typography variant="body2">
                    {DateTime.fromISO(automation.lastUpdated, { zone: "utc" })
                      .toLocal()
                      .toFormat("DD H:mm")}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <IconButton size="small" onClick={deleteClicked}>
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </CardActions>
          </Card>
        </Box>

        <AutomationInputConfigPage automation={automation} setAutomation={setAutomation}/>

        <AutomationProcessingConfigPage automation={automation}/>

        <AutomationOutputConfigPage automation={automation} setAutomation={setAutomation}/>

        <Box p={1}>
          <Card className={"detail-card"} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ borderBottom: 1, borderColor: "grey.400" }}
                >
                  <Typography variant="h5" component="div" pb={1}>
                    Invoke
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={1} pb={1}>
                  <ToggleButtonGroup value={invokeInputType} 
                                     size="small"
                                     exclusive 
                                     onChange={(_, value) => setInvokeInputType(value)}>
                    <ToggleButton value="text">
                      Text
                    </ToggleButton>
                    <ToggleButton value="file" disabled={automation.input_doc_type === null}>
                      {automation.input_doc_type} Document
                    </ToggleButton>
                  </ToggleButtonGroup>                  
                </Grid>
                <Grid item xs={12} pt={1}>
                  {
                    invokeInputType === "text" && <>
                      <TextField
                        variant="filled"
                        fullWidth={true}
                        multiline
                        rows={4}
                        onChange={(event) =>
                          setTestInput(event.target.value.trim())
                        }
                      />                    
                    </>
                  }
                  
                  {
                    invokeInputType === "file" && <>
                      <input
                        accept="*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                        onClick={() => (this.value = null) /** Clear the input */}
                      />
                      <label htmlFor="contained-button-file">
                        <Button variant="outlined" component="span">
                          Choose File
                        </Button>
                      </label>
                      <Stack>
                        {(files || []).map((file, index) => {
                          if (file.status === "error") {
                            return (
                              <Alert
                                severity="error"
                                onClose={() => {
                                  handleFileDelete(null, index);
                                }}
                              >
                                {file.error}
                              </Alert>
                            );
                          } else {
                            return (
                              <Chip
                                key={index}
                                label={file.file_name}
                                onDelete={(event) => handleFileDelete(event, index)}
                                color={
                                  file.status === "error" ? "error" : "primary"
                                }
                                sx={{ mt: 1, maxWidth: "200px" }}
                              />
                            );
                          }
                        })}
                      </Stack>                    
                    </>
                  }
                </Grid>
                <Grid item xs={12} pt={1}>
                  {testing ? (
                      <CircularProgress />
                    ) : <>
                      <Button
                        variant="contained"
                        onClick={invoke}
                        disabled={!isReadyToInvoke}
                      >
                        Run
                      </Button>
                      {
                        invokeInputType === "text" && <ReadLinesButton onRead={bulkRun} 
                                                                       sx={{ml: 2}}
                                                                       tooltip="Specify a file to run multiple invocations. Each input should be on a new line.">
                          Bulk Run
                        </ReadLinesButton>
                      }                                           
                    </>
                  }
                </Grid>
                {
                  testError && <Alert severity="error">{testError}</Alert>
                }
                {testOutput && (
                  <Grid item xs={12} pl={2} pt={1} p={3}>
                    <Typography className={"field-label"} variant="overline">
                      Output
                    </Typography>
                    {displayOutputAsJson ? (
                      <ReactJson
                        src={JSON.parse(testOutput)}
                        name={null}
                        collapsed={4}
                        sortKeys={false}
                        displayDataTypes={false}
                      />
                    ) : (
                      <Typography variant="body2" className={"has-linebreaks"}>
                        {testOutput}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box p={1}>
          <Card className={"detail-card"} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ borderBottom: 1, borderColor: "grey.400" }}
                >
                  <Typography variant="h5" component="div" pb={1}>
                    Recent Invocations
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={1}>
                  <InvocationHistoryTable
                    key={`invocations-at-${invocationHistoryTimestamp}`}
                    automationId={id}
                    validationFunction={
                      automation.outputSchema.isEnabled && validateOutput
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </>
    );
  }

  return (
    <>
      <GuruConsolePageLayout p={2}>{content}</GuruConsolePageLayout>
    </>
  );
}
