import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Select from "react-select";
import {darkColor, lightColor} from "../common/Theme";
import CircularProgress from "@mui/material/CircularProgress";

export default function MovementSelect({value, movementChanged, other = true, otherDisplayName = "Other", multiple = false, label = "Select a movement..."}) {
  const [movements, setMovements] = useState(null);
  const [movementOptions, setMovementOptions] = useState([]);

  const searchMovements = (candidate, input) => {
    return candidate.value === "other" || (candidate.value.toLowerCase().includes(input.toLowerCase()));
  };

  const selectionChanged = useCallback((movementOption) => {
    var selectedMovements = null;
    if (multiple) {
      const movementOptionValues = movementOption.map(nextOption => nextOption.value);
      selectedMovements = movements.filter(
        nextMovement => movementOptionValues.includes(nextMovement["name"])
      );
    }
    else {
      selectedMovements = movements.find(nextMovement => nextMovement["name"] === movementOption.value);
    }

    movementChanged(selectedMovements);
  }, [movements, movementChanged]);

  useEffect(() => {
    async function loadMovements() {
      setMovements(await axios({
        url: `https://${await guruUtils.apiDomain()}/movements`,
        headers: await guruUtils.getAuthHeaders(),
      }).then(function (response) {
        return response.data["movements"];
      }).catch((reason => {
        let errorMessage = `Get movements failed because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      })));
    }

    loadMovements();
  }, []);

  useEffect(() => {
    if (movements !== null) {
      let newMovementOptions = movements.map((nextMovement) => {
        return {
          label: nextMovement["display_name"],
          value: nextMovement["name"],
        };
      }).sort((a, b) => a.label.localeCompare(b.label));

      if (other) {
        newMovementOptions.push({
          label: otherDisplayName,
          value: "other",
        });
      }

      setMovementOptions(newMovementOptions);
    }
  }, [movements, setMovementOptions, other, otherDisplayName]);

  const getValues = () => {
    if (multiple) {
      const lowerCaseValues = value.map((nextValue) => nextValue.toLowerCase());
      return movementOptions.filter(
        nextMovement => lowerCaseValues.includes(nextMovement.value.toLowerCase())
      );
    }
    else {
      return movementOptions.find(
        nextMovement => nextMovement.value.toLowerCase() === (value ?? "").toLowerCase()
      );
    }
  };

  if (movements === null) {
    return <CircularProgress/>
  }
  else {
    return <div style={{minWidth: "300px"}}>
      <Select
        placeholder={label}
        onChange={selectionChanged}
        value={getValues()}
        isSearchable
        required
        menuPosition={"fixed"}
        isMulti={multiple}
        options={movementOptions}
        filterOption={searchMovements}
        width={"200px"}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 0,
            height: "56px",
          }),
          option: (provided, state) => ({
            ...provided,
            color: (state.isSelected || state.isFocused) ? "white" : lightColor,
            backgroundColor: state.isFocused ? lightColor : state.isSelected ? darkColor : "white",
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: lightColor,
          })
        }}
      />
    </div>;
  }
}
