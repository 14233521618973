import { useEffect, useState, useCallback } from "react";

/**
 * Setup the import map and es-module-shims
 *
 * This ensures that user-code can import from the standard library
 *
 * @param {String} scriptPath The path of the script to wrap (e.g., "inferenceWorker.js")
 * @returns The blob URL of the wrapped script
 */
const wrapWorkerScript = (scriptPath) => {
  const importMap = {
    imports: {
        "guru/core_types": "./guru/core_types.mjs",
        "guru/onnxruntime": "./guru/onnxruntime.mjs",
        "guru/preprocess": "./guru/preprocess.mjs",
        "guru/inference_utils": "./guru/inference_utils.mjs",
        "guru/stdlib": "./guru/stdlib.mjs",
        "guru/draw": "./guru/draw.mjs",
        "guru/object_detection": "./guru/object_detection.mjs",
        "guru/object_tracking": "./guru/object_tracking.mjs",
        "guru/pose_estimation": "./guru/pose_estimation.mjs",
        "guru/face_detection": "./guru/face_detection.mjs",
    }
  }
  const initOptions = `esmsInitOptions = {
    fetch: async (url) => {
      let _url = url;
      if (url.startsWith('blob://')) {
        _url = url.replace(/^blob:\\/\\//, '');
      }
      return await fetch(_url);
    },
  };`

  const wasmShimsUrl = new URL("es-module-shims.wasm.js", window.location.origin).href;
  const targetUrl = new URL(scriptPath, window.location.origin).href;
  const bootstrapCode = `
      ${initOptions}
      importScripts('${wasmShimsUrl}');
      importShim.addImportMap(${JSON.stringify(importMap)});
      importShim('${targetUrl}').catch(e => setTimeout(() => { throw e; }))`
  return URL.createObjectURL(new Blob([bootstrapCode], { type: 'application/javascript' }));
}

const useInferenceWorker = (onMessage) => {
  const [worker, setWorker] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const handleInferenceMessage = useCallback(({ data: { command, args = {} } }) => {
    if (command === "ready") {
      const { protocol, host } = window.location;
      const basePath = protocol + '//' + host + "/";
      worker.postMessage({command: "init", args: { basePath }});
    } else {
      const { error } = args;
      if (command === "inited" && !error) {
        setIsReady(true);
      }
      onMessage({ data: { command, args }});
    }
  }, [worker, onMessage]);

  useEffect(() => {
    setWorker((prev) => {
      if (prev) {
        return prev;
      }
      const wrappedScript = wrapWorkerScript("inferenceWorker.js");
      const _worker = new Worker(wrappedScript);
      return _worker;
    });
  }, []);

  useEffect(() => {
    if (!worker) return;
    worker.addEventListener("message", handleInferenceMessage);
    return () => {
      worker.removeEventListener("message", handleInferenceMessage);
    }
  }, [worker, handleInferenceMessage]);

  const postMessage = (msg, transfer = null) => {
    if (transfer !== null && transfer !== undefined) {
      worker.postMessage(msg, transfer);
    } else {
      worker.postMessage(msg);
    }
  };

  return [ isReady, worker ? postMessage : null ]
};


export { useInferenceWorker };