import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VideoFailedMessage from "./VideoFailedMessage";

export default function SummaryTable({ video }) {
  function calculateSprintStrideRate(reps) {
    if (!reps || reps.length < 2) return null;

    var numStrides = 0;
    var totalTime = 0;
    for (var i = 1; i < reps.length; i++) {
      const prevToeOff = reps[i - 1].startTimestampMs;
      const toeOff = reps[i].startTimestampMs;
      if (
        prevToeOff === undefined ||
        prevToeOff === null ||
        toeOff === undefined ||
        toeOff === null
      ) {
        continue;
      }
      totalTime += toeOff - prevToeOff;
      numStrides += 1;
    }
    if (numStrides >= 2) {
      const stridesPerSec = numStrides / (totalTime / 1000);
      return stridesPerSec;
    }
    return null;
  }

  function getSprintSummary(video) {
    const strideRate = calculateSprintStrideRate(video.reps());
    return (
      <>
        <Grid item xs={8}>
          <Typography variant={"h6"} display="inline" className={"on-dark"}>
            Stride count:
          </Typography>
          <Typography display="inline" className={"on-dark"}>
            {" "}
            {video.reps().length}
          </Typography>
        </Grid>
        {strideRate && (
          <Grid item xs={8}>
            <Typography variant={"h6"} display="inline" className={"on-dark"}>
              Stride rate:
            </Typography>
            <Typography display="inline" className={"on-dark"}>
              {" "}
              {strideRate.toFixed(2)} strides/sec
            </Typography>
          </Grid>
        )}
      </>
    );
  }

  if (video.successful()) {
    return (
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>
            <Typography
              variant={"h5"}
              className={"on-dark"}
              style={{ textTransform: "capitalize" }}
            >
              {video.movement}
            </Typography>
          </Grid>
          {video.movement && video.movement.toLowerCase() === "sprint" ? (
            getSprintSummary(video)
          ) : (
            <Grid item xs={8}>
              <Typography
                variant={"h6"}
                display="inline"
                className={"on-dark"}
                style={{ textTransform: "capitalize" }}
              >
                Rep count:
              </Typography>
              <Typography display="inline" className={"on-dark"}>
                {" "}
                {video.reps().length}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  } else {
    return <VideoFailedMessage video={video}/>;
  }
}
