import React, { useState, useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import {FormControlLabel, Switch} from "@mui/material";

export default function ManualTriggerForm({ automation, setAutomation }) {
  const [loading, setLoading] = useState(false);

  const documentsChecked = (_, value) => {
    if (value) {
      updateInputDocType(_, ["form"]);
    }
    else {
      updateInputDocType(_, []);
    }
  };

  const updateInputDocType = useCallback(
    async (_, docTypes) => {
      console.log(`updateInputDocType: ${JSON.stringify(docTypes)}`);
      setLoading(true);

      axios
        .put(
          `https://${await guruUtils.apiDomain()}/automations/${automation.id}`,
          {
            input_doc_types: docTypes,
          },
          { headers: await guruUtils.getAuthHeaders() }
        )
        .then(() => {
          automation.input_doc_types = docTypes;
          setAutomation({
            ...automation,
            input_doc_types: docTypes,
          });
        })
        .catch((e) => {
          console.error(`Failed to save input doc types ${JSON.stringify(docTypes)} because ${e}`);
          return false;
        })
        .finally(() => setLoading(false));
    },
    [automation, setAutomation]
  );  

  if (loading) {
    return <CircularProgress/>;
  }
  else {
    return <>
      <Grid item xs={12} pt={1}>
        <FormControlLabel control={<Switch checked={automation.input_doc_types.length > 0} onChange={documentsChecked} />}
                          label="Documents" />

        {
          automation.input_doc_types.length > 0 && <div><ToggleButtonGroup
            value={automation.input_doc_types}
            size="small"
            onChange={updateInputDocType}
          >
            <ToggleButton value="form">Form</ToggleButton>
            <ToggleButton value="tabular">Tabular</ToggleButton>
            <ToggleButton value="text">Text</ToggleButton>
          </ToggleButtonGroup></div>
        }

        <Typography variant={"body2"}>
          Whether your automation accepts documents and, if so, what type.
        </Typography>
      </Grid>
    </>;
  }
};