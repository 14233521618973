import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Auth } from "aws-amplify";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import {
  PlayCircleFilledOutlined,
  Code,
  Book, Chat,
  AccountCircle
} from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Report from "@mui/icons-material/Report";
import Schema from "@mui/icons-material/Schema";
import Videocam from "@mui/icons-material/Videocam";
import PrecisionManufacturing from '@mui/icons-material/PrecisionManufacturing';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import CssBaseline from "@mui/material/CssBaseline";
import CreateSchemaModal from "./CreateSchemaModal";
import { guruUtils } from "./Utils";
import { errorColor } from "./Theme";

export default function GuruMenu({ menuWidth }) {
  let navigate = useNavigate();
  const [userEmail, setUserEmail] = React.useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    guruUtils.checkAdmin(setIsAdmin);
  }, []);  

  const accountClicked = (event) => {
    navigate("/account");
  };

  const automationsClicked = (event) => {
    navigate("/automations");
  };  

  const ideClicked = (event) => {
    navigate(`/editor`);
  };

  const docsClicked = (event) => {
    window.open("https://docs.getguru.ai/introduction", "_blank");
  };

  const homeClicked = (event) => {
    navigate("/home");
  };

  const templatesClicked = (event) => {
    navigate("/templates");
  };

  const schemasClicked = (event) => {
    navigate("/schemas");
  };

  const videosClicked = (event) => {
    navigate("/videos");
  };

  const helpClicked = (event) => {
    window.open("https://discord.gg/HdHVGuwQGC", "_blank");
  };

  const modalRef = useRef();

  const createClicked = () => {
    modalRef.current.openModal();
  };

  useEffect(() => {
    async function initUser() {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          user.getUserAttributes((error, attributes) => {
            attributes.forEach((attribute) => {
              if (attribute["Name"] === "email") {
                setUserEmail(attribute["Value"]);
              }
            });
          });
        })
        .catch((result) => {
          console.error(`Failed to fetch authenticated user: ${result}`);
        });
    }
    initUser();
  }, [setUserEmail]);

  return (
    <>
      <CreateSchemaModal ref={modalRef} />
      <CssBaseline />
      <Drawer
        variant={"permanent"}
        sx={{
          width: menuWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: menuWidth,
            boxSizing: "border-box",
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <Toolbar>
          <Link href={"/"}>
            <img src={"./logo_purple.png"} height={"30"} alt={"Guru"} />
          </Link>
        </Toolbar>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingX: 1,
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={createClicked}
                sx={{ backgroundColor: "#5decc9", borderRadius: "5px" }}
              >
                <ListItemIcon>
                  <Code className={"on-light"} />
                </ListItemIcon>
                <ListItemText primary={"Create"} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={templatesClicked}>
                <ListItemIcon>
                  <PlayCircleFilledOutlined className={"on-light"} />
                </ListItemIcon>
                <ListItemText primary={"Templates"} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={schemasClicked}>
                <ListItemIcon>
                  <Schema className={"on-light"} />
                </ListItemIcon>
                <ListItemText primary={"My Schemas"} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton id={"my-videos"} onClick={videosClicked}>
                <ListItemIcon>
                  <Videocam className={"on-light"} />
                </ListItemIcon>
                <ListItemText primary={"My Videos"} />
              </ListItemButton>
            </ListItem>

            {
              isAdmin &&
              <ListItem disablePadding>
                <ListItemButton onClick={automationsClicked}>
                  <ListItemIcon>
                    <PrecisionManufacturing className={"on-light"} />
                  </ListItemIcon>
                  <ListItemText primary={"Automations"} />
                </ListItemButton>
              </ListItem>
            }         
          </List>

          <List>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton onClick={docsClicked}>
                <ListItemIcon>
                  <Book className={"on-light"} />
                </ListItemIcon>
                <ListItemText primary={"Docs"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton id={"support"} onClick={helpClicked}>
                <ListItemIcon>
                  <Chat className={"on-light"} />
                </ListItemIcon>
                <ListItemText primary={"Discord"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/account")}>
                <ListItemIcon>
                  <AccountCircle className={"on-light"} />
                </ListItemIcon>
                <ListItemText primary={"Account"} />
              </ListItemButton>
            </ListItem>
            {guruUtils.getTenantOverride() && (
              <ListItem disablePadding>
                <ListItemButton onClick={accountClicked}>
                  <ListItemIcon>
                    <Report sx={{ color: errorColor }} />
                  </ListItemIcon>
                  <ListItemText
                    secondary={guruUtils.getTenantOverride()}
                    sx={{ overflow: "hidden" }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
}
