import { createButton } from "../../common/CanvasButton.js";
import { drawCurrentRepOverlay } from "./overlays/BaseOverlays";
import { drawLine, drawRect, drawText } from "../../workers/inference/guru/draw";

const fmtTime = (timeMs) => {
  const minutes = Math.floor(timeMs / 1000 / 60)
    .toString()
    .padStart(1, "0");
  const sec = Math.floor((timeMs % 60000) / 1000)
    .toString()
    .padStart(2, "0");
  const milliseconds = Math.floor(timeMs % 1000)
    .toString()
    .padStart(3, "0");
  return `${minutes}:${sec}:${milliseconds}`;
};

const VideoPlayerInfoBar = (
  { currentTimestamp, durationSec },
  mouseDragAt,
  mouseClickAt,
  seekByTimestamp,
  ctx,
  reps,
  uiState
) => {
  const isVideoLoading = durationSec === null || durationSec === undefined;
  if (isVideoLoading) {
    return;
  }

  const canvasWidth = ctx.canvas.width;
  const canvasHeight = ctx.canvas.height;

  const progressBar = () => {
    const x1 = 0.0;
    const y1 = 0.9;
    const w = 1.0;
    const h = 0.03;

    // draw a semi-transparent dark background, for contrast
    drawRect(
      ctx,
      { x: x1 * canvasWidth, y: y1 * canvasHeight },
      { x: (x1 + w) * canvasWidth, y: canvasHeight },
      { color: "black", alpha: 0.4 }
    );

    // draw progress bar
    const btn = createButton({
      canvas: ctx.canvas,
      x: x1,
      y: y1,
      width: w,
      height: h,
    });

    const padding = 0.01;
    const absBtnX = canvasWidth * x1;
    const absBtnY = canvasHeight * (y1 + padding);
    const absWidth = canvasWidth * w;
    const absHeight = canvasHeight * h;
    const timeToX = (t) => {
      const frac = t / durationSec;
      return absBtnX + frac * absWidth;
    };
    const xToTime = (x) => {
      return x * durationSec;
    };
    drawLine(
      ctx,
      { position: { x: absBtnX, y: absBtnY + padding + absHeight / 2 } },
      {
        position: {
          x: absBtnX + absWidth,
          y: absBtnY + padding + absHeight / 2,
        },
      },
      { color: "white", width: 4 }
    );

    const currentTimeX = timeToX(uiState.t / 1000);
    drawLine(
      ctx,
      { position: { x: currentTimeX, y: absBtnY } },
      { position: { x: currentTimeX, y: absBtnY + absHeight } },
      { color: "white", alpha: 1.0 }
    );

    const newTimestampPosition = mouseDragAt ? mouseDragAt : mouseClickAt;
    if (newTimestampPosition) {
      if (
        newTimestampPosition.y >= absBtnY &&
        newTimestampPosition.y <= absBtnY + absHeight
      ) {
        seekByTimestamp(xToTime(newTimestampPosition.x / canvasWidth) * 1000);
      }
    }

    return btn;
  };

  const drawTimestamp = () => {
    drawText(
      ctx,
      fmtTime(currentTimestamp),
      0.715 * canvasWidth,
      0.95 * canvasHeight,
      canvasWidth
    );
  };

  progressBar();
  if (reps) {
    drawCurrentRepOverlay(
      ctx,
      reps,
      currentTimestamp,
      0.01 * canvasWidth,
      0.95 * canvasHeight
    );
  }
  drawTimestamp();
};

export default VideoPlayerInfoBar;
