import React from "react";
import Alert from "@mui/material/Alert";

export default function VideoFailedMessage({video}) {
  const generic_contact_us = "If you believe this video should process correctly " +
    "please contact Guru and include the URL of this page.";

  let errorMessage;
  switch (video.failureReason()) {
    case "CONVERSION_FAILED":
      errorMessage = "Guru was unable to process your video. " +
        "Please ensure it is a valid video file. " + generic_contact_us;
      break;
    case "FPS_TOO_LOW":
      if (video.detectedFps()) {
        errorMessage = `The frame rate of this video was ${video.detectedFps().toFixed(2)}, which is too low.`;
      }
      else {
        errorMessage = `The frame rate of this video was too low.`;
      }
      errorMessage += " Sprints require at least 60 FPS to analyze correctly. " + generic_contact_us;
      break;
    case "INSUFFICIENT_JOINT_DATA":
      errorMessage = "Guru was unable to identify the entire body of the person. " +
        "Please ensure the person is in-frame. " + generic_contact_us;
      break;
    case "LOW_QUALITY_POSE_ESTIMATE":
      errorMessage = "Guru was unable to identify the person in your video. " +
        "Please ensure the person is in-frame and the FPS of your video is suitable " +
        "for the activity. " + generic_contact_us;
      break;
    default:
      errorMessage = "There was an unexpected error processing your video. " +
        generic_contact_us;
  }

  return (
    <Alert variant={"filled"} severity="error">
      {errorMessage}
    </Alert>
  );
}
