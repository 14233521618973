import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import { Add } from "@mui/icons-material";
import { lightColor } from "../common/Theme";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";
import GuruPaginatedTable from "../common/GuruPaginatedTable";
import { useNavigate } from "react-router-dom";
import AddExampleDialog from "./AddExampleDialog";

const DeleteExample = ({exampleId, deleteExample}) => {
  return <IconButton size="small" onClick={() => deleteExample(exampleId)}>
    <DeleteIcon sx={{color: "error.main"}}/>
  </IconButton>;
}

export default function AutomationExamplesPage() {
  let { id } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [examples, setExamples] = useState(null);

  const loadExamples = useCallback(async () => {
    await axios({
      url: `https://${await guruUtils.apiDomain()}/automations/${id}/examples`,
      headers: await guruUtils.getAuthHeaders(),
    })
      .then(function (response) {
        setLoading(false);
        setExamples(response.data.entries.map((example) => {
          return {
            ...example,
            "actions": <DeleteExample exampleId={example.id} deleteExample={deleteExample}/>
          }
        }));
      })
      .catch((reason) => {
        let errorMessage = `Get examples failed because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
  }, [id]);

  useEffect(() => {   
    loadExamples();
  }, [loadExamples]);

  const addClicked = () => {
    setAdding(true);
  };

  const deleteExample = async (exampleId) => {
    console.log(`Deleting example: ${exampleId}`);
    setLoading(true);

    axios.delete(
      `https://${await guruUtils.apiDomain()}/automations/${id}/examples/${exampleId}`,
      {
        headers: await guruUtils.getAuthHeaders(),
      }
    )
    .then(function (response) {
      setExamples(oldExamples => {
        return oldExamples.filter((example) => example.id !== exampleId);
      });
    })
    .catch((reason) => {
      let errorMessage = `Failed to delete example because ${reason}`;
      console.log(errorMessage);
      throw errorMessage;
    }).finally(() => setLoading(false));
  };

  const doneClicked = () => {
    navigate(`/automations/${id}`);
  };

  const finishedAddingExample = (newExample) => {
    setAdding(false);
    if (newExample) {
      setExamples(oldExamples => [
        {
          ...newExample,
          "actions": <DeleteExample exampleId={newExample.id} deleteExample={deleteExample}/>
        },
        ...oldExamples,
      ]);
    }
  };

  var content = null;
  if (loading) {
    content = <CircularProgress />;
  } else {
    content = (
      <>
        <Box p={1} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Fab color="primary" variant="extended" onClick={addClicked}>
              <Add />
              <Typography sx={{ color: lightColor }} disabled={adding} onClick={addClicked}>Add</Typography>
            </Fab>
          </Box>

          <Button variant="outlined" onClick={doneClicked}>
            Done
          </Button>          
        </Box>
        <GuruPaginatedTable
          data={examples || []}
          columns={[
            { id: "input", label: "When Guru receives", style: {width: "45%", verticalAlign: "top"} },
            { id: "output", label: "Guru should answer", style: {width: "45%", verticalAlign: "top"} },
            { id: "actions", label: "", style: {width: "10%", verticalAlign: "top"} },
          ]}
          loading={loading}
        />
      </>
    );
  }

  return (
    <>
      <GuruConsolePageLayout p={2}>{content}</GuruConsolePageLayout>

      <AddExampleDialog automationId={id} visible={adding} finished={finishedAddingExample}/>
    </>
  );
}
