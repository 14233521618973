import React, { useState, useCallback, useEffect, useRef } from "react";

const OffscreenCanvas = ({ width, height, onCanvasReady, style }) => {
  const setCanvasRef = useCallback(
    (canvas) => {
      if (!canvas) {
        return;
      }

      try {
        onCanvasReady(canvas.transferControlToOffscreen());
      } catch (e) {
        // we probably already did this
        return;
      }
    },
    [onCanvasReady]
  );

  return (
    <canvas
      width={width}
      height={height}
      style={{
        ...(style || {}),
        position: "absolute",
        zIndex: -1,
      }}
      ref={setCanvasRef}
    />
  );
};

export const VideoPlayerOffscreenCanvas = ({
  src,
  onReady,
  onFrameRendered,
  isPlaying,
  seekTarget,
  width,
  height,
  style,
}) => {
  const [offscreenCanvas, setOffscreenCanvas] = useState(null);
  const [mediaWorker, setMediaWorker] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const currentSrc = useRef(null);

  const handleMessage = useCallback(
    ({ data: { command, args } }) => {
      switch (command) {
        case "initialize-done":
          if (onReady) {
            onReady({
              numFrames: args.numFrames,
              durationMs: args.durationMs,
              videoResolution: args.videoResolution,
            });
            setIsReady(true);
          }
          break;
        case "on-frame-rendered":
          if (onFrameRendered) {
            onFrameRendered({
              frameIndex: args.frameIndex,
              timestampMs: args.timestampMs,
            });
          }
          break;
        default:
          console.error(`Unknown command: ${command}`);
      }
    },
    [onReady, onFrameRendered]
  );

  useEffect(() => {
    if (!offscreenCanvas) {
      return;
    }
    const worker = new Worker("/videoPlayerWorker.js");
    setMediaWorker(worker);
  }, [offscreenCanvas]);

  useEffect(() => {
    if (!offscreenCanvas || !mediaWorker) {
      return;
    }
    mediaWorker.addEventListener("message", handleMessage);
    return () => {
      mediaWorker.removeEventListener("message", handleMessage);
    };
  }, [offscreenCanvas, mediaWorker, handleMessage]);

  useEffect(() => {
    if (!(seekTarget && isReady)) {
      return;
    }
    const isSet = (obj) => obj !== undefined && obj !== null;
    const { frameIndex, timestampMs } = seekTarget;
    if (isSet(frameIndex)) {
      mediaWorker.postMessage({
        command: "seek-frame",
        args: { frameIndex },
      });
    } else if (isSet(timestampMs)) {
      mediaWorker.postMessage({
        command: "seek-timestamp",
        args: { timestampMs },
      });
    } else {
      throw new Error("Invalid seek target");
    }
  }, [mediaWorker, seekTarget, isReady]);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    mediaWorker.postMessage({ command: isPlaying ? "play" : "pause" });
  }, [mediaWorker, isReady, isPlaying]);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    mediaWorker.postMessage({
      command: "resize",
      args: { width, height },
    });
  }, [mediaWorker, isReady, width, height]);

  useEffect(() => {
    if (!mediaWorker || !offscreenCanvas) {
      return;
    }
    if (currentSrc.current === null) {
      // Initialize and transfer control of the canvas
      setIsReady(false);
      mediaWorker.postMessage(
        {
          command: "initialize",
          videoFile: src,
          canvas: offscreenCanvas,
        },
        { transfer: [offscreenCanvas] }
      );
    } else if (currentSrc.current !== src) {
      // Re-initialize but don't try to re-transfer the canvas
      setIsReady(false);
      mediaWorker.postMessage({
        command: "initialize",
        videoFile: src,
      });
    }
    currentSrc.current = src;
  }, [src, mediaWorker, offscreenCanvas]);

  return (
    <>
      <OffscreenCanvas
        onCanvasReady={setOffscreenCanvas}
        width={width}
        height={height}
        style={style}
      />
    </>
  );
};
