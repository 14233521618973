import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";

export default function ListTemplatesPage() {
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState(null);

  useEffect(() => {
    async function loadTemplates() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/schemas/templates`,
        headers: await guruUtils.getAuthHeaders(),
      })
        .then(function (response) {
          setLoading(false);
          setTemplates(response.data);
        })
        .catch((reason) => {
          let errorMessage = `Get templates failed because ${reason}`;
          console.log(errorMessage);
          throw errorMessage;
        });
    }

    loadTemplates();
  }, []);

  const templateClicked = async (template) => {
    setLoading(true);

    axios
      .post(
        `https://${await guruUtils.apiDomain()}/schemas`,
        {
          templateId: template.id,
        },
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      )
      .then(function (response) {
        window.location.href = `/editor?schemaId=${response.data.id}&videoId=${response.data.testVideoId}`;
      })
      .catch((reason) => {
        let errorMessage = `Failed to create schema because ${reason}`;
        console.log(errorMessage);
        throw errorMessage;
      });
  };

  var content = null;
  if (loading) {
    content = <CircularProgress />;
  } else {
    content = (
      <Box py={4} px={4}>
        <Typography variant="h5">
          Use Templates as the starting point for your next Guru project
        </Typography>

        <Grid container spacing={4} mt={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            templates.map((template) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                lg={3}
                key={template.title}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  elevation={3}
                  onClick={() => templateClicked(template)}
                  sx={{
                    transition: "0.3s",
                    maxWidth: 345,
                    "&:hover": { transform: "scale(1.05)" },
                    backgroundColor: "white",
                  }}
                >
                  <CardMedia
                    sx={{ height: 140 }}
                    image={template.animationUri || template.thumbnailUri}
                    title={template.name}
                  />
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography gutterBottom variant="body1" component="div">
                      <strong>{template.name}</strong>
                    </Typography>
                    <Typography gutterBottom variant="body2" component="div">
                      {template.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    );
  }

  return (
    <>
      <GuruConsolePageLayout p={5}>{content}</GuruConsolePageLayout>
    </>
  );
}
