import {useState} from "react";

export function useCode({ initialText: placeholderText }) {
  const [initialText, setInitialText] = useState(placeholderText);
  const [text, _setText] = useState(placeholderText);
  const [isDirty, setIsDirty] = useState(false);

  const setText = (text) => {
    setIsDirty(text !== initialText);
    _setText(text);
  };

  const resetText = (text) => {
    setInitialText(text);
    setText(text);
  };

  return { text, initialText, setText, resetText, isDirty, setIsDirty };
};