import { useCallback, useEffect, useState } from "react";

const getMousePosition = (event) => {
  const { target, clientX, clientY } = event;
  const rect = target.getBoundingClientRect();
  let leftPad = window
    .getComputedStyle(target)
    .getPropertyValue("padding-left");
  let topPad = window.getComputedStyle(target).getPropertyValue("padding-top");
  if (leftPad && leftPad.endsWith("px")) {
    leftPad = parseInt(leftPad.slice(0, -2));
  }
  if (topPad && topPad.endsWith("px")) {
    topPad = parseInt(topPad.slice(0, -2));
  }
  const x = Math.min(
    Math.max(0, clientX - rect.left - leftPad),
    rect.width - leftPad - 1
  );
  const y = Math.min(
    Math.max(0, clientY - rect.top - topPad),
    rect.height - topPad - 1
  );
  return { x, y };
};

export const useClickAndDragHandler = ({
  canvasElement,
  onClick,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  onDrag,
  onDragFinished,
}) => {
  const [dragStartPosition, setDragStartPosition] = useState(null);

  const handleMouseMove = useCallback(
    (e) => {
      const isDragging = Boolean(dragStartPosition);
      if (isDragging && onDrag) {
        onDrag(canvasElement, dragStartPosition, getMousePosition(e));
      }
      if (onMouseMove) {
        onMouseMove(canvasElement, getMousePosition(e));
      }
    },
    [onDrag, onMouseMove, canvasElement, dragStartPosition]
  );

  useEffect(() => {
    if (!canvasElement) {
      return;
    }
    const handleMouseDown = (e) => {
      const mousePosition = getMousePosition(e);
      onMouseDown(canvasElement, mousePosition);
      setDragStartPosition(mousePosition);
    };
    const handleMouseUp = (e) => {
      let _start = dragStartPosition;
      setDragStartPosition(null);

      const end = getMousePosition(e);
      const { x: x1, y: y1 } = _start;
      const { x: x2, y: y2 } = end;
      const isClick = x1 === x2 && y1 === y2;
      if (isClick) {
        onClick && onClick(canvasElement, end);
      } else {
        onDragFinished && onDragFinished(canvasElement, _start, end);
      }
      onMouseUp && onMouseUp(canvasElement, end);
    };
    canvasElement.addEventListener("mousemove", handleMouseMove);
    canvasElement.addEventListener("mousedown", handleMouseDown);
    canvasElement.addEventListener("mouseup", handleMouseUp);
    return () => {
      canvasElement.removeEventListener("mousemove", handleMouseMove);
      canvasElement.removeEventListener("mousedown", handleMouseDown);
      canvasElement.removeEventListener("mouseup", handleMouseUp);
    };
  }, [
    onMouseDown,
    onMouseUp,
    onDragFinished,
    onClick,
    canvasElement,
    handleMouseMove,
    dragStartPosition,
  ]);
};
