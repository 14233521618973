import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ContentCopy from "@mui/icons-material/ContentCopy";
import CheckCircle from "@mui/icons-material/CheckCircle";

export default function CopyableTextField({ label, value, sx }) {
  const [copiedState, setCopiedState] = useState(false);

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedState(true);
      setTimeout(() => setCopiedState(false), 3000);
    });
  };

  return (
    <TextField
      label={label}
      value={value}
      disabled
      variant="filled"
      sx={{ maxWidth: 420, ...sx }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => handleCopyToClipboard(value)}>
              {copiedState ? <CheckCircle color="success" /> : <ContentCopy />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
