export const amplifyConfig = {
  "aws_project_region": "us-west-2",
  "aws_cognito_region": "us-west-2",
  "aws_user_pools_id": "us-west-2_YUcHuA3dE",
  "aws_user_pools_web_client_id": "74urba6n17b8lmrfojq3nu1pfs",
  "oauth": {
    "domain": "customer-console-prod.auth.us-west-2.amazoncognito.com",
    "scope": ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    "redirectSignIn": "https://console.getguru.ai/login-callback/",
    "redirectSignOut": "https://console.getguru.ai/logout-callback/",
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cognito_username_attributes": ["EMAIL"],
  "aws_cognito_social_providers": ["GOOGLE"],
  "aws_cognito_signup_attributes": ["EMAIL"],
  "aws_cognito_mfa_configuration": "OPTIONAL",
  "aws_cognito_mfa_types": ["SMS"],
  "aws_cognito_password_protection_settings": {"passwordPolicyMinLength": 8, "passwordPolicyCharacters": []},
  "aws_cognito_verification_mechanisms": ["EMAIL"]
}