import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import axios from "axios";
import { guruUtils } from "../../common/Utils";
import CopyableTextField from "../../common/CopyableTextField";

export default function VideoIDEDeploy({ schemaId }) {
  const [loading, setLoading] = useState(true);
  const [tenantInfo, setTenantInfo] = useState(null);

  useEffect(() => {
    async function loadTenant() {
      try {
        const response = await axios({
          url: `https://${await guruUtils.apiDomain()}/user/tenant`,
          headers: await guruUtils.getAuthHeaders(),
        });
        setTenantInfo(response.data);
      } catch (reason) {
        console.error(`Get tenant failed: ${reason}`);
      } finally {
        setLoading(false);
      }
    }

    loadTenant();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  const cardStyle = {
    p: 2,
    mb: 2,
    backgroundColor: "white", // White background
    border: "1px solid #9c27b0", // Purple border
    borderRadius: "4px", // Slightly rounded corners for the border
  };

  return (
    <Box p={2}>
      <Paper elevation={3} sx={cardStyle}>
        <Typography variant="h5" gutterBottom>
          Step 1: Save Your Schema
        </Typography>
        <Typography>
          Saving a schema makes it immediately available on both the{" "}
          <strong>Guru Cloud API</strong> for server-side processing and the
           <strong>Guru Mobile SDK</strong> for on-device processing.
        </Typography>
        <Typography>
          <br />
          You can save your schema by clicking <strong>File</strong> in the Guru
          Editor.
        </Typography>
      </Paper>

      <Paper elevation={3} sx={cardStyle}>
        <Typography variant="h5" gutterBottom>
          Step 2: Copy Your API Key and Schema ID
        </Typography>
        <Typography>
          Your schema will run against any video processed by the Guru API or
          Guru SDK that specifies your <strong>schema id</strong>. Copy them
        </Typography>
        <br />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <CopyableTextField label="API Key" value={tenantInfo.apiKey}/>
          <CopyableTextField label="Schema ID" value={schemaId}/>
        </Box>
      </Paper>

      <Paper elevation={3} sx={cardStyle}>
        <Typography variant="h5" gutterBottom>
          Step 3: Call Guru from your application
        </Typography>
        <Typography>
          See examples of how to use your schema in your application in the{" "}
          <Link
            href="https://docs.getguru.ai/deploying/guru-api-intro"
            target="_blank"
            rel="noopener noreferrer"
          >
            Guru API documentation
          </Link>{" "}
          and the{" "}
          <Link
            href="https://github.com/Formguru/guru-swift-sdk#getting-started"
            target="_blank"
            rel="noopener noreferrer"
          >
            SDK Getting Started Guide
          </Link>
          . You'll need your <strong>Client ID</strong> and{" "}
          <strong>Client Secret</strong>. Copy them below.
        </Typography>
        <br />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <CopyableTextField label="Client Id" value={tenantInfo.credentials.id}/>
          <CopyableTextField label="Client Secret" value={tenantInfo.credentials.secret}/>
        </Box>
      </Paper>
    </Box>
  );
}
