import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import { Add } from "@mui/icons-material";
import { lightColor } from "../common/Theme";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";
import GuruPaginatedTable from "../common/GuruPaginatedTable";

export default function ListAutomationsPage() {
  const [loading, setLoading] = useState(true);
  const [automations, setAutomations] = useState(null);

  useEffect(() => {
    async function loadAutomations() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/automations`,
        headers: await guruUtils.getAuthHeaders(),
      })
        .then(function (response) {
          setLoading(false);

          const sortedAutomations = response.data
            .sort((a, b) => {
              return a.name.localeCompare(b.name);
            });

          setAutomations(sortedAutomations);
        })
        .catch((reason) => {
          let errorMessage = `Get automations failed because ${reason}`;
          console.log(errorMessage);
          throw errorMessage;
        });
    }

    loadAutomations();
  }, []);

  const createClicked = () => {
    window.location.href = "/automations/new";
  };

  const automationClicked = (automation) => {
    window.location.href = `/automations/${automation.id}`;
  };

  const columns = [
    { id: "id", label: "Automation ID" },
    { id: "name", label: "Name" },
    { id: "status", label: "Status" },
  ];

  var content = null;
  if (loading) {
    content = <CircularProgress />;
  } else {
    content = (
      <>
        <GuruPaginatedTable
          data={automations || []}
          columns={columns}
          onRowClick={automationClicked}
          loading={loading}
        />

        <Fab
          color="primary"
          variant="extended"
          onClick={createClicked}
          sx={{
            margin: 0,
            top: "auto",
            right: 20,
            bottom: 60,
            left: "auto",
            position: "fixed",
          }}
        >
          <Add />
          <Typography sx={{ color: lightColor }}>Create</Typography>
        </Fab>
      </>
    );
  }

  return (
    <>
      <GuruConsolePageLayout p={2}>{content}</GuruConsolePageLayout>
    </>
  );
}
