import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { guruUtils } from "../common/Utils";
import axios from "axios";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ReactJson from "react-json-view";
import { darkColor } from "../common/Theme";
import SummaryTable from "./SummaryTable";
import RepsTable from "./RepsTable";
import VideoPlayer from "./player/VideoPlayer";
import VideoTruthForm from "./VideoTruthForm";
import VideoFailedMessage from "./VideoFailedMessage";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";

export default function VideoPage() {
  let { id } = useParams();
  const [isPlaying, setIsPlaying] = useState(false);
  const [video, setVideo] = useState(null);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [videoDurationMs, setVideoDurationMs] = React.useState(0);
  const [labelingObject, setLabelingObject] = React.useState(null);
  const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const [seekToFrame, setSeekToFrame] = useState(null);

  useEffect(() => {
    setVideo(null);
    let isMounted = true;

    const doFetch = async () => {
      const video = await guruUtils.getVideo(id);
      if (video != null && video.processed() && isMounted) {
        console.log(`Setting video ${video.id} with status ${video.status}`);
        setVideo(video);
      } else if (isMounted) {
        setTimeout(doFetch, 2000);
      }
    };
    doFetch();

    // Cleanup when component unmounts
    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    if (video) {
      async function loadGroundTruth() {
        await axios({
          url: `https://${await guruUtils.apiDomain()}/videos/${video.id}/frames/groundTruth`,
          headers: await guruUtils.getAuthHeaders(),
        })
          .then(function (response) {
            const groundTruth = response.data;
            const groundTruthFrameIndices = Object.keys(groundTruth).map(
              (index) => {
                return parseInt(index);
              }
            );

            groundTruthFrameIndices.forEach((groundTruthFrameIndex) => {
              video.removeBBoxesForFrame(groundTruthFrameIndex);
              const objects =
                groundTruth[groundTruthFrameIndex.toString()]["objects"];
              objects.forEach((nextObject) => {
                if (nextObject["type"] === "person") {
                  video.groundTruthBBoxForFrame(
                    groundTruthFrameIndex,
                    0,
                    "person",
                    nextObject["boundingBox"]
                  );

                  const frameTimestamp = video.estimateTimestampForFrameIndex(
                    groundTruthFrameIndex
                  );
                  const toCamelCase = (s) => {
                    return s.replace(/([-_][a-z])/gi, ($1) => {
                      return $1.toUpperCase().replace("-", "").replace("_", "");
                    });
                  };
                  const fixAliases = (s) => s.replace("FootIndex", "Toe");
                  Object.keys(nextObject["jointToPoint"]).forEach(
                    (jointName) => {
                      const camelCaseJoint = fixAliases(toCamelCase(jointName));
                      video.updateJointPosition(
                        camelCaseJoint,
                        frameTimestamp,
                        groundTruthFrameIndex,
                        nextObject["jointToPoint"][jointName]["x"],
                        nextObject["jointToPoint"][jointName]["y"]
                      );
                    }
                  );
                } else {
                  video.groundTruthBBoxForFrame(
                    groundTruthFrameIndex,
                    Math.floor(Math.random() * 1000000),
                    nextObject["type"],
                    nextObject["boundingBox"]
                  );
                }
              });
            });
          })
          .catch((reason) => {
            let errorMessage = `Get ground truth failed because ${reason}`;
            console.log(errorMessage);
            throw errorMessage;
          });
      }

      loadGroundTruth();
    }
  }, [video]);

  const frameUpdated = useCallback(
    (frameIndex, timestamp) => {
      setCurrentFrameIndex(frameIndex);
      setCurrentTimestamp(timestamp);
    },
    [setCurrentFrameIndex, setCurrentTimestamp]
  );

  const playerReady = (durationMs) => {
    setVideoDurationMs(durationMs);
  };

  const reloadVideo = async () => {
    setVideo(null);
    setVideo(await guruUtils.getVideo(id));
  };

  const tabChanged = (event, selectedTabIndex) => {
    setLabelingObject(0);
    setTabIndex(selectedTabIndex);
  };

  return (
    <>
      <GuruConsolePageLayout>
        {video == null ? (
          <div className={"centered"}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container>
            <Grid item xs={12} padding={1}>
              <div className={"horizontally-centered"}>
                <VideoPlayer
                  video={video}
                  isLabeling={tabIndex === 2}
                  labelingObject={labelingObject}
                  frameUpdated={frameUpdated}
                  initialFrameIndex={seekToFrame}
                  onPlayingChange={(playing) => setIsPlaying(playing)}
                  onReady={playerReady}
                />
              </div>
            </Grid>
            <Grid item xs={12} className={"coloredBackground"}>
              <Box sx={{ borderBottom: 1, borderColor: "secondary.main" }}>
                <Tabs value={tabIndex} onChange={tabChanged}>
                  <Tab label="Summary" />
                  <Tab label="Reps" />
                  <Tab label="Labeling" />
                  <Tab label="Joint Data" />
                </Tabs>
              </Box>
              <TabPanel value={tabIndex} index={0}>
                <Box padding={1}>
                  {video.successful() ? (
                    video.reps() && <SummaryTable video={video} />
                  ) : (
                    <VideoFailedMessage video={video} />
                  )}
                </Box>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <Box padding={1}>
                  {video.reps() && (
                    <RepsTable
                      video={video}
                      frameIndex={currentFrameIndex}
                      onModified={reloadVideo}
                    />
                  )}
                </Box>
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <Box padding={1}>
                  {videoDurationMs === 0 || isPlaying ? (
                    <CircularProgress />
                  ) : (
                    <VideoTruthForm
                      video={video}
                      frameIndex={currentFrameIndex}
                      frameTimestamp={currentTimestamp}
                      videoDurationMs={videoDurationMs}
                      seekToFrame={(frameIndex) => {
                        setSeekToFrame(frameIndex);
                      }}
                      labelingObjectChanged={(obj) => {
                        setLabelingObject(obj);
                      }}
                    />
                  )}
                </Box>
              </TabPanel>
              <TabPanel value={tabIndex} index={3}>
                <Box padding={1}>
                  <ReactJson
                    src={video.j2p}
                    name={false}
                    theme={"bright"}
                    collapsed={1}
                    sortKeys={true}
                    displayDataTypes={false}
                    style={{
                      backgroundColor: darkColor,
                    }}
                  />
                </Box>
              </TabPanel>
            </Grid>
          </Grid>
        )}
      </GuruConsolePageLayout>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
