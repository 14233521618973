import React, { useEffect, useState, useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EditableList from "./EditableList";
import Alert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CopyableTextField from "../common/CopyableTextField";
import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import { InfoOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import DismissableAlert from "../common/DismissableAlert";

export default function EmailTriggerForm({ automation }) {
  const [savedConfiguration, setSavedConfiguration] = useState(null);
  const [currentConfiguration, setCurrentConfiguration] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isValidConfig, setIsValidConfig] = useState(false);
  const [error, setError] = useState(null);
  const [didSaveSucceed, setDidSaveSucceed] = useState(false);

  useEffect(() => {
    fetchConfiguration();
  }, []);

  const fetchConfiguration = useCallback(async () => {
    setLoading(true);
    var response;
    try {
      response = await axios.get(
        `https://${await guruUtils.apiDomain()}/automations/${automation.id}/connections/inputs`,
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      );
    } catch (e) {
      setError("Failed to fetch input connections");
      return;
    }
    setSavedConfiguration(response.data["email"]);
    setCurrentConfiguration(response.data["email"]);
    setLoading(false);
  }, [automation]);

  const saveConfig = useCallback(
    async (toSave) => {
      setLoading(true);
      setDidSaveSucceed(false);

      const config = toSave || currentConfiguration;

      try {
        await axios.put(
          `https://${await guruUtils.apiDomain()}/automations/${automation.id}/connections/inputs/email`,
          config,
          { headers: await guruUtils.getAuthHeaders() }
        );
      } catch (e) {
        console.error(`Failed to save email trigger because ${e}`);
        setError("Failed to save email trigger");
        return false;
      }
      setLoading(false);
      setSavedConfiguration(config);
      setDidSaveSucceed(true);
    },
    [automation, currentConfiguration]
  );

  const toggleEmailInputEnabled = useCallback(
    async (isEnabled) => {
      setLoading(true);
      const newConfig = {
        ...savedConfiguration,
        is_enabled: isEnabled,
      };
      saveConfig(newConfig);
    },
    [savedConfiguration]
  );

  const updateAttachmentDocType = async (value) => {
    setCurrentConfiguration((config) => {
      return {
        ...config,
        attachment_doc_type: value,
      };
    });
  };

  const validateEmailOrDomain = (input) => {
    // Regular expression for validating a full email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression for validating a domain (at least one dot, no starting or ending with a dot)
    const domainRegex = /^(?!-)[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,}$/;
    const isValid = emailRegex.test(input) || domainRegex.test(input);
    if (!isValid) {
      return { error: "Must be either an email address or a domain" };
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <FormControlLabel
        label={
          <>
            Incoming Email{" "}
            <Tooltip title="Trigger this automation using the contents of an email as input">
              <InfoOutlined
                fontSize="small"
                style={{ verticalAlign: "middle" }}
              />
            </Tooltip>
          </>
        }
        control={
          <Switch
            checked={currentConfiguration?.is_enabled}
            onChange={(e) => {
              setCurrentConfiguration((config) => {
                return { ...config, is_enabled: e.target.checked };
              });
              const isValidConfig =
                savedConfiguration?.sender_allowlist?.length > 0;
              if (e.target.checked && isValidConfig) {
                toggleEmailInputEnabled(true);
              } else if (!e.target.checked) {
                toggleEmailInputEnabled(false);
              }
            }}
          />
        }
      />
      {currentConfiguration?.is_enabled && (
        <Box p={1}>
          <Card className={"detail-card"} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid item xs={12} pt={1}>
                  <Typography
                    className={"field-label"}
                    variant="overline"
                    component="div"
                  >
                    Recipient Address
                  </Typography>
                  <Typography variant={"body2"}>
                    This address must be the first entry on the To: line
                  </Typography>
                  <CopyableTextField
                    label="To:"
                    value={`${automation.id}@automations.getguru.fitness`}
                    sx={{ width: "100%", maxWidth: null }}
                  />
                </Grid>
                <Grid item xs={12} pt={1}>
                  <Typography
                    className={"field-label"}
                    variant="overline"
                    component="div"
                  >
                    Sender Allowlist (required){" "}
                  </Typography>
                  <Typography variant={"body2"}>
                    The sender's email address or domain must be in this list,
                    otherwise it will be ignored
                  </Typography>
                  <EditableList
                    items={currentConfiguration?.sender_allowlist || []}
                    validateItem={validateEmailOrDomain}
                    onItemsChanged={(newItems) => {
                      const isValid = newItems.every((item) => !item.error);
                      setIsValidConfig(isValid);

                      const values = newItems.map(({ value }) => value);
                      setCurrentConfiguration((config) => {
                        return {
                          ...config,
                          sender_allowlist: values,
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} pt={1}>
                  <Typography
                    className={"field-label"}
                    variant="overline"
                    component="div"
                  >
                    Attachments
                  </Typography>
                  <ToggleButtonGroup
                    value={currentConfiguration.attachment_doc_type}
                    size="small"
                    exclusive
                    onChange={(_, value) => updateAttachmentDocType(value)}
                  >
                    <ToggleButton value="">Ignore</ToggleButton>
                    <ToggleButton value="form">Form</ToggleButton>
                    <ToggleButton value="tabular">Tabular</ToggleButton>
                    <ToggleButton value="text">Text</ToggleButton>
                  </ToggleButtonGroup>
                  <Typography variant={"body2"}>
                    Whether attachments to your emails should be processed and,
                    if so, their format.
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={2}>
                  <Button
                    onClick={() => saveConfig()}
                    variant="contained"
                    disabled={!isValidConfig}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {didSaveSucceed && (
        <DismissableAlert
          severity="success"
          open={didSaveSucceed}
          hideAfterMs={2000}
        >
          Saved
        </DismissableAlert>
      )}
      {error && <Alert severity="error">{error}</Alert>}
    </>
  );
}
