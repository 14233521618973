import React, {useEffect, useRef} from "react";
import {drawLine, drawCircle} from "../workers/inference/guru/draw";
import {accentColor, darkColor, lightColor} from "../common/Theme";

export default function RepSignalGraph({video, frameIndex}) {
  const canvas = useRef();

  useEffect(() => {
    function drawCurrentFrameMarker(context, widthPerFrame, currentCanvas) {
      var currentPosition = video.jointPositionAtFrame(video.jointForRepCounting(), frameIndex, true);
      if (currentPosition) {
        drawCircle(
          context,
          widthPerFrame * frameIndex,
          currentPosition.y * currentCanvas.height,
          10,
          {
            color: accentColor,
            alpha: 0.6,
          }
        );
      }
    }

    function drawRepMarkers(widthPerFrame, context, currentCanvas) {
      video.reps().forEach((rep) => {
        const x = widthPerFrame * video.estimateFrameIndexForTimestamp(rep["startTimestampMs"]);
        drawLine(
          context,
          {
            position: {
              x: x,
              y: 0
            },
          },
          {
            position: {
              x: x,
              y: currentCanvas.height,
            },
          },
          {
            color: accentColor,
            width: 2,
          }
        );
      });
    }

    function drawSignalGraph(currentCanvas, context, numFrames, widthPerFrame) {
      var lastPosition = {x: 0, y: currentCanvas.height};
      for (var i = 0; i < numFrames; ++i) {
        var framePosition = video.jointPositionAtFrame(video.jointForRepCounting(), i, true);
        if (framePosition) {
          drawLine(
            context,
            {
              position: {
                x: widthPerFrame * (i - 1),
                y: lastPosition.y * currentCanvas.height
              },
            },
            {
              position: {
                x: widthPerFrame * i,
                y: framePosition.y * currentCanvas.height,
              },
            },
            {
              color: lightColor,
              width: 2,
            }
          );

          lastPosition = framePosition;
        }
      }
      return widthPerFrame;
    }

    if (video.jointForRepCounting()) {
      const currentCanvas = canvas.current;
      currentCanvas.style.width = "100%";
      currentCanvas.style.height = "100px";
      currentCanvas.width = currentCanvas.offsetWidth;
      currentCanvas.height = currentCanvas.offsetHeight;

      const context = currentCanvas.getContext("2d");
      context.fillStyle = darkColor;
      context.fillRect(0, 0, currentCanvas.width, currentCanvas.height);

      const numFrames = video.numFrames();
      const widthPerFrame = currentCanvas.width / numFrames;
      drawSignalGraph(currentCanvas, context, numFrames, widthPerFrame);
      drawCurrentFrameMarker(context, widthPerFrame, currentCanvas);
      drawRepMarkers(widthPerFrame, context, currentCanvas);
    }
  }, [video, frameIndex]);

  if (video.jointForRepCounting()) {
    return <canvas ref={canvas} />;
  }
  else {
    return <></>;
  }
}
