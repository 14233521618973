import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import {guruUtils} from "../common/Utils";
import { Auth } from "aws-amplify";


export default function CreateTenantPage({tenantCreated}) {
  const [tenantName, setTenantName] = useState("");
  const [loading, setLoading] = useState(true);
  const [autoJoinedTenant, setAutoJoinedTenant] = useState(false);

  useEffect(() => {
    async function autoJoinTenant() {
      await axios.get(
        `https://${await guruUtils.apiDomain()}/invitations/accept`,
        {
          headers: await guruUtils.getAuthHeaders(),
        }
      ).then(() => {
        setAutoJoinedTenant(true);
      }).catch(() => {
        console.log("No invitation to auto-accept");
      }).finally(() => setLoading(false));
    }
    autoJoinTenant();
  }, []);

  const createTenant = async (event) => {
    event.preventDefault();
    
    console.log(`Creating tenant: ${tenantName}`);
    setLoading(true);

    await axios.post(
      `https://${await guruUtils.apiDomain()}/user/tenant`,
      {
        name: tenantName,
      },
      {
        headers: await guruUtils.getAuthHeaders(),
      },
    ).then(function (response) {
      tenantCreated();
    }).catch((reason => {
      let errorMessage = `Failed to create tenant ${tenantName} because ${reason}`;
      console.log(errorMessage);
      throw errorMessage;
    }));
  };

  const logout = async () => {
    await Auth.signOut();
  };

  const tenantNameChanged = (event) => {
    setTenantName(event.target.value.trim());
  };

  return <>
    <AppBar position="static" sx={{height: "64px"}}>
      <Toolbar>
        <Link href={"/"}>
          <img src={'./logo.png'} height={"48"} alt={"Guru"}/>
        </Link>
      </Toolbar>
    </AppBar>

    <div className={"centered"}>
      <Card>
        <Box p={5} className={"on-dark"}>
          <Typography variant={"h3"}>
            Welcome to Guru
          </Typography>
          
          {
            autoJoinedTenant ?
              <>
                <Typography variant={"body1"} pt={2} pb={2}>
                  You have been automatically added to your organization.
                  Sign back in to access your videos and schemas.
                </Typography> 
                <Button onClick={logout}
                        variant="contained">
                  Sign Out
                </Button>
              </> : <form onSubmit={createTenant}>
              <Box mt={2}>
                <Typography variant={"body1"}>
                  Please enter a name for your organization.
                </Typography>
                <TextField label="Organization" variant="filled" fullWidth onChange={tenantNameChanged} />
              </Box>
  
              <Box mt={3}>
                <Typography variant={"body1"} pb={2}>
                  You will need to sign back in after creation.
                </Typography>
                {
                  loading ? <CircularProgress/> :
                    <Button disabled={tenantName.length === 0}
                            type="submit"
                            variant="contained">
                      Create
                    </Button>
                }
              </Box>
            </form>
          }
        </Box>
      </Card>
    </div>
  </>;
}