import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import { Add } from "@mui/icons-material";
import { lightColor } from "../common/Theme";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";
import GuruPaginatedTable from "../common/GuruPaginatedTable";

export default function ListSchemasPage() {
  const [loading, setLoading] = useState(true);
  const [schemas, setSchemas] = useState(null);

  useEffect(() => {
    async function loadSchemas() {
      await axios({
        url: `https://${await guruUtils.apiDomain()}/schemas`,
        headers: await guruUtils.getAuthHeaders(),
      })
        .then(function (response) {
          setLoading(false);

          const sortedSchemas = response.data
            .sort((a, b) => {
              if (a.lastUpdated === null) return 1;
              if (b.lastUpdated === null) return -1;
              return new Date(b.lastUpdated) - new Date(a.lastUpdated);
            })
            .map((schema) => ({
              ...schema,
              humanReadableLastUpdated: schema.lastUpdated
                ? new Date(schema.lastUpdated).toLocaleString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : "Not Available",
            }));

          setSchemas(sortedSchemas);
        })
        .catch((reason) => {
          let errorMessage = `Get schemas failed because ${reason}`;
          console.log(errorMessage);
          throw errorMessage;
        });
    }

    loadSchemas();
  }, []);

  const createClicked = () => {
    window.location.href = "/schemas/new";
  };

  const schemaClicked = (schema) => {
    window.location.href = `/editor?schemaId=${schema.id}&videoId=${schema.testVideoId}`;
  };

  const columns = [
    { id: "id", label: "Schema ID" },
    { id: "name", label: "Description" },
    { id: "humanReadableLastUpdated", label: "Last Updated" },
  ];

  var content = null;
  if (loading) {
    content = <CircularProgress />;
  } else {
    content = (
      <>
        <GuruPaginatedTable
          data={schemas || []}
          columns={columns}
          onRowClick={schemaClicked}
          loading={loading}
        />

        <Fab
          color="primary"
          variant="extended"
          onClick={createClicked}
          sx={{
            margin: 0,
            top: "auto",
            right: 20,
            bottom: 60,
            left: "auto",
            position: "fixed",
          }}
        >
          <Add />
          <Typography sx={{ color: lightColor }}>Create</Typography>
        </Fab>
      </>
    );
  }

  return (
    <>
      <GuruConsolePageLayout p={2}>{content}</GuruConsolePageLayout>
    </>
  );
}
