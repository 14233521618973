import React from "react";
import { Box, CssBaseline } from "@mui/material";
import GuruMenu from "./GuruMenu";

export default function GuruConsolePageLayout({ children }) {
  const menuWidth = 220;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <GuruMenu menuWidth={menuWidth} />
        <Box component="main" sx={{ flexGrow: 1, overflow: "auto" }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}
