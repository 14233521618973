import React, {useCallback, useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import {guruUtils} from "../common/Utils";
import Select  from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {useNavigate} from "react-router-dom";


export default function ChooseTenantControl() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userTenantId, setUserTenantId] = React.useState(null);
  const [tenants, setTenants] = React.useState(false);

  useEffect(() => {
    async function init() {
      setUserTenantId(guruUtils.getTenantOverride() || await guruUtils.userTenant());

      setTenants(await axios({
        url: `https://${await guruUtils.apiDomain()}/tenants`,
        headers: await guruUtils.getAuthHeaders(),
      }).then(function (response) {
        setLoading(false);
        return response.data;
      }));
    }

    init();
  }, []);

  const tenantChanged = useCallback((event) => {
    const chosenTenant = event.target.value;
    guruUtils.setTenantOverride(chosenTenant);
    setUserTenantId(chosenTenant);
    navigate("/");
  }, [setUserTenantId, navigate]);

  if (loading) {
    return <CircularProgress/>;
  }
  else {
    return <FormControl fullWidth>
      <InputLabel id="choose-tenant-label">Tenant</InputLabel>
      <Select
        labelId="choose-tenant-label"
        value={userTenantId}
        label="Tenant"
        onChange={tenantChanged}
      >
        {
          tenants.map((tenant) => {
            return <MenuItem key={tenant["id"]} value={tenant["id"]}>{`${tenant["name"]} - ${tenant["id"]}`}</MenuItem>
          })
        }
      </Select>
    </FormControl>;
  }
}
