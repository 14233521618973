import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ContentCopy from "@mui/icons-material/ContentCopy";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";

export default function CopyableText({ text, ...props }) {
  const [copiedState, setCopiedState] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedState(true);
      setTimeout(() => setCopiedState(false), 3000);
    });
  };

  return (
    <Typography {...props}>
      {text}
      <IconButton onClick={handleCopyToClipboard}>
        {
          copiedState ? (
              <CheckCircle color="success" />
          ) : (
            <ContentCopy />
          )
        }
      </IconButton>
    </Typography>
  );
};