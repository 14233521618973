import createTheme from "@mui/material/styles/createTheme";
import "@fontsource/plus-jakarta-sans";

const darkColor = "#2A079D";
const lightColor = "#6131FF";
const lighterColor = "#9464ff";
const accentColor = "#5decc9";
const errorColor = "#e85c5c";
const errorColor10 = "#ea6c6c";
const warningColor = "#ffbe17";
const warningColor10 = "#ffc42e";

const theme = createTheme({
  palette: {
    primary: {
      main: lightColor,
    },
    secondary: {
      main: darkColor,
    },
    background: {
      paper: lightColor,
    },
    action: {
      active: accentColor,
    },
    success: {
      main: darkColor,
    },
    warning: {
      main: warningColor,
    },
    error: {
      main: errorColor,
    },
    disabled: {
      main: "lightgray",
    },    
  },
  typography: {
    fontFamily: "Plus Jakarta Sans",
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: darkColor,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: accentColor,
          color: darkColor,
          ":hover": {
            backgroundColor: "#fff",
          },
        },
        containedError: {
          color: "white",
          backgroundColor: errorColor,
          ":hover": {
            backgroundColor: errorColor10,
          },
        },
        containedWarning: {
          color: "white",
          backgroundColor: warningColor,
          ":hover": {
            backgroundColor: warningColor10,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: darkColor,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: accentColor,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          color: lightColor,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: lightColor,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: lightColor,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderColor: "white",
          borderWidth: "1px",
          "&.Mui-selected": {
            color: accentColor,
            fontWeight: "bold",
            textDecoration: "underline",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: lightColor,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          backgroundColor: "white",
          color: lightColor,
        },
        icon: {
          backgroundColor: "white",
          color: lightColor,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: lightColor,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "white",
          "&.Mui-selected": {
            color: accentColor,
            fontWeight: "bold",
            textDecoration: "underline",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: darkColor,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        hover: {
          backgroundColor: darkColor,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: accentColor,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          color: lightColor,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: lightColor,
          "&.Mui-selected": {
            color: "white",
            backgroundColor: lightColor
          },
          "&.Mui-selected:hover": {
            backgroundColor: darkColor
          }          
        }
      }
    }
  },
});

export { theme, darkColor, lightColor, accentColor, errorColor };
