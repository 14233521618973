import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ErrorOutline } from "@mui/icons-material";

export default function StackTrace({ exception }) {
  const parseLine = (line) => {
    // first, parse the function name and the file name
    const regex =
      /at (?:(?<functionName>[\w\.]+)\s+)?\(?(?<fileName>[^\)]+)\)?/;
    const match = line.match(regex);
    if (match) {
      let { functionName, fileName } = match.groups;
      // then extract the line number and column number
      const regex2 = /(?<fileName>.+):(?<lineNumber>\d+):(?<columnNumber>\d+)$/;
      const match2 = fileName.match(regex2);
      var lineNumber = null;
      var columnNumber = null;
      if (match2) {
        fileName = match2.groups.fileName;
        lineNumber = parseInt(match2.groups.lineNumber);
        columnNumber = parseInt(match2.groups.columnNumber);
      }
      return [functionName, fileName, lineNumber, columnNumber];
    }
  };

  const cleanStackTrace = (exception) => {
    const barrierFiles = ["es-module-shims.wasm.js", "inferenceWorker.js"];
    var lines = exception.stack.split("\n");
    for (let i = 1; i < lines.length; i++) {
      const parsed = parseLine(lines[i]);
      if (parsed) {
        const [functionName, fileName, lineNumber, columnNumber] = parsed;
        // Everything below this in the stack won't be useful for debugging
        if (fileName && barrierFiles.some((f) => fileName.endsWith(f))) {
          lines = lines.slice(0, i);
          break;
        }
        // This is the user's code. Give it a shorter, more readable file name
        if (fileName && fileName.startsWith("blob:")) {
          lines[i] = lines[i].replace(fileName, "guru-schema.js");
        }
      }
    }
    const formattedStackTrace = lines
      .splice(1)
      .map((line, index) => <pre>{line}</pre>);
    return formattedStackTrace;
  };

  var stackTrace;
  try {
    stackTrace = cleanStackTrace(exception);
  } catch {
    console.error(exception);
    stackTrace = exception.message;
  }

  return (
    <Card className={"on-dark"} style={{ padding: "8px" }}>
      <CardHeader
        title={<Typography variant="h5">Runtime Error</Typography>}
        avatar={<ErrorOutline style={{ color: "red" }} />}
      />
      <CardContent>
        {stackTrace && stackTrace.length > 0 ? (
          <Accordion defaultExpanded={true}>
            {/* TODO: why is this color property needed? Shouldn't this be the default? */}
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            >
              <Typography variant="subtitle1" className={"on-dark"}>{exception.message}</Typography>
            </AccordionSummary>
            <AccordionDetails className={"on-dark"}>{stackTrace}</AccordionDetails>
          </Accordion>
        ) : (
          <Typography variant="subtitle1" className={"on-dark"}>{exception.message}</Typography>
        )}
      </CardContent>
    </Card>
  );
}
